import { useSelector } from 'react-redux';
const GoldRate = () => {
    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        
        const options = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        };
        const formattedDate = date.toLocaleDateString('en-GB', options);
        
        const timeOptions = {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        };
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
      
        return `${formattedDate} ${formattedTime}`;
      };
    const storeData = useSelector(state => state.storeData);
    console.log("storeData inside goldrate",storeData)
    return(
        <>
        <h2 className="font-normal text-[20px] mb-3">Today's Gold Rate</h2>
        <p className="text-[16px] font-bold">24 KT(995) - ₹ {parseInt(storeData?.metalRates?.gold24kRate) || 0}/g</p>
        <div className="my-2" style={{border: '2px solid #000000'}}></div>
        <p className="text-[16px] font-bold">22 KT(916) - ₹ {parseInt(storeData?.metalRates?.gold22kRate) || 0}/g</p>
        <div className="my-2" style={{border: '2px solid #000000'}}></div>
        <p className="text-[16px] font-bold">18 KT(750) - ₹ {parseInt(storeData?.metalRates?.gold18kRate) || 0}/g</p>
        <div className="my-2" style={{border: '2px solid #000000'}}></div>
        <p className="text-[16px] font-bold">14 KT(585) - ₹ {parseInt(storeData?.metalRates?.gold14kRate) || 0}/g</p>
        <p className="text-[15px] mt-4 font-semibold">Updated on: </p>
        <p className="text-[15px] mt-0 font-semibold">{formatDate(storeData?.metalRates?.updatedAt || "Today")}</p>
        </>
    )
}
export default GoldRate;