import React, { useState } from "react";
import { useNavigate  } from 'react-router-dom';
import { useResponsive } from 'hooks';
import { useUpdateCheckoutBillingAddress,useUpdateCheckoutShippingAddress } from "../../../src/pages/products/singleProductDetails/cartAPI";
import { useGetBackgroundColor } from "../../../src/api/index";
const AddAddress = ({currentAddress,checkoutAddressType,checkoutId, handleClose}) => { 
  const { data:themeColor } = useGetBackgroundColor({ onLoad: true });
  const [selectedAddress,setSelectedAddress] = useState(currentAddress);
  const updateBillingAddress = useUpdateCheckoutBillingAddress();
  const updateShippingAddress = useUpdateCheckoutShippingAddress();
  const [addressType, setAddressType] = useState("home");
  const [error, setError] = useState("");
  const { isDesktop, isMobile } = useResponsive();
  const navigate = useNavigate();
  const handleSave = async() => {
    let data;
    if(checkoutAddressType==="billing"){
      data = await updateBillingAddress(checkoutId,selectedAddress)  
      if(data?.checkoutBillingAddressUpdate?.checkoutErrors?.length!==0){
        setError(`${data?.checkoutBillingAddressUpdate?.checkoutErrors[0]?.code}: ${data?.checkoutBillingAddressUpdate?.checkoutErrors[0]?.field ==="countryArea" ? "State" :data?.checkoutBillingAddressUpdate?.checkoutErrors[0]?.field} - ${data?.checkoutBillingAddressUpdate?.checkoutErrors[0]?.message} `)
      }else{
        handleClose();
      }
    }else{
      data = await updateShippingAddress(checkoutId,selectedAddress)  
      if(data?.checkoutShippingAddressUpdate?.checkoutErrors?.length!==0){
        setError(`${data?.checkoutShippingAddressUpdate?.checkoutErrors[0]?.code}: ${data?.checkoutShippingAddressUpdate?.checkoutErrors[0]?.field ==="countryArea" ? "State" : data?.checkoutShippingAddressUpdate?.checkoutErrors[0]?.field} - ${data?.checkoutShippingAddressUpdate?.checkoutErrors[0]?.message} `)
      }else{
        handleClose();
      }
    }
    console.log("Add a new address",data);   
  }
  const handleChange = (e)=>{
    const { name, type, value, checked } = e.target;
      setSelectedAddress({
        ...selectedAddress,
        [name]: value,
      });

  }
  return (
    <div className="max-w-lg mx-auto bg-white">
      {/* Add Address Header */}
      {isMobile && <div className="flex items-center justify-between checkout-header p-4 shadow">
      <button onClick={() => {navigate({pathname: `/orderSummary`}); handleClose(false)}}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='27'
            height='27'
            viewBox='0 0 27 27'
            fill='none'
          >
            <path
              d='M25.3125 13.5L1.6875 13.5M1.6875 13.5L10.3661 23.625M1.6875 13.5L10.3661 3.375'
              stroke='black'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
        <h1 className="text-xl font-bold">Add Address</h1>
        <div></div>
        {/* <button>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              d='M0 24L1.687 17.837C0.645998 16.033 0.0989998 13.988 0.0999998 11.891C0.103 5.335 5.43799 0 11.993 0C15.174 0.001 18.16 1.24 20.406 3.488C22.6509 5.736 23.8869 8.724 23.8859 11.902C23.8829 18.459 18.548 23.794 11.993 23.794C10.003 23.793 8.04198 23.294 6.30499 22.346L0 24ZM6.59698 20.193C8.27298 21.188 9.87298 21.784 11.989 21.785C17.437 21.785 21.875 17.351 21.878 11.9C21.88 6.438 17.463 2.01 11.997 2.008C6.54498 2.008 2.11 6.442 2.108 11.892C2.107 14.117 2.75899 15.783 3.85399 17.526L2.85499 21.174L6.59698 20.193ZM17.984 14.729C17.91 14.605 17.712 14.531 17.414 14.382C17.117 14.233 15.656 13.514 15.383 13.415C15.111 13.316 14.913 13.266 14.714 13.564C14.516 13.861 13.946 14.531 13.773 14.729C13.6 14.927 13.426 14.952 13.129 14.803C12.832 14.654 11.874 14.341 10.739 13.328C9.85598 12.54 9.25898 11.567 9.08598 11.269C8.91298 10.972 9.06798 10.811 9.21598 10.663C9.34998 10.53 9.51298 10.316 9.66198 10.142C9.81298 9.97 9.86198 9.846 9.96198 9.647C10.061 9.449 10.012 9.275 9.93698 9.126C9.86198 8.978 9.26798 7.515 9.02098 6.92C8.77898 6.341 8.53398 6.419 8.35198 6.41L7.78198 6.4C7.58398 6.4 7.26198 6.474 6.98998 6.772C6.71798 7.07 5.94999 7.788 5.94999 9.251C5.94999 10.714 7.01498 12.127 7.16298 12.325C7.31198 12.523 9.25798 15.525 12.239 16.812C12.948 17.118 13.502 17.301 13.933 17.438C14.645 17.664 15.293 17.632 15.805 17.556C16.376 17.471 17.563 16.837 17.811 16.143C18.059 15.448 18.059 14.853 17.984 14.729Z'
              fill='#111111'
            />
          </svg>
        </button> */}
      </div>}

      {/* Address Form */}
      <div className="mt-2 p-4">
        <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
          {/* First Name */}
          <input
            type="text"
            onChange={handleChange}
            name='firstName'
            placeholder="First Name"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
            value={selectedAddress?.firstName || ''}
          />
          {/* Last Name */}
          <input
            type="text"
            name='lastName'
            onChange={handleChange}
            placeholder="Last Name"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
            value={selectedAddress?.lastName || ''}
          />
        </div>

        <div className="mt-4">
          {/* Street & House No */}
          <input
            type="text"
            name='streetAddress1'
            onChange={handleChange}
            placeholder="Street & House No"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
            value={selectedAddress?.streetAddress1 || ''}
          />
        </div>

        <div className="mt-4">
          {/* Landmark (Optional) */}
          <input
            type="text"
            name='streetAddress2'
            onChange={handleChange}
            placeholder="Landmark (Optional)"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
            value={selectedAddress?.streetAddress2 || ''}
          />
        </div>

        <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-4">
          {/* City */}
          <input
            type="text"
            name='city'
            onChange={handleChange}
            placeholder="City"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
            value={selectedAddress?.city || ''}
          />
          {/* Pincode */}
          <input
            type="text"
            name='postalCode'
            onChange={handleChange}
            placeholder="Pincode"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
            value={selectedAddress?.postalCode || ''}
          />
        </div>

        <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-4">
          {/* State */}
          <input
            type="text"
            name='countryArea'
            onChange={handleChange}
            placeholder="State"
            className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
            value={selectedAddress?.countryArea || ''}
          />
          {/* Country */}
          <div className="relative">
            <select className="h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold">
              <option value="India">India</option>
              {/* Add more country options here */}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-12 md:grid-cols-12 gap-4 mt-4">
          {/* Phone Country Code */}
          <select className="col-start-1 col-end-4 h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold">
            <option value="+91">+91</option>
            {/* Add more country codes here */}
          </select>

          {/* Phone Number */}
          <input
            type="text"
            name='phone'
            onChange={handleChange}
            placeholder="Phone Number"
            className="col-start-4 col-end-13 h-[50px] w-full pr-2 py-1 rounded-md bg-gray-50 px-4 text-gray-500 outline-none drop-shadow-sm transition-all duration-200 ease-in-out border-gray-100 border-2 focus:bg-white focus:ring-2 focus:ring-red-300 text-[14px] text-[#505050] font-semibold"
            value={selectedAddress?.phone?.replace("+91",'') || ''}
          />
        </div>

        {/* Address Type */}
        {/* <div className="mt-6">
          <h3 className="font-bold text-[12px]">Select Address Type</h3>
          <div className="flex mt-2">
            <div
              className={`flex-1 p-3 border rounded-lg bg-gray-50 cursor-pointer mr-2 ${
                addressType === "home" ? "border-black" : "border-gray-300"
              }`}
              onClick={() => setAddressType("home")}
            >
              <span className="flex items-center text-[10px] font-bold">              
                Home (7am - 10pm delivery)
              </span>
            </div>

            <div
              className={`flex-1 p-3 border rounded-lg cursor-pointer bg-gray-50 ${
                addressType === "office" ? "border-black" : "border-gray-300"
              }`}
              onClick={() => setAddressType("office")}
            >
              <span className="flex items-center text-[10px] font-bold">
                Office (7am - 10pm delivery)
              </span>
            </div>
          </div>

          <p className="text-[9px] text-semibold mt-2">
            Preferences will help us plan your delivery. However, shipments can
            sometimes arrive early or later than planned.
          </p>
        </div> */}

        {error && error!=="" &&
        <div className="mt-6 font-semibold text-red-800 text-sm">{error}</div>
        }
        {/* Save Button */}
        {!isMobile && <div className="mt-6">
          <button className="checkout-btn text-white py-3 px-6 rounded-lg w-full" onClick={handleSave} style={{backgroundColor : themeColor.color.primaryColor}}>
            SAVE
          </button>
        </div>}
      </div>
            {/* Delivery Date Warning */}
      {isMobile && <div className="mt-6 bg-yellow-100 p-2 text-center text-[10px] font-semibold">
      Expected Delivery Date Might Vary
      </div>}
      {isMobile &&  <button className="checkout-btn text-white py-5 px-6 w-full" onClick={handleSave} style={{backgroundColor : themeColor.color.primaryColor}}>
            SAVE
          </button>
        }
    </div>
  );
};

export default AddAddress;
