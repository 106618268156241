import { useCreateTokenApi, useGetAllCategoriesApi, useGetDBPInventoryApi, useStoreDataApi, useGetCategoryPricingDataApi } from "api";

const INITmain = ({ children }) => {
  useStoreDataApi({ onLoad: true });
  useGetAllCategoriesApi({
    onLoad: true,
    productLimit: 5,
  });
  
  useGetDBPInventoryApi({ onLoad: true });
  
  const isToken = localStorage.getItem(`vjw-${window.location.hostname}ad-token`);  
  
  useCreateTokenApi({isToken})
  useGetCategoryPricingDataApi({onLoad:true});
  
  return children
}

export default INITmain