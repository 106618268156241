import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import routesData from './routes.config';
import ProtectedRoute from './ProtectedRoute';
import { useMarqueeApi } from 'pages/home/api';
import { Footer, Header, Marquee, MobileMenu } from 'components/index';
import useResponsive from 'hooks/useResponsive';

const renderRoutes = routes => {
  return routes.map((route, index) => {
    const Component = route.lazy
      ? lazy(() => Promise.resolve({ default: route.component }))
      : route.component;

    if (route.routes) {
      return (
        <Route
          key={index}
          path={route.path}
          element={
            route.auth ? (
              <ProtectedRoute auth={route.auth} element={Component} />
            ) : (
              <Component />
            )
          }
        >
          {renderRoutes(route.routes)}
        </Route>
      );
    }

    return (
      <Route
        key={index}
        path={route.path}
        element={
          route.auth ? (
            <ProtectedRoute auth={route.auth} element={Component} />
          ) : (
            <Component />
          )
        }
      />
    );
  });
};

const FullHeader = () => {
  const { data: marqueeData } = useMarqueeApi({ onLoad: true });
  const { isMobile, isTablet, isDesktop } = useResponsive();
  return (
    <header>
      {/* <Marquee text={marqueeData} /> */}
      {!isMobile && !isTablet && <Header />}
      {(isMobile || isTablet) && <MobileMenu />}
    </header>
  );
};

const FullFooter = () => {
  return <Footer />;
};

const RouteWrapper = ({ children }) => { 

  // Using the useLocation hook to get the current location
  const location = useLocation();
  let footerDisplay = true;
  // Check if the current path is '/orderSummary'
  if ((location.pathname === '/orderSummary' || location.pathname === '/checkout')) {
    footerDisplay = false;
  }
  // Conditionally render the footer based on the current location
  return (
    <>
      {children}
      {footerDisplay ? <FullFooter /> : null}
    </>
  );
};

const MasterRoutes = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <FullHeader />
      <RouteWrapper>
        <Routes>{renderRoutes(routesData)}</Routes>
      </RouteWrapper>
    </Suspense>
  </Router>
);

export default MasterRoutes;
