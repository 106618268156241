import React from 'react';
import { Link } from 'react-router-dom';
import { useGetBackgroundColor } from "../../../src/api/index";

const ViewProfileMobile = () => {
  const refUserDetails = JSON.parse(localStorage.getItem(`vjw-${window.location.hostname}user-det`));
  const { data } = useGetBackgroundColor({ onLoad: true });

  return (
    <div className="px-5 py-5">
      {/* <div className="bg-gray-100 p-4 rounded-md">
        <p className="text-sm text-gray-500">Your Profile - <Link to="/profile-complete" className="text-blue-500">50% Complete</Link></p>
      </div> */}

      <div className="my-5">
        <button className="bg-gradient-to-r text-white py-2 px-4 rounded-md w-full" style={{backgroundColor : data.color.ctaPrimary}}>
        <Link to="/edit-profile" className="bg-gradient-to-r text-white py-2 px-4 rounded-md w-full">
            Edit Profile     
        </Link>
        </button>
      </div>

      <div className="border p-4 rounded-md">
        <div className="mb-4">
          <p className="text-gray-500 font-semibold">FIRST NAME</p>
          <p className="font-bold">{refUserDetails?.firstName}</p>
        </div>
        <div className="mb-4">
          <p className="text-gray-500 font-semibold">LAST NAME</p>
          <p className="font-bold">{refUserDetails?.lastName}</p>
        </div>
        {/* <div className="mb-4">
          <p className="text-gray-500 font-semibold">EMAIL</p>
          <p className="font-bold">sample.email@gmail.com</p>
        </div> */}
        <div className="mb-4">
          <p className="text-gray-500 font-semibold">MOBILE NO.</p>
          <p className="font-bold">{refUserDetails?.phoneNumber}</p>
        </div>
        <div className="mb-4">
          <p className="text-gray-500 font-semibold">Email</p>
          <p className="font-bold">{refUserDetails?.email || "-"}</p>
        </div>
        <div className="mb-4">
          <p className="text-gray-500 font-semibold">PINCODE</p>
          <p className="font-bold">{refUserDetails?.addresses.length>0 ? refUserDetails?.addresses[0]?.postalCode :'-' }</p>
        </div>
        <div className="mb-4">
          <p className="text-gray-500 font-semibold">BIRTHDAY</p>
          <p className="font-bold">{refUserDetails?.birthDate ? refUserDetails?.birthDate?.split("T")[0] : '-'}</p>
        </div>
        <div className="mb-4">
          <p className="text-gray-500 font-semibold">ANNIVERSARY</p>
          <p className="font-bold">{refUserDetails?.anniversaryDate ? refUserDetails?.anniversaryDate?.split("T")[0] : '-'}</p>
        </div>
        {/* <div className="mb-4">
          <p className="text-gray-500 font-semibold">OCCUPATION</p>
          <p className="font-bold">-</p>
        </div>
        <div className="mb-4">
          <p className="text-gray-500 font-semibold">SPOUSE BIRTHDAY</p>
          <p className="font-bold">-</p>
        </div> */}
      </div>

      {/* <div className="my-5">
        <button className="bg-gray-200 text-black py-2 px-4 rounded-md w-full">
          Change Password
        </button>
      </div> */}

      {/* <div className="my-5">
        <button className="bg-red-500 text-white py-2 px-4 rounded-md w-full">
          Delete My Account
        </button>
      </div> */}
    </div>
  );
};

export default ViewProfileMobile;
