import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faBagShopping, faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate  } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import React, { useState, useEffect, useRef } from 'react';
import { useGetBackgroundColor } from "../../../src/api/index";

const ProductCard = ({ product }) => {
  const { data } = useGetBackgroundColor({ onLoad: true });
  const { id, name, img, isDBPProduct, inStock, displayPrice, metalType, metalCarat, pricingStatus,netWeight } = product;   
  const swiperRef = useRef(null);  
  const navigate = useNavigate();
  // if(id==="UHJvZHVjdDoxMDY="){
  //   console.log("pricingStatus",pricingStatus)
  //   console.log("displayPrice",displayPrice)
  // }
  function formatPriceWithCommas(price) {
    // Convert the price to a string
    const priceString = price.toString();
  
    // Split the price into integer and decimal parts (if any)
    const [integerPart, decimalPart = ""] = priceString.split(".");
  
    // Add commas to the integer part
    let formattedIntegerPart = "";
    for (let i = integerPart.length - 1, count = 0; i >= 0; i--, count++) {
      if ((count === 3 && i !== 0) || ((count + 1) % 2 === 0 && count > 3)) {
        formattedIntegerPart = "," + formattedIntegerPart;
      }
  
      formattedIntegerPart = integerPart[i] + formattedIntegerPart;
    }
  
    // Combine the formatted parts and return the result
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  }

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Change width as per your breakpoint
    };

    // Check on component mount
    checkIsMobile();

    // Add listener for window resize
    window.addEventListener('resize', checkIsMobile);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);
const productUrl = `/product-details/${id}${isDBPProduct ? "?isDBP=true" : ""}`;
  return (
    // onClick={handleProductDetailsClick}
    <Link to={productUrl}>
      <div className='product-card--wrapper cursor-pointer sm:m-0' >
        <div className='relative aspect-square bg-[#dcdcdc]'>         
          <Swiper
            ref={swiperRef}
            className='w-[250px] h-[210px] '
            modules={[Pagination, Autoplay, EffectFade]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            slidesPerView={1}
            spaceBetween={0}
            loop={true}   
            pagination={{
              el: '.swiper-pagination',
              dynamicBullets: false,
              clickable: true,
            }}
            speed={2000}           
          >
            {img?.map((imgUrl, index) => (
              <SwiperSlide key={index}>
                  <img src={imgUrl?.url} alt={`Slide ${index}`} className='image-container' />               
              </SwiperSlide>
            ))}
          </Swiper>
          <div className='floating-tags periodtag'>LATEST</div>
          <div className='floating-tags karattag'>{metalCarat}K {metalType?.toUpperCase()}</div>
          {/* <div className='floating-tags likebtn'>
            <Link to='/'>
              <img src='/assets/img/icons/wishlist.png' alt='' />
            </Link>
          </div> */}
        </div>
        <div className='product-detail-section px-3 py-2'>
          <p className='product-name mb-0 text-[13px] font-semibold sm:mb-[10px]' style={{color : data.color.primaryColor}}>
            {name}
          </p>
          <div className='p-price-share--wrapper flex items-center gap-3'>
            {pricingStatus===true && parseInt(displayPrice) > 0? (
              <p className='p-price font-sherif m-0 mb-[5px] text-[20px] font-bold sm:text-[13px]'>
                &#8377;{formatPriceWithCommas(displayPrice)}
              </p>
            ) : (pricingStatus===false && parseFloat(netWeight)!==null ? 
                <p className='p-price font-sherif m-0 mb-[5px] text-[12px] font-bold sm:text-[10px]'>
                  Weigth: {parseFloat(netWeight || 0).toFixed(2)} gm (Approx)
                </p>
                :
                (netWeight > 0.0 ?
                  <p className='p-price font-sherif m-0 mb-[5px] text-[12px] font-bold sm:text-[10px]'>
                    Weigth: {parseFloat(netWeight || 0).toFixed(2)} gm (Approx)
                  </p>
                 : <p className='p-price font-sherif m-0 mb-[5px] text-[12px] font-bold sm:text-[10px]'>
                  Made To Order
                </p>)
            )}

            <div className='share-icons flex items-center gap-1 ml-auto'>
              <div className='icons flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[12px] bg-[#2BFF66] sm:h-[32px] sm:w-[32px]'>
                <a href='#'>
                  <FontAwesomeIcon icon={faWhatsapp} className='text-black' />
                </a>
              </div>
              <div className='icons flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[12px] bg-[#E3E4FF] sm:h-[32px] sm:w-[32px]'>
                <Link to={productUrl}>
                  <FontAwesomeIcon icon={faBagShopping} className='text-blue-600' />
                </Link>
              </div>
            </div>
          </div>


        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
