import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    essentials: [],
    store: [],
    user: [],
    countries: [],
    attributes: [],
};

const graphSlice = createSlice({
    name: 'graph',
    initialState,
    reducers: {
        setEssentials(state, action) {
            state.essentials = action.payload;
        },
        fetchStoreDetails(state, action) {
            state.store = action.payload;
        },
        fetchUserDetails(state, action) {
            state.user = action.payload;
        },
        fetchCountries(state, action) {
            state.countries = action.payload;
        },
        handleFilters(state, action) {
            const payload = action.payload;
            let tempOptions = structuredClone(payload);
            tempOptions = tempOptions.filter(option => option.values?.length > 0);
            state.attributes = tempOptions;
        },
    },
});

export const { setEssentials, fetchStoreDetails, fetchUserDetails, fetchCountries, handleFilters } = graphSlice.actions;
export default graphSlice.reducer;
