
import { useLazyQuery } from "hooks";
import { useMutation } from "@apollo/client";
import { GET_USER_DETAILS } from "graphql/query";
import { UPDATE_USER_PROFILE } from "graphql/mutations";


export const useGetUserDetails = (onLoad) => {
    const { data, loading, error, executeQuery, refetch, } = useLazyQuery(GET_USER_DETAILS, { onLoad });

    return {
        executeQuery,
        data,
        loading,
        error,
        refetch,
    };
};

export const useUpdateUserProfile = () => {
    const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);
    // gender, pincode, occupation, email
    const UpdateUserProfileFunction = async (id, firstName, lastName, phoneNumber, birthDate, anniversaryDate, email, gender) => {
      try {       
        const { data } = await updateUserProfile({
          variables: {
            id, firstName, lastName, phoneNumber, birthDate, anniversaryDate, email, gender
            },       
        });
  
        if(data?.customerUpdate?.accountErrors?.length===0){
          let userD = JSON.parse(
            localStorage.getItem(`vjw-${window.location.hostname}user-det`)
          );
          userD.firstName = firstName;
          userD.lastName = lastName;
          userD.phoneNumber = phoneNumber;
          userD.birthDate = birthDate;
          userD.gender = gender;
          userD.anniversaryDate = anniversaryDate;
          userD.email = email;
          // Check if the line is found (index is not -1)
          localStorage.setItem(
                `vjw-${window.location.hostname}user-det`,
                JSON.stringify(userD)
            );       
        }
        return data;
      } catch (error) {
        console.error("Error adding new line in the checkout:", error);
        throw error;
      }
    };
  
    return UpdateUserProfileFunction;
  };