import React from 'react';
import { useSelector } from 'react-redux';
import useDeviceDetect from 'hooks/useDeviceDetect';
import RegisterForm from "./RegisterForm";
import RegisterFormMobile from "./RegisterFormMobile";

const RegisterPage = () => {
    const { isMobile } = useDeviceDetect();
    const storeData = useSelector((state) => state.storeData);

    
    return (
        <div className="login-page-component">

            {!isMobile && <>  
                {/* <div className="pagination font-['Inter'] flex justify-center items-center gap-[23px] py-[22px] bg-[#F3F3F3]">
                    <span className="gap-[10px] flex justify-center items-center">
                        <div className="text-[13px] font-semibold h-[25px] w-[25px] bg-[#232323] text-white rounded-full flex justify-center items-center">
                            1
                        </div>
                        <p className="font-semibold text-[15px]">Log In</p>
                    </span>
                    <span className="gap-[10px] flex justify-center items-center">
                        <div className="text-[13px] font-semibold h-[25px] w-[25px] text-black bg-[#C2C2C2] rounded-full flex justify-center items-center">
                            2
                        </div>
                        <p className="font-semibold text-[#8E8E8E] text-[15px]">Shipping</p>
                    </span>
                    <span className="gap-[10px] flex justify-center items-center">
                        <div className="text-[13px] font-semibold h-[25px] w-[25px] text-black bg-[#C2C2C2] rounded-full flex justify-center items-center">
                            3
                        </div>
                        <p className="font-semibold text-[#8E8E8E] text-[15px]">Payments</p>
                    </span>
                </div> */}
            {/* </div> */}
                <div className="login-page--wrapper flex justify-center items-center py-5">
                    <div className="login-page-content text-center w-[280px] font-['Inter']">
                        <img
                            src={storeData.storeLogo}
                            alt=""
                            className="w-[46px] h-[51px] object-contain m-auto mb-[20px]"
                        />
                        <p className="text-[20px] font-bold mb-[15px]">Login to {storeData?.name?.replace(","," ")}</p>
                        <p className="text-[12px] font-medium text-[#6A6A6A] mb-[20px]">
                            Register now to unlock our best prices and get special launch offers. Complete your profile and enjoy a ₹200 discount on your next purchase. Don't miss out—log in and save today!
                        </p>
                        <RegisterForm />
                    </div>
                </div>
            </>
            }
            {isMobile && <RegisterFormMobile />}

        </div>
    );
};

export default RegisterPage;
