import React, { useState } from 'react';
import './ProfileEdit.css';
import MyAccountAside from 'components/account/MyAccountAside';
import {useUpdateUserProfile} from '../../../src/api/userDetails';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetBackgroundColor } from "../../../src/api/index";
import { useNavigate   } from 'react-router-dom';

const ProfileEditDesktop = () => {
  const navigate = useNavigate();
  const refUserDetails = JSON.parse(localStorage.getItem(`vjw-${window.location.hostname}user-det`));
  // Define state for the form fields
  const [firstName, setFirstName] = useState(refUserDetails?.firstName || '');
  const [lastName, setLastName] = useState(refUserDetails?.lastName || '');
  const [mobile, setMobile] = useState(refUserDetails?.phoneNumber?.replace("+91","") || '');
  const [birthDate, setBirthDate] = useState(refUserDetails?.birthDate?.split("T")[0] || '');
  const [anniversaryDate, setAnniversaryDate] = useState(refUserDetails?.anniversaryDate?.split("T")[0] || '');
  const [gender, setGender] = useState(refUserDetails?.gender || '');
  const [pincode, setPincode] = useState('');
  const [occupation, setOccupation] = useState('');
  const [email, setEmail] = useState(refUserDetails?.email || '');
  const { data } = useGetBackgroundColor({ onLoad: true });
  const updateUserProfile = useUpdateUserProfile();
  console.log("refUserDetails",refUserDetails);
  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const data = await updateUserProfile(
        refUserDetails?.id, 
        firstName, 
        lastName, 
        refUserDetails?.phoneNumber, 
        `${birthDate}T00:00:00`, 
        `${anniversaryDate}T00:00:00`, 
        email, 
        gender
      );
      toast.success('Profile updated successfully!');
      console.log("Profile updated data", data);
    } catch (error) {
      toast.error('Failed to update profile. Please try again.');
      console.error('Profile update error', error);
    }
  };
  return (
    <div className="orders-page-container">
      <MyAccountAside/>
      <div className="max-w-5xl mx-auto p-2">
      <div className='flex'>
      <div className='mt-1 cursor-pointer' onClick={() => navigate('/account')}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 27 23" fill="none">
      <path d="M25.3125 11.5L1.6875 11.5M1.6875 11.5L10.3661 21.625M1.6875 11.5L10.3661 1.375" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      </div>
      <h2 className="text-[18px] font-bold text-gray-700 mb-4 ml-5">My Profile</h2>
      </div>
      <div className='bg-white shadow-lg rounded-[15px]' style={{border: '1px solid #00000033'}}>
      <div className='bg-[#B9B9B9] rounded-t-[15px] p-4 text-[16px] font-semibold	' style={{border: '1px solid #00000033'}}>Personal Details</div>
      <form onSubmit={handleSave} className='p-4'>
        {/* Name Section */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-[14px] font-bold text-black mb-2">Let's start with your name*</label>
            <div className="flex space-x-4">
              <input
                type="text"
                placeholder="First Name"
                className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Last Name"
                className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          {/* Gender Section */}
          <div>
            <label className="block text-[14px] font-bold text-black mb-2">Select your Gender*</label>
            <div className="flex space-x-4">
              <button
                type="button"
                className={`w-1/3 p-2 rounded-lg border ${gender === 'male' ? 'text-white' : 'bg-gray-200 text-gray-600'} hover:text-white`}
                onClick={() => setGender('male')}
                style={{backgroundColor : gender === 'male' ? data.color.ctaPrimary : ''}}
              >
                Male
              </button>
              <button
                type="button"
                className={`w-1/3 p-2 rounded-lg border ${gender === 'female' ? 'text-white' : 'bg-gray-200 text-gray-600'} hover:text-white`}
                onClick={() => setGender('female')}
                style={{backgroundColor : gender === 'female' ? data.color.ctaPrimary : ''}}
              >
                Female
              </button>
              <button
                type="button"
                className={`w-1/3 p-2 rounded-lg border ${gender === 'other' ? 'text-white' : 'bg-gray-200 text-gray-600'} hover:text-white`}
                onClick={() => setGender('other')}
                style={{backgroundColor : gender === 'other' ? data.color.ctaPrimary : ''}}
              >
                Other
              </button>
            </div>
          </div>
        </div>

        {/* Mobile and Pincode Section */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-[14px] font-bold text-black mb-2">Enter your mobile number*</label>
            <div className="flex">
              <select className="border border-gray-300 rounded-l-lg p-2" disabled>
                <option value="91">+91</option>
              </select>
              <input
                type="text"
                disabled={true}
                placeholder="Mobile Number"
                className="w-full border border-gray-300 rounded-r-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-[14px] font-bold text-black mb-2">Email address*</label>
            <div className="flex">
              <input
                  type="email"
                  placeholder="Email ID"
                  className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
            </div>
          </div>
          {/* <div className="relative">
            <div className="absolute left-2 top-1/2 mt-1 transform -translate-y-1/2">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <path d="M19.25 9.84375H17.465C17.15 6.51 14.49 3.84125 11.1562 3.535V1.75C11.1562 1.39125 10.8588 1.09375 10.5 1.09375C10.1412 1.09375 9.84375 1.39125 9.84375 1.75V3.535C6.51 3.85 3.84125 6.51 3.535 9.84375H1.75C1.39125 9.84375 1.09375 10.1412 1.09375 10.5C1.09375 10.8588 1.39125 11.1562 1.75 11.1562H3.535C3.85 14.49 6.51 17.1587 9.84375 17.465V19.25C9.84375 19.6087 10.1412 19.9062 10.5 19.9062C10.8588 19.9062 11.1562 19.6087 11.1562 19.25V17.465C14.49 17.15 17.1587 14.49 17.465 11.1562H19.25C19.6087 11.1562 19.9062 10.8588 19.9062 10.5C19.9062 10.1412 19.6087 9.84375 19.25 9.84375ZM10.5 13.23C8.995 13.23 7.77 12.005 7.77 10.5C7.77 8.995 8.995 7.77 10.5 7.77C12.005 7.77 13.23 8.995 13.23 10.5C13.23 12.005 12.005 13.23 10.5 13.23Z" fill="#292D32"/>
            </svg>
            </div>
              <input
                type="text"
                placeholder="Enter Pincode"
                className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 mt-2"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                required
              />
              <button
                type="button"
                className="absolute right-2 top-1/2 mt-1 transform -translate-y-1/2 text-[12px] text-[#222AE7] font-semibold"
              >
                Locate Me
              </button>
            </div> */}
          {/* <div>
            <label className="block  text-[14px] font-bold text-black">Share your pincode*</label>
            <span className='text-[12px] font-semibold text-black'>This helps us find your product in store</span>
            <div className='product-delivery-date flex items-center rounded-lg p-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
            >
              <path
                d='M18.3332 9.37502H16.6332C16.3332 6.20002 13.7998 3.65835 10.6248 3.36669V1.66669C10.6248 1.32502 10.3415 1.04169 9.99984 1.04169C9.65817 1.04169 9.37484 1.32502 9.37484 1.66669V3.36669C6.19984 3.66669 3.65817 6.20002 3.3665 9.37502H1.6665C1.32484 9.37502 1.0415 9.65835 1.0415 10C1.0415 10.3417 1.32484 10.625 1.6665 10.625H3.3665C3.6665 13.8 6.19984 16.3417 9.37484 16.6334V18.3334C9.37484 18.675 9.65817 18.9584 9.99984 18.9584C10.3415 18.9584 10.6248 18.675 10.6248 18.3334V16.6334C13.7998 16.3334 16.3415 13.8 16.6332 10.625H18.3332C18.6748 10.625 18.9582 10.3417 18.9582 10C18.9582 9.65835 18.6748 9.37502 18.3332 9.37502ZM9.99984 12.6C8.5665 12.6 7.39984 11.4334 7.39984 10C7.39984 8.56669 8.5665 7.40002 9.99984 7.40002C11.4332 7.40002 12.5998 8.56669 12.5998 10C12.5998 11.4334 11.4332 12.6 9.99984 12.6Z'
                fill='#292D32'
              />
            </svg>
            <input type="text" class="block w-full ml-1 border-0  focus:outline-none focus:ring-2 focus:ring-white" placeholder="Enter Pincode" />
            <p class="text-[12px] font-semibold whitespace-nowrap ml-2" style={{color : '#222AE7'}}>
            Locate Me
            </p>
          </div>
          </div> */}
        </div>

        {/* Birthdate and Anniversary Date Section */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-[14px] font-bold text-black">Tell us your birth date*</label>
            <span className='text-[12px] font-semibold text-black'>You don’t want to miss out on special discounts</span>
            <input
              type="date"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 mt-2"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-[14px] font-bold text-black">Tell us your anniversary date*</label>
            <span className='text-[12px] font-semibold text-black'>You don’t want to miss out on special discounts</span>
            <input
              type="date"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 mt-2"
              value={anniversaryDate}
              onChange={(e) => setAnniversaryDate(e.target.value)}
              required
            />
          </div>
          
        </div>

        {/* <div className="grid grid-cols-2 gap-4 mb-6">
                <div className="mb-6">
          <label className="block text-[14px] font-bold text-black mb-2">Are you a professional?</label>
          <select
            className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
          >
            <option value="">Occupation (Optional)</option>
            <option value="engineer">Engineer</option>
            <option value="doctor">Doctor</option>
            <option value="teacher">Teacher</option>
          </select>
        </div>
          <div>
            <label className="block text-[14px] font-bold text-black mb-2">Tell us your anniversary date*</label>
            <input
              type="date"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              value={anniversaryDate}
              onChange={(e) => setAnniversaryDate(e.target.value)}
              required
            />
          </div>
        </div> */}


        {/* Save Button */}
        <div className="text-center">
          <button
            type="submit"
            className="p-2 text-white py-3 rounded-lg font-bold w-[350px]"
            style={{backgroundColor : data.color.ctaPrimary}}
          >
          SAVE & CONTINUE
          </button>
        </div>
      </form>
      </div>
    </div>
    {/* Toast container */}
    <ToastContainer />
    </div>
  );
};

export default ProfileEditDesktop;
