import React from 'react';
import EmptyCart from './emptyCart';
import ShoppingCart from './cartDetails';
import { useNavigate  } from 'react-router-dom';
import { useEffect } from 'react';
import { useGetUserDetailsData } from "api";
import CheckOutFooter from './checkoutFooter';
import Loader from 'components/Loader/Loader.jsx';
export default function CheckOutPage() {
    const navigate = useNavigate();
    const { data: userData,loading } = useGetUserDetailsData({ onLoad: true });
   
    let userDetails = userData;   
    
    useEffect(()=>{
      if(userData){
        localStorage.setItem(
            `vjw-${window.location.hostname}user-det`,
            JSON.stringify(userData)
        ); 
      }   
        let userD = JSON.parse(
            localStorage.getItem(`vjw-${window.location.hostname}user-det`)
            );
        console.log("userDetailsuserDetailsuserDetailsuserDetails",userD)
        if(!userD){
          navigate({
            pathname: `/login`
          });  
        }
      },[userData]);
 return(
    <>
    { loading && 
    <Loader/>
    }
    {(!userDetails || !userDetails?.checkout || ( !userDetails?.checkout?.lines.length && userDetails?.checkout?.lines.length===0) ) && !loading &&
        <EmptyCart></EmptyCart>
    }
    {userDetails &&  userDetails?.checkout && userDetails?.checkout?.lines.length!==0 && 
        <ShoppingCart checkoutLine={userDetails?.checkout}></ShoppingCart>       
    }
    <CheckOutFooter></CheckOutFooter>
    </>
 )
}