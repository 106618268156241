import React from "react";
import { useResponsive } from 'hooks';
import { useSelector } from 'react-redux';
const CheckOutFooter = () => {
  const { isDesktop, isMobile } = useResponsive();
  const storeData = useSelector(state => state.storeData);
  console.log("storeDatastoreDatastoreData",storeData?.user?.phoneNumber)
  return (
    <>
    <div style={{marginTop : '70px'}}></div>
    <div className="fixed w-full bottom-0">
    {!isMobile && <footer style={styles.footer}>
      <div style={styles.contactInfo} className="font-semibold">
        <span>Contact Us: {storeData?.user?.phoneNumber} (Helpline)</span>
      </div>
      <div style={styles.paymentIcons} >
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="27" viewBox="0 0 40 27" fill="none">
      <rect x="0.5" y="0.5" width="39" height="26" rx="3.5" fill="white" stroke="#D9D9D9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1429 18.2905H9.71965L7.90253 11.4664C7.81628 11.1525 7.63315 10.875 7.36377 10.7442C6.6915 10.4155 5.95071 10.1539 5.14258 10.022V9.75926H9.0462C9.58496 9.75926 9.98903 10.1539 10.0564 10.6123L10.9992 15.5347L13.4212 9.75926H15.7771L12.1429 18.2905ZM17.124 18.2905H14.8355L16.72 9.75926H19.0085L17.124 18.2905ZM21.9693 12.1227C22.0366 11.6632 22.4407 11.4005 22.9121 11.4005C23.6529 11.3345 24.4599 11.4664 25.1333 11.794L25.5374 9.95716C24.8639 9.69443 24.1231 9.5625 23.4509 9.5625C21.2297 9.5625 19.6134 10.7442 19.6134 12.3843C19.6134 13.6319 20.7583 14.2871 21.5664 14.6817C22.4407 15.0752 22.7774 15.338 22.7101 15.7315C22.7101 16.3218 22.0366 16.5845 21.3644 16.5845C20.5562 16.5845 19.7481 16.3877 19.0085 16.0591L18.6044 17.897C19.4126 18.2246 20.2869 18.3565 21.095 18.3565C23.5856 18.4213 25.1333 17.2408 25.1333 15.4688C25.1333 13.2373 21.9693 13.1065 21.9693 12.1227ZM33.1426 18.2905L31.3255 9.75926H29.3736C28.9696 9.75926 28.5655 10.022 28.4308 10.4155L25.0659 18.2905H27.4218L27.8921 17.044H30.7867L31.0561 18.2905H33.1426ZM29.7104 12.0567L30.3826 15.272H28.4982L29.7104 12.0567Z" fill="#616161"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="27" viewBox="0 0 40 27" fill="none">
      <rect x="0.5" y="0.5" width="39" height="26" rx="3.5" fill="white" stroke="#D9D9D9"/>
      <path d="M23.0259 13.3909C23.0259 17.6798 19.5344 21.1567 15.2273 21.1567C10.9203 21.1567 7.42871 17.6798 7.42871 13.3909C7.42871 9.10189 10.9203 5.625 15.2273 5.625C19.5344 5.625 23.0259 9.10189 23.0259 13.3909Z" fill="#ED0006"/>
      <path d="M33.1431 13.3909C33.1431 17.6798 29.6515 21.1567 25.3445 21.1567C21.0374 21.1567 17.5459 17.6798 17.5459 13.3909C17.5459 9.10189 21.0374 5.625 25.3445 5.625C29.6515 5.625 33.1431 9.10189 33.1431 13.3909Z" fill="#F9A000"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2859 19.3018C18.6091 17.8774 17.5459 15.7579 17.5459 13.3911C17.5459 11.0244 18.6091 8.90487 20.2859 7.48047C21.9627 8.90487 23.0259 11.0244 23.0259 13.3911C23.0259 15.7579 21.9627 17.8774 20.2859 19.3018Z" fill="#FF5E00"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="26" viewBox="0 0 38 26" fill="none">
      <rect x="0.5" y="0.5" width="37" height="25" rx="3.5" fill="white" stroke="#F3F3F3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.291 15.6773V12.7451V12.7445H19.8898C20.549 12.7449 21.103 12.5394 21.5518 12.128C22.0054 11.7376 22.2589 11.1763 22.2461 10.5911C22.255 10.0093 22.0019 9.45209 21.5518 9.06292C21.1069 8.64929 20.5086 8.42361 19.8898 8.43603H17.3232V15.6773H18.291ZM18.291 11.8553V9.32754V9.32697H19.9139C20.2756 9.31719 20.6243 9.45711 20.8714 9.71119C21.12 9.94355 21.2603 10.2627 21.2603 10.5961C21.2603 10.9294 21.12 11.2486 20.8714 11.4809C20.6212 11.7297 20.2736 11.8656 19.9139 11.8553H18.291Z" fill="#5F6368"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M26.1697 11.1117C25.7558 10.7443 25.1912 10.5605 24.4759 10.5605C23.5571 10.5605 22.8632 10.8841 22.3944 11.5312L23.2467 12.0472C23.5617 11.6088 23.9894 11.3897 24.53 11.3897C24.8746 11.3858 25.2082 11.5069 25.4641 11.7288C25.7192 11.9316 25.866 12.2339 25.8638 12.5521V12.7648C25.4919 12.5625 25.0187 12.4614 24.444 12.4614C23.7708 12.4622 23.2327 12.614 22.8296 12.9167C22.4264 13.2195 22.2249 13.6272 22.2249 14.1399C22.2161 14.6067 22.4268 15.0525 22.7983 15.3532C23.1806 15.6768 23.6556 15.8385 24.2234 15.8385C24.8886 15.8385 25.4216 15.5554 25.8223 14.9892H25.8644V15.6768H26.79V12.6232C26.7904 11.983 26.5837 11.4792 26.1697 11.1117ZM23.5449 14.7668C23.3422 14.6263 23.2226 14.4003 23.2239 14.1601C23.2239 13.8903 23.3555 13.6655 23.6206 13.4806C23.8831 13.2984 24.2109 13.2073 24.604 13.2073C25.1437 13.2073 25.5645 13.3229 25.8662 13.554C25.8662 13.9446 25.7059 14.2849 25.3854 14.5749C25.0966 14.8525 24.7052 15.0088 24.2968 15.0094C24.0246 15.0143 23.7589 14.9286 23.5449 14.7668Z" fill="#5F6368"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32.1033 10.7227L28.8719 17.8611H27.8729L29.0721 15.3634L26.9473 10.7227H27.9991L29.5349 14.2818H29.5559L31.0496 10.7227H32.1033Z" fill="#5F6368"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3804 12.1125C14.3808 11.829 14.3559 11.546 14.3059 11.2666H10.2246V12.8688H12.5622C12.4655 13.3859 12.1533 13.8425 11.6978 14.133V15.173H13.0929C13.9098 14.449 14.3804 13.3784 14.3804 12.1125Z" fill="#4285F4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2241 16.1773C11.392 16.1773 12.3754 15.8087 13.0925 15.1731L11.6974 14.1331C11.3091 14.3862 10.809 14.5306 10.2241 14.5306C9.09532 14.5306 8.13721 13.7992 7.79459 12.8135H6.35742V13.8853C7.09203 15.2904 8.58815 16.1772 10.2241 16.1773Z" fill="#34A853"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.79448 12.8135C7.61336 12.297 7.61336 11.7377 7.79448 11.2212V10.1494H6.35731C5.74288 11.3247 5.74288 12.7101 6.35731 13.8853L7.79448 12.8135Z" fill="#FBBC04"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2241 9.50436C10.8413 9.49467 11.4377 9.71882 11.8843 10.1284L13.1195 8.94103C12.3363 8.23387 11.2986 7.84563 10.2241 7.85769C8.58815 7.85777 7.09203 8.74461 6.35742 10.1497L7.79459 11.2215C8.13721 10.2358 9.09532 9.50436 10.2241 9.50436Z" fill="#EA4335"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="26" viewBox="0 0 38 26" fill="none">
      <rect x="0.5" y="0.5" width="37" height="25" rx="3.5" fill="#5F5F5F" stroke="#D9D9D9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25358 9.20801L3.7998 17.0586H7.93446L8.44704 15.8069H9.61867L10.1313 17.0586H14.6823V16.1033L15.0879 17.0586H17.4421L17.8476 16.0831V17.0586H27.3126L28.4635 15.8394L29.5412 17.0586L34.4026 17.0687L30.938 13.1552L34.4026 9.20801H29.6166L28.4963 10.4047L27.4525 9.20801H17.1558L16.2716 11.2343L15.3667 9.20801H11.2407V10.1309L10.7817 9.20801H7.25358ZM21.3877 10.3228H26.823L28.4854 12.1673L30.2013 10.3228H31.8637L29.3379 13.1541L31.8637 15.9528H30.1259L28.4635 14.087L26.7388 15.9528H21.3877V10.3228ZM22.7299 12.5176V11.4892V11.4882H26.1213L27.6012 13.1328L26.0558 14.7864H22.7299V13.6638H25.6951V12.5176H22.7299ZM8.05362 10.3228H10.069L12.3599 15.6464V10.3228H14.5677L16.3372 14.1398L17.9679 10.3228H20.1647V15.9562H18.828L18.8171 11.5419L16.8684 15.9562H15.6726L13.7129 11.5419V15.9562H10.9631L10.4417 14.6932H7.62519L7.10494 15.9551H5.63159L8.05362 10.3228ZM8.10611 13.5257L9.03405 11.2759L9.96091 13.5257H8.10611Z" fill="white"/>
      </svg>
      </div>
    </footer>}
    {isMobile &&
    <footer className="font-semibold flex flex-col items-start text-sm mt-3 px-2 bg-[#D9D9D9]">
      <div className="flex flex-col mb-1">
        <span>Contact Us: {storeData?.user?.phoneNumber}  (Helpline)</span>
      </div>
      <div style={styles.paymentIcons} className="mb-2 text-center">
      {/* <span>contactus@goldclub.co</span> */}
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="27" viewBox="0 0 40 27" fill="none">
      <rect x="0.5" y="0.5" width="39" height="26" rx="3.5" fill="white" stroke="#D9D9D9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1429 18.2905H9.71965L7.90253 11.4664C7.81628 11.1525 7.63315 10.875 7.36377 10.7442C6.6915 10.4155 5.95071 10.1539 5.14258 10.022V9.75926H9.0462C9.58496 9.75926 9.98903 10.1539 10.0564 10.6123L10.9992 15.5347L13.4212 9.75926H15.7771L12.1429 18.2905ZM17.124 18.2905H14.8355L16.72 9.75926H19.0085L17.124 18.2905ZM21.9693 12.1227C22.0366 11.6632 22.4407 11.4005 22.9121 11.4005C23.6529 11.3345 24.4599 11.4664 25.1333 11.794L25.5374 9.95716C24.8639 9.69443 24.1231 9.5625 23.4509 9.5625C21.2297 9.5625 19.6134 10.7442 19.6134 12.3843C19.6134 13.6319 20.7583 14.2871 21.5664 14.6817C22.4407 15.0752 22.7774 15.338 22.7101 15.7315C22.7101 16.3218 22.0366 16.5845 21.3644 16.5845C20.5562 16.5845 19.7481 16.3877 19.0085 16.0591L18.6044 17.897C19.4126 18.2246 20.2869 18.3565 21.095 18.3565C23.5856 18.4213 25.1333 17.2408 25.1333 15.4688C25.1333 13.2373 21.9693 13.1065 21.9693 12.1227ZM33.1426 18.2905L31.3255 9.75926H29.3736C28.9696 9.75926 28.5655 10.022 28.4308 10.4155L25.0659 18.2905H27.4218L27.8921 17.044H30.7867L31.0561 18.2905H33.1426ZM29.7104 12.0567L30.3826 15.272H28.4982L29.7104 12.0567Z" fill="#616161"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="27" viewBox="0 0 40 27" fill="none">
      <rect x="0.5" y="0.5" width="39" height="26" rx="3.5" fill="white" stroke="#D9D9D9"/>
      <path d="M23.0259 13.3909C23.0259 17.6798 19.5344 21.1567 15.2273 21.1567C10.9203 21.1567 7.42871 17.6798 7.42871 13.3909C7.42871 9.10189 10.9203 5.625 15.2273 5.625C19.5344 5.625 23.0259 9.10189 23.0259 13.3909Z" fill="#ED0006"/>
      <path d="M33.1431 13.3909C33.1431 17.6798 29.6515 21.1567 25.3445 21.1567C21.0374 21.1567 17.5459 17.6798 17.5459 13.3909C17.5459 9.10189 21.0374 5.625 25.3445 5.625C29.6515 5.625 33.1431 9.10189 33.1431 13.3909Z" fill="#F9A000"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2859 19.3018C18.6091 17.8774 17.5459 15.7579 17.5459 13.3911C17.5459 11.0244 18.6091 8.90487 20.2859 7.48047C21.9627 8.90487 23.0259 11.0244 23.0259 13.3911C23.0259 15.7579 21.9627 17.8774 20.2859 19.3018Z" fill="#FF5E00"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="26" viewBox="0 0 38 26" fill="none">
      <rect x="0.5" y="0.5" width="37" height="25" rx="3.5" fill="white" stroke="#F3F3F3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.291 15.6773V12.7451V12.7445H19.8898C20.549 12.7449 21.103 12.5394 21.5518 12.128C22.0054 11.7376 22.2589 11.1763 22.2461 10.5911C22.255 10.0093 22.0019 9.45209 21.5518 9.06292C21.1069 8.64929 20.5086 8.42361 19.8898 8.43603H17.3232V15.6773H18.291ZM18.291 11.8553V9.32754V9.32697H19.9139C20.2756 9.31719 20.6243 9.45711 20.8714 9.71119C21.12 9.94355 21.2603 10.2627 21.2603 10.5961C21.2603 10.9294 21.12 11.2486 20.8714 11.4809C20.6212 11.7297 20.2736 11.8656 19.9139 11.8553H18.291Z" fill="#5F6368"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M26.1697 11.1117C25.7558 10.7443 25.1912 10.5605 24.4759 10.5605C23.5571 10.5605 22.8632 10.8841 22.3944 11.5312L23.2467 12.0472C23.5617 11.6088 23.9894 11.3897 24.53 11.3897C24.8746 11.3858 25.2082 11.5069 25.4641 11.7288C25.7192 11.9316 25.866 12.2339 25.8638 12.5521V12.7648C25.4919 12.5625 25.0187 12.4614 24.444 12.4614C23.7708 12.4622 23.2327 12.614 22.8296 12.9167C22.4264 13.2195 22.2249 13.6272 22.2249 14.1399C22.2161 14.6067 22.4268 15.0525 22.7983 15.3532C23.1806 15.6768 23.6556 15.8385 24.2234 15.8385C24.8886 15.8385 25.4216 15.5554 25.8223 14.9892H25.8644V15.6768H26.79V12.6232C26.7904 11.983 26.5837 11.4792 26.1697 11.1117ZM23.5449 14.7668C23.3422 14.6263 23.2226 14.4003 23.2239 14.1601C23.2239 13.8903 23.3555 13.6655 23.6206 13.4806C23.8831 13.2984 24.2109 13.2073 24.604 13.2073C25.1437 13.2073 25.5645 13.3229 25.8662 13.554C25.8662 13.9446 25.7059 14.2849 25.3854 14.5749C25.0966 14.8525 24.7052 15.0088 24.2968 15.0094C24.0246 15.0143 23.7589 14.9286 23.5449 14.7668Z" fill="#5F6368"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32.1033 10.7227L28.8719 17.8611H27.8729L29.0721 15.3634L26.9473 10.7227H27.9991L29.5349 14.2818H29.5559L31.0496 10.7227H32.1033Z" fill="#5F6368"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3804 12.1125C14.3808 11.829 14.3559 11.546 14.3059 11.2666H10.2246V12.8688H12.5622C12.4655 13.3859 12.1533 13.8425 11.6978 14.133V15.173H13.0929C13.9098 14.449 14.3804 13.3784 14.3804 12.1125Z" fill="#4285F4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2241 16.1773C11.392 16.1773 12.3754 15.8087 13.0925 15.1731L11.6974 14.1331C11.3091 14.3862 10.809 14.5306 10.2241 14.5306C9.09532 14.5306 8.13721 13.7992 7.79459 12.8135H6.35742V13.8853C7.09203 15.2904 8.58815 16.1772 10.2241 16.1773Z" fill="#34A853"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.79448 12.8135C7.61336 12.297 7.61336 11.7377 7.79448 11.2212V10.1494H6.35731C5.74288 11.3247 5.74288 12.7101 6.35731 13.8853L7.79448 12.8135Z" fill="#FBBC04"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2241 9.50436C10.8413 9.49467 11.4377 9.71882 11.8843 10.1284L13.1195 8.94103C12.3363 8.23387 11.2986 7.84563 10.2241 7.85769C8.58815 7.85777 7.09203 8.74461 6.35742 10.1497L7.79459 11.2215C8.13721 10.2358 9.09532 9.50436 10.2241 9.50436Z" fill="#EA4335"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="26" viewBox="0 0 38 26" fill="none">
      <rect x="0.5" y="0.5" width="37" height="25" rx="3.5" fill="#5F5F5F" stroke="#D9D9D9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25358 9.20801L3.7998 17.0586H7.93446L8.44704 15.8069H9.61867L10.1313 17.0586H14.6823V16.1033L15.0879 17.0586H17.4421L17.8476 16.0831V17.0586H27.3126L28.4635 15.8394L29.5412 17.0586L34.4026 17.0687L30.938 13.1552L34.4026 9.20801H29.6166L28.4963 10.4047L27.4525 9.20801H17.1558L16.2716 11.2343L15.3667 9.20801H11.2407V10.1309L10.7817 9.20801H7.25358ZM21.3877 10.3228H26.823L28.4854 12.1673L30.2013 10.3228H31.8637L29.3379 13.1541L31.8637 15.9528H30.1259L28.4635 14.087L26.7388 15.9528H21.3877V10.3228ZM22.7299 12.5176V11.4892V11.4882H26.1213L27.6012 13.1328L26.0558 14.7864H22.7299V13.6638H25.6951V12.5176H22.7299ZM8.05362 10.3228H10.069L12.3599 15.6464V10.3228H14.5677L16.3372 14.1398L17.9679 10.3228H20.1647V15.9562H18.828L18.8171 11.5419L16.8684 15.9562H15.6726L13.7129 11.5419V15.9562H10.9631L10.4417 14.6932H7.62519L7.10494 15.9551H5.63159L8.05362 10.3228ZM8.10611 13.5257L9.03405 11.2759L9.96091 13.5257H8.10611Z" fill="white"/>
      </svg>
      </div>
    </footer>
    }
    </div>
    </>
  );
};

const styles = {
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    backgroundColor: "#D9D9D9",
    borderTop: "1px solid #ccc",
    fontSize: "14px",
  },
  contactInfo: {
    display: "flex",
    alignItems: "center",
  },
  separator: {
    margin: "0 10px",
  },
  paymentIcons: {
    display: "flex",
    alignItems: "right",
    gap: 10
  },
  icon: {
    width: "40px",
    marginLeft: "10px",
  },
};

export default CheckOutFooter;
