import * as React from 'react';
const Youtube = props => (
  <svg
    width={19}
    height={14}
    viewBox='0 0 19 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M18.1783 3.35205C18.1783 3.35205 18.0002 2.09448 17.4516 1.5423C16.7569 0.815547 15.9802 0.811984 15.624 0.769234C13.0732 0.583984 9.24356 0.583984 9.24356 0.583984H9.23643C9.23643 0.583984 5.40675 0.583984 2.856 0.769234C2.49975 0.811984 1.72312 0.815547 1.02843 1.5423C0.479808 2.09448 0.305245 3.35205 0.305245 3.35205C0.305245 3.35205 0.119995 4.83049 0.119995 6.30536V7.68761C0.119995 9.16249 0.301683 10.6409 0.301683 10.6409C0.301683 10.6409 0.479808 11.8985 1.02487 12.4507C1.71956 13.1774 2.63156 13.1525 3.03768 13.2309C4.49831 13.3698 9.24 13.4125 9.24 13.4125C9.24 13.4125 13.0732 13.4054 15.624 13.2237C15.9802 13.181 16.7569 13.1774 17.4516 12.4507C18.0002 11.8985 18.1783 10.6409 18.1783 10.6409C18.1783 10.6409 18.36 9.16605 18.36 7.68761V6.30536C18.36 4.83049 18.1783 3.35205 18.1783 3.35205ZM7.35543 9.36555V4.23911L12.2824 6.81123L7.35543 9.36555Z'
      fill='white'
    />
  </svg>
);
export default Youtube;
