import React, { useState } from "react";
import ConfirmationModal from "./confirmOrder";
import { useNavigate  } from 'react-router-dom';
import './checkout.css';
import { faChevronUp, faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResponsive } from 'hooks';
import OrderSummaryProducts from "./orderSummaryProducts";
import {LoginPage} from "pages/index";
import Shipping from "./shipping";
import { useGetUserDetailsData } from "api";
import CheckOutFooter from "./checkoutFooter";
import { useSelector } from 'react-redux';

const OrderSummary = () => {
  const storeData = useSelector(state => state.storeData);
  function getFormattedDate(daysToAdd) {
    const date = new Date();
    date.setDate(date.getDate() + daysToAdd);

    const day = date.getDate();
    const suffix = getDaySuffix(day);
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    return `${day}${suffix} ${month} ${year}`;
}

function getDaySuffix(day) {
    if (day > 3 && day < 21) return 'th'; // 11th, 12th, 13th all get 'th'
    switch (day % 10) {
        case 1:  return 'st';
        case 2:  return 'nd';
        case 3:  return 'rd';
        default: return 'th';
    }
}
  const TABS = {
    LOGIN : 'Login',
    SHIPPING : 'Shipping',
    PAYMENT : 'Payment'
  }
  const { isDesktop, isMobile } = useResponsive();
  const [confirmationModel, setConfirmationModel] = useState(false);
  const [hideOrderSummary, setHideOrderSummary] = useState(true);
  const [tab, setTab] = useState(TABS.SHIPPING);
  const [isSameAsShipping, setIsSameAsShipping] = useState(true);
  const navigate = useNavigate();
  const handleEdit = () => {
    console.log("Edit button clicked");
  };

  const handleConfirm = () => {
    setConfirmationModel(false)
    console.log("Confirm button clicked1");
  };
  const { data: userData } = useGetUserDetailsData({ onLoad: true });
  let checkoutLine = userData?.checkout;
  // JSON.parse(
  //   localStorage.getItem(`vjw-${window.location.hostname}user-det`)
  //   )?.checkout;
  console.log("checkoutLine?.lines",checkoutLine?.lines)
  const products = checkoutLine?.lines?.map((line,index)=>{
    if(line){
      let size;
      if(line?.variant?.attributes){
        size = line?.variant?.attributes?.map((att)=>{
          if(att?.attribute?.name==="Size"){
            return att?.values[0]?.name;
          }
          return null;
        }).filter((attf)=>attf);    
      }else{
        size = ['Made to Order']
      }
      console.log("sizesizesizesize",size) 
      if(size?.length){
        if(line?.variant){
          return {
            id: line?.id,
            name: line?.variant?.product?.name,
            price: (checkoutLine?.metadata?.length > index ? parseInt(JSON.parse(checkoutLine?.metadata[index]?.value?.replaceAll('" -',"# -").replaceAll("'",'"').replaceAll("# -","' -"))['Total Price'] || 0) : 0)*parseInt(line?.quantity),
            size: size[0],
            quantity: line?.quantity,
            estDelivery: getFormattedDate(2),
            imgSrc: line?.variant?.product?.images[0]?.url,
          }
        }
        if(line?.dbpVariantData){
          let productImg = "https://s3.ap-south-1.amazonaws.com/goldclub.co/2023/products/"+line?.dbpVariantData?.images[0].split("/products/")[1]         
          return {
            id: line?.id,
            name: line?.dbpVariantData?.product?.name,
            price: (checkoutLine?.metadata?.length > index ? parseInt(JSON.parse(checkoutLine?.metadata[index]?.value?.replaceAll('" -',"# -").replaceAll("'",'"').replaceAll("# -","' -"))['Total Price'] || 0) : 0)*parseInt(line?.quantity),
            size: size[0],
            quantity: line?.quantity,
            estDelivery: getFormattedDate(line?.dbpVariantData?.product?.makingDays || 2),
            imgSrc: productImg,
          }
        }
      }          
    }
    return null
  }).filter((data)=>data);  
  let subtotal = 0;
  if(products && products.length){
    subtotal = products?.reduce(
      (total, product) => total + product?.price,
      0
    );
  }
  if(products?.length===0) navigate({pathname: `/checkout`});
  // const products = [
  //   {
  //     id: 1,
  //     name: 'Brian Platinum Ring for Men',
  //     price: 43060,
  //     size: 18,
  //     quantity: 1,
  //     estDelivery: '7th Sept',
  //     imgSrc: '/assets/img/category/Rectangle-1.png', // Replace with actual image paths
  //   },
  //   {
  //     id: 2,
  //     name: 'Brian Platinum Ring for Men',
  //     price: 43060,
  //     size: 18,
  //     quantity: 1,
  //     estDelivery: '7th Sept',
  //     imgSrc: '/assets/img/category/Rectangle-2.png',
  //   },
  //   {
  //     id: 3,
  //     name: 'Brian Platinum Ring for Men',
  //     price: 43060,
  //     size: 18,
  //     quantity: 1,
  //     estDelivery: '7th Sept',
  //     imgSrc: '/assets/img/category/Rectangle-3.png',
  //   },
  // ];

  return (
    <>
    {isMobile &&
    <div className="max-w-full mx-auto">
      {/* Shopping Cart Header */}
      <div className="flex items-center justify-between checkout-header p-4 shadow">
      <button onClick={() => navigate({pathname: `/checkout`})}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='27'
            height='27'
            viewBox='0 0 27 27'
            fill='none'
          >
            <path
              d='M25.3125 13.5L1.6875 13.5M1.6875 13.5L10.3661 23.625M1.6875 13.5L10.3661 3.375'
              stroke='black'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
        <h1 className="text-lg font-semibold">Shopping Cart ({products?.length})</h1>
        <button onClick={()=>window.open(`https://wa.me/${storeData?.storePhoneNumber}`, '_blank')}>
          {/* WhatsApp icon */}
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              d='M0 24L1.687 17.837C0.645998 16.033 0.0989998 13.988 0.0999998 11.891C0.103 5.335 5.43799 0 11.993 0C15.174 0.001 18.16 1.24 20.406 3.488C22.6509 5.736 23.8869 8.724 23.8859 11.902C23.8829 18.459 18.548 23.794 11.993 23.794C10.003 23.793 8.04198 23.294 6.30499 22.346L0 24ZM6.59698 20.193C8.27298 21.188 9.87298 21.784 11.989 21.785C17.437 21.785 21.875 17.351 21.878 11.9C21.88 6.438 17.463 2.01 11.997 2.008C6.54498 2.008 2.11 6.442 2.108 11.892C2.107 14.117 2.75899 15.783 3.85399 17.526L2.85499 21.174L6.59698 20.193ZM17.984 14.729C17.91 14.605 17.712 14.531 17.414 14.382C17.117 14.233 15.656 13.514 15.383 13.415C15.111 13.316 14.913 13.266 14.714 13.564C14.516 13.861 13.946 14.531 13.773 14.729C13.6 14.927 13.426 14.952 13.129 14.803C12.832 14.654 11.874 14.341 10.739 13.328C9.85598 12.54 9.25898 11.567 9.08598 11.269C8.91298 10.972 9.06798 10.811 9.21598 10.663C9.34998 10.53 9.51298 10.316 9.66198 10.142C9.81298 9.97 9.86198 9.846 9.96198 9.647C10.061 9.449 10.012 9.275 9.93698 9.126C9.86198 8.978 9.26798 7.515 9.02098 6.92C8.77898 6.341 8.53398 6.419 8.35198 6.41L7.78198 6.4C7.58398 6.4 7.26198 6.474 6.98998 6.772C6.71798 7.07 5.94999 7.788 5.94999 9.251C5.94999 10.714 7.01498 12.127 7.16298 12.325C7.31198 12.523 9.25798 15.525 12.239 16.812C12.948 17.118 13.502 17.301 13.933 17.438C14.645 17.664 15.293 17.632 15.805 17.556C16.376 17.471 17.563 16.837 17.811 16.143C18.059 15.448 18.059 14.853 17.984 14.729Z'
              fill='#111111'
            />
          </svg>
        </button>
      </div>
      <div className="p-2">
      {/* Order Summary */}
      <div className="flex ml-4">
      <h2 className="font-semibold">Hide Order Summary</h2>
      <div className="ml-2 cursor-pointer	">
      <FontAwesomeIcon icon={hideOrderSummary ? faChevronDown : faChevronUp} onClick={() => setHideOrderSummary(!hideOrderSummary)}/>    
       </div>
      </div>
      {hideOrderSummary && products?.length && <div className="bg-gray-100 p-3 rounded-lg shadow-md mx-auto mt-4 mb-4">
      {/* Products */}
      {products?.map(product => ( <div className="flex justify-between items-center pb-4"  key={product?.id}>
        <div className="flex space-x-2">
          <img
            src={product?.imgSrc}     
            alt={product?.name}
            className="h-[6rem] w-[5.5rem]"
            style={{border: '1px solid #000000',borderRadius: '15px'}}
          />
          <div>
            <p className="font-semibold sm:text-[12px]">{product?.name}</p>
            <p className="order-summary-text font-bold sm:text-[12px] mt-[3px]">₹{product?.price?.toLocaleString()}</p>
            <p className="order-summary-text font-semibold sm:text-[10px] mt-[7px]">Size: {product?.size} | Quantity: {product?.quantity}</p>
            <p className="text-xs order-summary-text font-medium sm:text-[10px] mt-[5px]">Est. Delivery by {product?.estDelivery}</p>
          </div>
        </div>
        {product?.imgSrc1 &&
          <img
            src={product?.imgSrc1}
            alt="Product 1"
            className="h-[6rem] w-[5.5rem] product-img"
            style={{border: '1px solid #000000',borderRadius: '15px'}}
          />
        }
      </div>))}

      {/* Order Summary */}
      <div className="font-semibold p-2 -mb-1">Order Summary</div>
      <div className="p-2 order-summary-text">
        <div className="flex justify-between mb-3 font-semibold text-sm">
          <p>Subtotal</p>
          <p>₹{subtotal?.toLocaleString()}</p>
        </div>
        <div className="flex justify-between mb-3 font-semibold text-sm">
          <p>Shipping Charge</p>
          <p>Free</p>
        </div>
        <div className="flex justify-between mb-2 font-semibold text-sm">
          <p>Shipping Insurance</p>
          <p>Free</p>
        </div>
        <hr className="border-t-[3px] border-white my-2" />
        <div className="flex justify-between font-semibold text-lg">
          <p>Grand Total</p>
          <p>₹{subtotal?.toLocaleString()}</p>
        </div>
      </div>
      </div>}

      {/* Shipping / Payment */}
      <div className="flex space-x-4 items-center justify-center">
      {/* Step 1: Shipping */}
      <div className="flex items-center space-x-2" onClick={() => setTab(TABS.SHIPPING)}>
        <div className="w-7 h-7 rounded-full bg-black text-white flex items-center justify-center font-semibold">
        {(tab == TABS.SHIPPING) ? 1 : <FontAwesomeIcon icon={faCheck}/>}
        </div>
        <p className="font-medium">Shipping</p>
      </div>

      {/* Step 2: Payment */}
      <div className="flex items-center space-x-2" onClick={() => setTab(TABS.PAYMENT)}>
        <div className="w-7 h-7 rounded-full bg-[#4F4F4F] text-white flex items-center justify-center font-semibold">
        {(tab == TABS.SHIPPING || tab == TABS.PAYMENT) ? 2 : <FontAwesomeIcon icon={faCheck}/>}
        </div>
        <p className="font-medium text-gray-500">Payment</p>
      </div>
    </div>
    {tab == TABS.SHIPPING && <Shipping></Shipping>}
    {false && <>
      {/* Delivery Details */}
      <div className="text-2xl font-semibold text-center mt-6 mb-2">Delivery Details</div>
        {/* Home Delivery */}
        <div
          className="p-3 border rounded-lg mb-4 bg-[#D9D9D9]"
        >
        <div className="flex justify-between">
        <div>
         <p className="font-bold">Home Delivery</p>
          <span className="text-sm font-medium">
            Earliest Delivery dates selected for your Pincode
          </span>
        </div>
        <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <circle cx="10" cy="10" r="9" stroke="black" stroke-width="2"/>
        <circle cx="10" cy="10" r="5" fill="black"/>
        </svg>
        </div>
        </div>

          {/* <button className="mt-3 p-2 font-medium bg-[#EDEDED] rounded-[12px]">
          <div className="flex">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
          <path d="M16.5 11.6875H5.5C5.12417 11.6875 4.8125 11.3758 4.8125 11C4.8125 10.6242 5.12417 10.3125 5.5 10.3125H16.5C16.8758 10.3125 17.1875 10.6242 17.1875 11C17.1875 11.3758 16.8758 11.6875 16.5 11.6875Z" fill="#292D32"/>
          <path d="M11 17.1875C10.6242 17.1875 10.3125 16.8758 10.3125 16.5V5.5C10.3125 5.12417 10.6242 4.8125 11 4.8125C11.3758 4.8125 11.6875 5.12417 11.6875 5.5V16.5C11.6875 16.8758 11.3758 17.1875 11 17.1875Z" fill="#292D32"/>
          </svg>&nbsp; Choose your Delivery Date
          </div>
          </button> */}
        </div>
      <div
        className="bg-[#F6F6F6] border-[#0000001A] boarder-1 flex justify-between items-center p-4 border rounded-lg cursor-pointer mt-3"
        onClick={() => { setIsSameAsShipping(false); navigate({pathname: `/addAddress`})}}
      >
        <div>
        <p className="font-semibold">In-store Pick Up</p>
        <span className="sm:text-xs">Buy now, pick up from our store at your convenience</span>
        </div>
        {/* Plus Icon */}
        <div className="w-6 h-6 flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <circle cx="10" cy="10" r="9" stroke="black" stroke-width="2"/>
        </svg>
        </div>
      </div>

      {/* Shipping Address */}
      <div className="text-2xl font-semibold text-center mt-4 mb-2">Shipping Address</div>

      <div className="bg-[#D9D9D9]" style={{border: '1px solid #828282A6', borderRadius : '10px'}}>

      <div className="p-3 ">
      <div className="flex justify-between">
        <p className="font-bold">John Doe</p>
        <a className="font-medium">Edit</a>
      </div>
        <p className="font-medium my-2">108/A, Bijon Behari Ganguly Street, Kolkata, West Bengal, 700012, India</p>
        <p className="font-bold">Phone: +91 9988441411</p>
        <div className="flex justify-between">
        <p className="font-bold">Address Type: Home</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M10.0003 1.66669C5.40866 1.66669 1.66699 5.40835 1.66699 10C1.66699 14.5917 5.40866 18.3334 10.0003 18.3334C14.592 18.3334 18.3337 14.5917 18.3337 10C18.3337 5.40835 14.592 1.66669 10.0003 1.66669ZM13.9837 8.08335L9.25866 12.8084C9.14199 12.925 8.98366 12.9917 8.81699 12.9917C8.65033 12.9917 8.49199 12.925 8.37533 12.8084L6.01699 10.45C5.77533 10.2084 5.77533 9.80835 6.01699 9.56669C6.25866 9.32502 6.65866 9.32502 6.90033 9.56669L8.81699 11.4834L13.1003 7.20002C13.342 6.95835 13.742 6.95835 13.9837 7.20002C14.2253 7.44169 14.2253 7.83335 13.9837 8.08335Z" fill="#292D32"/>
        </svg>
        </div>
      </div>
      </div>
      {/* Add a new address option */}
      <div
      className="bg-[#F6F6F6] border-[#0000001A] boarder-1 flex justify-between items-center p-4 border rounded-lg cursor-pointer mt-3"
      onClick={() => { setIsSameAsShipping(false); navigate({pathname: `/addAddress`})}}
    >
      <p className="font-semibold">Add a new address</p>
      {/* Plus Icon */}
      <div className="w-6 h-6 flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g opacity="0.5">
      <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill="#292D32"/>
      </g>
      </svg>
      </div>
      </div>
            
      {/* Billing Address */}
      <div className="p-4 w-full mx-auto">
      {/* Title */}
      <div className="text-2xl font-semibold text-center mb-4">Billing Address</div>

      {/* Same as shipping address option */}
      <div
        className="flex justify-between items-center p-4 mb-2 border rounded-lg cursor-pointer bg-[#F6F6F6] border-[#0000001A] boarder-1"
        onClick={() => setIsSameAsShipping(true)}
      >
        <div>
          <p className="font-semibold">Same as shipping address</p>
          <p className="text-sm text-gray-500">
            Invoice & order updates will be shared at this address & mobile
          </p>
        </div>
        {/* Radio Button */}
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g opacity="0.5">
        <circle cx="10" cy="10" r="9" stroke="black" stroke-width="2"/>
        <circle cx="10" cy="10" r="5" fill="black"/>
        </g>
        </svg>
        </div>

      {/* Add a new address option */}
      <div
        className="bg-[#F6F6F6] border-[#0000001A] boarder-1 flex justify-between items-center p-4 border rounded-lg cursor-pointer"
        onClick={() => { setIsSameAsShipping(false); navigate({pathname: `/addAddress`})}}
      >
        <p className="font-semibold">Add a new address</p>
        {/* Plus Icon */}
        <div className="w-6 h-6 flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g opacity="0.5">
        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill="#292D32"/>
        </g>
        </svg>
        </div>
      </div>
      </div>
      {/* Continue Button */}
      <div className="mt-6">
        <button className="checkout-btn text-white py-3 px-6 w-full" onClick={() => setConfirmationModel(true)}>
          CONTINUE
        </button>
      </div>
      </>}
      </div>
    </div>
    }
    {!isMobile && 
    <div className="max-w-full mx-auto grid grid-cols-2">
    <div>
     {/* Shipping / Payment / Login*/}
     <div className="flex space-x-4  justify-center mb-5 bg-[#F3F3F3] h-[70px] items-center">
      {/* Step 1: Login */}
      <div className="flex space-x-2 cursor-pointer" onClick={() => setTab(TABS.LOGIN)}>
        <div className="w-7 h-7 rounded-full bg-black text-white flex items-center justify-center font-semibold">
          {tab == TABS.LOGIN ? 1 : <FontAwesomeIcon icon={faCheck}/>}
        </div>
        <p className="font-medium">Login</p>
      </div>
      {/* Step 2: Shipping */}
      <div className="flex space-x-2 cursor-pointer" onClick={() => setTab(TABS.SHIPPING)}>
        <div className={`w-7 h-7 rounded-full ${(tab == TABS.LOGIN || tab == TABS.SHIPPING) ? 'bg-[#4F4F4F]' : 'bg-black'} text-white flex items-center justify-center font-semibold`}>
        {(tab == TABS.LOGIN || tab == TABS.SHIPPING) ? 2 : <FontAwesomeIcon icon={faCheck}/>}
        </div>
        <p className="font-medium">Shipping & Payment</p>
      </div>

      {/* Step 3: Payment */}
      {/* <div className="flex space-x-2 cursor-pointer" onClick={() => setTab(TABS.PAYMENT)}>
        <div className="w-7 h-7 rounded-full bg-[#4F4F4F] text-white flex items-center justify-center font-semibold">
        {(tab == TABS.LOGIN || tab == TABS.SHIPPING || tab == TABS.PAYMENT) ? 3 : <FontAwesomeIcon icon={faCheck}/>}
        </div>
        <p className="font-medium text-gray-500">Payment</p>
      </div> */}
    </div>
    {tab == TABS.LOGIN && <LoginPage></LoginPage>}
    {tab == TABS.SHIPPING && <Shipping></Shipping>}
    {tab == TABS.PAYMENT && <div className="text-center text-2xl">In progress</div>}
    </div>
    <div className="bg-[#EBEBEB]">
    <div className="m-[7%]">
    <p className={`font-semibold ml-[3%] mb-2`}>Order Summary</p>
    <OrderSummaryProducts isCart={false} products={products}></OrderSummaryProducts>
    </div>
    </div>
    </div>
    }

    {confirmationModel &&<ConfirmationModal 
        address="154/S, Bipin Behari Ganguly Street, Kolkata, West Bengal - 700012, India."
        phoneNumber="+91 9988441245"
        onEdit={handleEdit}
        onConfirm={handleConfirm}
        close={() => setConfirmationModel(false)}  
    ></ConfirmationModal>}
    <CheckOutFooter></CheckOutFooter>
     </>
  );
};

export default OrderSummary;
