import * as React from 'react';
const Visa = props => (
  <svg
    width={47}
    height={50}
    viewBox='0 0 28 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect
      x={0.2}
      y={0.2}
      width={26.5423}
      height={17.7522}
      rx={5.5}
      fill='white'
      stroke='#D9D9D9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.36124 12.7025H6.69271L5.44152 7.96295C5.38213 7.74493 5.25604 7.55219 5.07055 7.46135C4.60766 7.23306 4.09758 7.05138 3.54114 6.95975V6.77728H6.22901C6.59997 6.77728 6.87819 7.05138 6.92456 7.36972L7.57375 10.7885L9.24147 6.77728H10.8636L8.36124 12.7025ZM11.791 12.7025H10.2152L11.5128 6.77728H13.0886L11.791 12.7025ZM15.1273 8.41873C15.1736 8.09961 15.4519 7.91713 15.7765 7.91713C16.2865 7.87132 16.8422 7.96295 17.3059 8.19045L17.5841 6.91473C17.1204 6.73226 16.6103 6.64062 16.1474 6.64062C14.618 6.64062 13.5051 7.46135 13.5051 8.60041C13.5051 9.46696 14.2934 9.92195 14.8499 10.1961C15.4519 10.4694 15.6837 10.6518 15.6374 10.9251C15.6374 11.3351 15.1736 11.5176 14.7108 11.5176C14.1543 11.5176 13.5979 11.3809 13.0886 11.1526L12.8104 12.4292C13.3668 12.6567 13.9688 12.7483 14.5253 12.7483C16.2402 12.7933 17.3059 11.9734 17.3059 10.7427C17.3059 9.19285 15.1273 9.10201 15.1273 8.41873ZM22.8207 12.7025L21.5695 6.77728H20.2256C19.9474 6.77728 19.6692 6.95975 19.5764 7.23306L17.2595 12.7025H18.8817L19.2055 11.8367H21.1986L21.3841 12.7025H22.8207ZM20.4575 8.37292L20.9203 10.606H19.6228L20.4575 8.37292Z'
      fill='#172B85'
    />
  </svg>
);
export default Visa;
