import React, { useState } from 'react';
import { useGetBackgroundColor } from "../../../../src/api/index";

const DesktopFilter = ({ metalTypeData, metalCaratData, ocassianData, genderData, metalColorData,gemstoneData, onFilterChange }) => {
  // State for checked filters
  const [checkedFilters, setCheckedFilters] = useState([]);
  const { data } = useGetBackgroundColor({ onLoad: true });

  // Handle checkbox change
  const handleCheckboxChange = (e, filterType, filterValue) => {
    const isChecked = e.target.checked;

    let updatedFilters;
    if (isChecked) {
      // Add to checked filters
      updatedFilters = [...checkedFilters, { type: filterType, value: filterValue }];
    } else {
      // Remove from checked filters
      updatedFilters = checkedFilters.filter(item => item.value !== filterValue);
    }

    setCheckedFilters(updatedFilters);
    // Call parent function with the updated checked filters
    onFilterChange(updatedFilters);
  };

  const handleClearAll = () => {
    setCheckedFilters([]);
    // Notify parent about the cleared filters
    onFilterChange([]);
  };

  const isChecked = (filterType, filterValue) => {
    return checkedFilters.some(item => item.type === filterType && item.value === filterValue);
  };

  return (
    <aside className='w-[280px] mb-5 px-5'>
      <div className="overflow-hidden rounded-[8px] font-['Inter'] sm:block sm:w-[19%]">
        <div className='main-heading flex items-center justify-between gap-[8px] px-[14px] py-[10px] text-white' style={{backgroundColor : data.color.shade1}}>
          <div className='flex'>
            <p className='mr-3 text-[15px] font-bold text-white'>FILTERS</p>
            <div className='flex h-[18px] items-center justify-center rounded-[4px] px-1 text-[12px] font-extrabold' style={{backgroundColor : data.color.shade2}}>
              {checkedFilters.length}
            </div>
          </div>
          <div className='text-white cursor-pointer' onClick={handleClearAll}>CLEAR ALL</div>
        </div>

        {/* Metal Type Section */}
        {metalTypeData && metalTypeData.length > 0 && (
          <div className='filter-category border-b border-gray-300 bg-[#EEEEEE]'>
            <div className='filter-heading px-[15px] py-[13px]'>
              <p className='text-[15px] font-bold'>Metal Type</p>
            </div>
            {metalTypeData.map((metal) => (
              <div className='flex gap-[10px] px-[14px] py-[6px]' key={metal.name}>
                <input
                  type='checkbox'
                  checked={isChecked('metalType', metal.name)} // Bind checkbox to state
                  onChange={(e) => handleCheckboxChange(e, 'metalType', metal.name)}
                  className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                />
                <div className='flex items-center gap-1'>
                  <p className='text-[12px] font-bold'>{metal.name}</p>
                  <div className='item-count text-[12px] font-bold'>({metal.count})</div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Metal Carat Section */}
        {metalCaratData && metalCaratData.length > 0 && (
          <div className='filter-category border-b border-gray-300 bg-[#EEEEEE]'>
            <div className='filter-heading px-[15px] py-[13px]'>
              <p className='text-[15px] font-bold'>Metal Carat</p>
            </div>
            {metalCaratData.map((carat) => (
              <div className='flex gap-[10px] px-[14px] py-[6px]' key={carat.name}>
                <input
                  type='checkbox'
                  checked={isChecked('metalCarat', carat.name)} // Bind checkbox to state
                  onChange={(e) => handleCheckboxChange(e, 'metalCarat', carat.name)}
                  className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                />
                <div className='flex items-center gap-1'>
                  <p className='text-[12px] font-bold'>{carat.name}</p>
                  <div className='item-count text-[12px] font-bold'>({carat.count})</div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Ocassian Section */}
        {ocassianData && ocassianData.length > 0 && (
          <div className='filter-category border-b border-gray-300 bg-[#EEEEEE]'>
            <div className='filter-heading px-[15px] py-[13px]'>
              <p className='text-[15px] font-bold'>Ocassian</p>
            </div>
            {ocassianData.map((occasion) => (
              <div className='flex gap-[10px] px-[14px] py-[6px]' key={occasion.name}>
                <input
                  type='checkbox'
                  checked={isChecked('ocassian', occasion.name)} // Bind checkbox to state
                  onChange={(e) => handleCheckboxChange(e, 'ocassian', occasion.name)}
                  className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                />
                <div className='flex items-center gap-1'>
                  <p className='text-[12px] font-bold'>{occasion.name}</p>
                  <div className='item-count text-[12px] font-bold'>({occasion.count})</div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Gender Section */}
        {genderData && genderData.length > 0 && (
          <div className='filter-category border-b border-gray-300 bg-[#EEEEEE]'>
            <div className='filter-heading px-[15px] py-[13px]'>
              <p className='text-[15px] font-bold'>Gender</p>
            </div>
            {genderData.map((gender) => (
              <div className='flex gap-[10px] px-[14px] py-[6px]' key={gender.name}>
                <input
                  type='checkbox'
                  checked={isChecked('gender', gender.name)} // Bind checkbox to state
                  onChange={(e) => handleCheckboxChange(e, 'gender', gender.name)}
                  className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                />
                <div className='flex items-center gap-1'>
                  <p className='text-[12px] font-bold'>{gender.name}</p>
                  <div className='item-count text-[12px] font-bold'>({gender.count})</div>
                </div>
              </div>
            ))}
          </div>
        )}


        {/* Metal Color Section */}
        {metalColorData && metalColorData.length > 0 && (
          <div className='filter-category border-b border-gray-300 bg-[#EEEEEE]'>
            <div className='filter-heading px-[15px] py-[13px]'>
              <p className='text-[15px] font-bold'>Metal Color</p>
            </div>
            {metalColorData.map((metalColors) => (
              <div className='flex gap-[10px] px-[14px] py-[6px]' key={metalColors.name}>
                <input
                  type='checkbox'
                  checked={isChecked('metalColor', metalColors.name)} // Bind checkbox to state
                  onChange={(e) => handleCheckboxChange(e, 'metalColor', metalColors.name)}
                  className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                />
                <div className='flex items-center gap-1'>
                  <p className='text-[12px] font-bold'>{metalColors.name}</p>
                  <div className='item-count text-[12px] font-bold'>({metalColors.count})</div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Gemstone Section */}
        {gemstoneData && gemstoneData.length > 0 && (
          <div className='filter-category border-b border-gray-300 bg-[#EEEEEE]'>
            <div className='filter-heading px-[15px] py-[13px]'>
              <p className='text-[15px] font-bold'>Gemstone</p>
            </div>
            {gemstoneData.map((gemstone) => (
              <div className='flex gap-[10px] px-[14px] py-[6px]' key={gemstone.name}>
                <input
                  type='checkbox'
                  checked={isChecked('gemstone', gemstone.name)} // Bind checkbox to state
                  onChange={(e) => handleCheckboxChange(e, 'gemstone', gemstone.name)}
                  className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                />
                <div className='flex items-center gap-1'>
                  <p className='text-[12px] font-bold'>{gemstone.name}</p>
                  <div className='item-count text-[12px] font-bold'>({gemstone.count})</div>
                </div>
              </div>
            ))}
          </div>
        )}

      </div>
    </aside>
  );
};

export default DesktopFilter;
