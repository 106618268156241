import React from 'react';
import { useNavigate  } from 'react-router-dom';
import { useResponsive } from 'hooks';
import './checkout.css'
import OrderSummaryProducts from './orderSummaryProducts';
import { useGetBackgroundColor } from "../../../src/api/index";
import { useSelector } from 'react-redux';

export default function ShoppingCart({checkoutLine}) {
  const storeData = useSelector(state => state.storeData);
  function getFormattedDate(daysToAdd) {
      const date = new Date();
      date.setDate(date.getDate() + daysToAdd);

      const day = date.getDate();
      const suffix = getDaySuffix(day);
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear();

      return `${day}${suffix} ${month} ${year}`;
  }

  function getDaySuffix(day) {
      if (day > 3 && day < 21) return 'th'; // 11th, 12th, 13th all get 'th'
      switch (day % 10) {
          case 1:  return 'st';
          case 2:  return 'nd';
          case 3:  return 'rd';
          default: return 'th';
      }
  }

  const navigate = useNavigate();
  const { isDesktop, isMobile } = useResponsive();
  const { data } = useGetBackgroundColor({ onLoad: true });
  // Dummy data for products
  console.log("checkoutLine?.lines",checkoutLine?.lines)
  const products = checkoutLine?.lines?.map((line,index)=>{
    if(line){
      let size;
      if(line?.variant?.attributes){
        size = line?.variant?.attributes?.map((att)=>{
          if(att?.attribute?.name==="Size"){
            return att?.values[0]?.name;
          }
          return null;
        }).filter((attf)=>attf);    
      }else{
        size = ['Made to Order']
      }
      console.log("sizesizesizesize",size) 
      if(size?.length){
        if(line?.variant){
          return {
            id: line?.id,
            name: line?.variant?.product?.name,
            
            price: (checkoutLine?.metadata?.length > index ? parseInt(JSON.parse(checkoutLine?.metadata[index]?.value?.replaceAll('" -',"# -").replaceAll("'",'"').replaceAll("# -","' -"))['Total Price'] || 0) : 0)*parseInt(line?.quantity),
            size: size[0],
            quantity: line?.quantity,
            estDelivery: getFormattedDate(2),
            imgSrc: line?.variant?.product?.images[0]?.url,
          }
        }
        if(line?.dbpVariantData){
          let productImg = line?.dbpVariantData?.images[0]
          return {
            id: line?.id,
            name: line?.dbpVariantData?.product?.name,
            price: (checkoutLine?.metadata?.length > index ? parseInt(JSON.parse(checkoutLine?.metadata[index]?.value?.replace(/'/g, '"'))['Total Price'] || 0) : 0)*parseInt(line?.quantity),
            size: size[0],
            quantity: line?.quantity,
            estDelivery: getFormattedDate(line?.dbpVariantData?.product?.makingDays || 2),
            imgSrc: productImg,
          }
        }
      }          
    }
    return null
  }).filter((data)=>data);  

  const subtotal = products?.reduce(
    (total, product) => total + product.price,
    0
  );
  console.log("products",products)
  return (
    <div className='flex h-100 flex-col'>
      {/* Header */}
      {isMobile && <div className='flex items-center justify-between checkout-header p-4 shadow'>
        <button onClick={() => navigate({pathname: `/`})}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='27'
            height='27'
            viewBox='0 0 27 27'
            fill='none'
          >
            <path
              d='M25.3125 13.5L1.6875 13.5M1.6875 13.5L10.3661 23.625M1.6875 13.5L10.3661 3.375'
              stroke='black'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
        <h2 className='text-lg font-semibold'>
          Shopping Cart ({products.length})
        </h2>
        <button onClick={()=>window.open(`https://wa.me/${storeData?.storePhoneNumber}`, '_blank')}>
          {/* WhatsApp icon */}
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              d='M0 24L1.687 17.837C0.645998 16.033 0.0989998 13.988 0.0999998 11.891C0.103 5.335 5.43799 0 11.993 0C15.174 0.001 18.16 1.24 20.406 3.488C22.6509 5.736 23.8869 8.724 23.8859 11.902C23.8829 18.459 18.548 23.794 11.993 23.794C10.003 23.793 8.04198 23.294 6.30499 22.346L0 24ZM6.59698 20.193C8.27298 21.188 9.87298 21.784 11.989 21.785C17.437 21.785 21.875 17.351 21.878 11.9C21.88 6.438 17.463 2.01 11.997 2.008C6.54498 2.008 2.11 6.442 2.108 11.892C2.107 14.117 2.75899 15.783 3.85399 17.526L2.85499 21.174L6.59698 20.193ZM17.984 14.729C17.91 14.605 17.712 14.531 17.414 14.382C17.117 14.233 15.656 13.514 15.383 13.415C15.111 13.316 14.913 13.266 14.714 13.564C14.516 13.861 13.946 14.531 13.773 14.729C13.6 14.927 13.426 14.952 13.129 14.803C12.832 14.654 11.874 14.341 10.739 13.328C9.85598 12.54 9.25898 11.567 9.08598 11.269C8.91298 10.972 9.06798 10.811 9.21598 10.663C9.34998 10.53 9.51298 10.316 9.66198 10.142C9.81298 9.97 9.86198 9.846 9.96198 9.647C10.061 9.449 10.012 9.275 9.93698 9.126C9.86198 8.978 9.26798 7.515 9.02098 6.92C8.77898 6.341 8.53398 6.419 8.35198 6.41L7.78198 6.4C7.58398 6.4 7.26198 6.474 6.98998 6.772C6.71798 7.07 5.94999 7.788 5.94999 9.251C5.94999 10.714 7.01498 12.127 7.16298 12.325C7.31198 12.523 9.25798 15.525 12.239 16.812C12.948 17.118 13.502 17.301 13.933 17.438C14.645 17.664 15.293 17.632 15.805 17.556C16.376 17.471 17.563 16.837 17.811 16.143C18.059 15.448 18.059 14.853 17.984 14.729Z'
              fill='#111111'
            />
          </svg>
        </button>
      </div>}
      <div className={`grid ${!isMobile ? 'grid-cols-2 mx-[7%] relative' : ''}`}>
      {/* Products List */}
      <div className='flex-1 p-4'>
      {!isMobile && <p className={`font-semibold ml-1 mb-2 ${!isMobile ? 'text-center mb-3' : ''}`}>Review Cart</p>}
        <OrderSummaryProducts  products={products} isCart={true}></OrderSummaryProducts>
      </div>
      {!isMobile && <div className='divider-order-summary'></div>}
      {/* Order Summary */}
      <div className='p-4'>
      <p className={`font-semibold ml-1 mb-2 ${!isMobile ? 'text-center mb-3' : ''}`}>Order Summary</p>
      <div className='order-summary shadow p-3'>
        <div className='flex justify-between order-price mb-2'>
          <p>Subtotal</p>
          <p>₹{subtotal?.toLocaleString()}</p>
        </div>
        <div className='flex justify-between order-price mb-2'>
          <p>Shipping Charge</p>
          <p>Free</p>
        </div>
        <div className='flex justify-between order-price'>
          <p>Shipping Insurance</p>
          <p>Free</p>
        </div>
        </div>
        
        <div className='flex justify-between grand-total mt-1 p-3'>
          <p>Grand Total</p>
          <p>₹{subtotal?.toLocaleString()}</p>
        </div>
        {!isMobile && 
        <div className='flex justify-center mt-[5%] text-white'>
        <button className='rounded-lg py-2 font-bold text-white checkout-btn w-80 p-2' style={{backgroundColor : data.color.primaryColor}} onClick={() => navigate({pathname: `/orderSummary`})}>
          PLACE ORDER
        </button>
        </div>}
      </div>
      </div>
     
      {/* Footer */}
      {isMobile && <div className='flex items-center justify-between view-order-summary p-4' style={{backgroundColor : data.color.shade1}}>
        <div>
        <div className='price'>
          ₹{subtotal.toLocaleString()}{' '}
        </div>
        <p className='text-sm text-black font-bold sm:text-xs'>View Order Summary</p>
        </div>
        <button className='place-order px-4 py-2 text-white' style={{backgroundColor : data.color.primaryColor}} onClick={() => navigate({pathname: `/orderSummary`})}>
          PLACE ORDER
        </button>
      </div>}
    </div>
  );
}
