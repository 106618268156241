import * as React from 'react';

const User = props => (
  <svg
    width={14}
    height={16}
    viewBox='0 0 14 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1 15V13C1 11.3431 2.34315 10 4 10H10C11.6569 10 13 11.3431 13 13V15M7 1C5.47192 1 4 2.19032 4 4C4 5.52808 5.19032 7 7 7C8.80968 7 10 5.52808 10 4C10 2.19032 8.52808 1 7 1Z'
      stroke='black'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default User;
