import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { useGetBackgroundColor } from "../../../src/api/index";
import logo from "assets/img/company_logo.png"; 
import { useSelector } from 'react-redux';
import useResponsive from 'hooks/useResponsive';

// Keyframes for horizontal flip
const flipHorizontal = keyframes`
  0% {
    transform: scaleX(1); /* Normal state */
  }
  50% {
    transform: scaleX(-1); /* Flipped horizontally */
  }
  100% {
    transform: scaleX(1); /* Back to normal */
  }
`;

// Keyframes for vertical flip
const flipVertical = keyframes`
  0% {
    transform: scaleY(1); /* Normal state */
  }
  50% {
    transform: scaleY(-1); /* Flipped vertically */
  }
  100% {
    transform: scaleY(1); /* Back to normal */
  }
`;

const Text = styled.h2`
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }

  @media (max-width: 480px) {
    font-size: 1em;
  }
`;

// Dynamic styling for the logo based on flip direction
const Logo = styled.img`
  width: 135px;
  height: 135px;
  ${'' /* animation: ${(props) =>
    props.direction === "horizontal"
      ? css`${flipHorizontal} 2s ease-in-out forwards`
      : css`${flipVertical} 2s ease-in-out forwards`}; */}

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 420px) {
    width: 80px;
    height: 80px;
  }
`;

const Loader = () => {
  const [flipDirection, setFlipDirection] = useState("horizontal");
  const storeData = useSelector(state => state.storeData);
  const [activeIndex, setActiveIndex] = useState(0);
  const { data:themeColor } = useGetBackgroundColor({ onLoad: true });
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 4); // Cycle through 0-3
    }, 150); // Delay of 500ms between each change

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setFlipDirection((prevDirection) =>
        prevDirection === "horizontal" ? "vertical" : "horizontal"
      );
    }, 5000); // Flip every 5 seconds

    return () => clearInterval(interval); // Cleanup the interval
  }, []);

  const { isMobile} = useResponsive();
  // Container for the loader
  let LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  text-align: center;
  background-color: #f0f0f0;
  `;
  if(isMobile){
  // Container for the loader
  LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 55px);
  text-align: center;
  background-color: #f0f0f0;
  `;
  }
  return (
    <LoaderWrapper>
      {/* <Text>Powered by</Text> */}
      <Logo src={storeData?.storeLogo ? storeData?.storeLogo : logo} alt="Logo" direction={flipDirection} className="h-[150px] w-[150px] "/>
      <div className="flex space-x-2 items-center mt-3">
      {Array(4)
        .fill(null)
        .map((_, index) => (
          <span
            key={index}
            className={`h-4 w-4 rounded-full sm:h-3 sm:w-3`}
            style={{backgroundColor : index === activeIndex? themeColor.color.primaryColor : themeColor.color.shade2}}
          ></span>
        ))}
    </div>
    </LoaderWrapper>
  );
};

export default Loader;
