import React, { useState } from 'react';
import {useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useGetBackgroundColor } from "../../../../src/api/index";
const PriceFilter = [
  { label: '₹10001 - ₹15000', badge: null, selected : false },
  { label: '₹15001 - ₹20000', badge: null, selected : false },
  { label: '₹20001 - ₹30000', badge: null, selected : false },
  { label: '₹30001 - ₹40000', badge: null, selected : false },
  { label: '₹40001 - ₹50000', badge: null, selected : false },
  { label: '₹50001 - ₹75000', badge: null, selected : false },
  { label: '₹75001 - ₹100000', badge: null, selected : false },
  { label: '₹100001 - ₹150000', badge: null, selected : false },
  { label: '₹150001 - ₹200000', badge: null, selected : false },
]
const filters = [
  // { label: 'Price', badge: null },
  // { label: 'Size', badge: null },
  // { label: 'Product Type', badge: null },
  // { label: 'Weight Ranges', badge: 1 },
  // { label: 'Metal', badge: null },
  // { label: 'Color of Metal', badge: null },
  // { label: 'Shop For', badge: null },
  // { label: 'Occasion', badge: null },
  // { label: 'Earring Style', badge: null },
  // { label: 'Gemstone', badge: null },
  // { label: 'Ring Style', badge: null },
  // { label: 'Material', badge: null },
  { label: 'Metal Type', badge: null },
  { label: 'Metal Carat', badge: null },
  { label: 'Ocassian', badge: null },
  { label: 'Gender', badge: null },
  { label: 'Metal Color', badge: null },
  { label: 'Gemstone', badge: null },
];
const WeightRange = [
  { label: '0-2g', badge: null, selected : false },
  { label: '2-5g', badge: null, selected : false },
  { label: '5-10g', badge: null, selected : false },
  { label: '10-20g', badge: null, selected : false },
  { label: '20-30g', badge: null, selected : false },
  { label: '30-50g', badge: null, selected : false },
  { label: '50-75g', badge: null, selected : false },
  { label: '75-100g', badge: null, selected : false },
  { label: '100g+', badge: null, selected : false },
]
const PriceMobileFilter = ({closeModel,metalTypeData, metalCaratData, ocassianData, genderData, metalColorData,gemstoneData, onFilterChange, filteredData}) => {
    const menuData = useSelector((state) => state.menu?.menuData);
    const productType = menuData?.data?.map((item) => ({
      id: item.id,
      title: item.name,
      description: item?.description, 
      submenu: [
        {
          title: 'SHOP BY STYLE',
          items: item.sub.map((subItem) => ({
            id: subItem?.id,
            img: subItem?.img,
            name: subItem?.name,
            description:subItem?.description
          })),
        },
      ],
      selected : false
    }));
    const [tab, setTab] = useState(filters[0].label)
    const [checkedFilters, setCheckedFilters] = useState(filteredData);
    const [productFilters, setProductFilters] = useState(filters);
    const { data } = useGetBackgroundColor({ onLoad: true });
    useEffect(() => {
    // badge count add
      setProductFilters(productFilters.map((item) => {
        const badge = checkedFilters.filter((element) => element.label === item.label)
        // const badge = item.badge + 1
        item.badge = badge.length > 0 ? badge.length : null
        return item;
      }))
    },[checkedFilters])
     // Handle checkbox change
    const handleCheckboxChange = (e, filterType, filterValue, tab) => {
    const isChecked = e.target.checked;

    let updatedFilters;
    if (isChecked) {
      // Add to checked filters
      updatedFilters = [...checkedFilters, { type: filterType, value: filterValue, label : tab }];
    } else {
      // Remove from checked filters
      updatedFilters = checkedFilters.filter(item => item.value !== filterValue);
    }

    setCheckedFilters(updatedFilters);
    };
    const applyFilter = () => {
      // Call parent function with the updated checked filters
      onFilterChange(checkedFilters);
      closeModel(false)
    }
    const handleClearAll = () => {
      setCheckedFilters([]);
      // Notify parent about the cleared filters
      onFilterChange([]);
      closeModel(false)
    };
    const isChecked = (filterType, filterValue) => {
      return checkedFilters.some(item => item.type === filterType && item.value === filterValue);
    };
  return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end z-50 flex flex-col">
          <div className="h-full w-full shadow-lg overflow-y-auto relative">
          <div className='flex gap-4 h-12 items-center p-2' style={{background: 'linear-gradient(180deg, #FFFFFF 0%, #DCDCDC 100%)'}}>
          <button
            className="text-black text-xl focus:outline-none"
            aria-label="Close"
            onClick={() => closeModel(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M22.75 22.75L14 14M14 14L5.25 5.25M14 14L22.75 5.25M14 14L5.25 22.75" stroke="black" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </button>
          <h2 className="text-lg font-bold">FILTER</h2>
          </div>
            <div className="flex bg-white text-[14px] h-full">
              <div className="w-1/2 border-r bg-[#f1f1f1]">
              <ul className="space-y-2">
              {productFilters.map((filter, index) => (
              ((filter.label === 'Metal Type' && metalTypeData && metalTypeData.length > 0) || (filter.label === 'Metal Carat' && metalCaratData && metalCaratData.length > 0) || (filter.label === 'Ocassian' && ocassianData && ocassianData.length > 0) ||(filter.label === 'Gender' && genderData && genderData.length > 0) || (filter.label === 'Metal Color' && metalColorData && metalColorData.length > 0) || (filter.label === 'Gemstone' && gemstoneData && gemstoneData.length > 0) ) && (
                <li
                  key={index}
                  className={`flex justify-between items-center py-2 px-3 ${filter.label === tab ? 'text-[#1D2293]' : ''}`}
                  style={{ borderBottom: '1px solid #0000000D' }}
                  onClick={() => setTab(filter.label)}
                >
                  <span className="font-medium">{filter.label}</span>
                  {filter.badge && (
                    <span className="flex h-[18px] items-center justify-center rounded-[4px] px-1 text-[12px] font-bold text-white" style={{backgroundColor : data.color.ctaPrimary}}>
                      {filter.badge}
                    </span>
                  )}
                </li>
              )
              ))}
                </ul>
              </div>
              <div className="w-2/3 px-2">
                <ul className="space-y-2">
                {PriceFilter && PriceFilter.length > 0 && 'Price' === tab && (
                PriceFilter.map((price, index) => (
                  <li key={index} className="flex items-center py-2 px-1 gap-2">
                    <input
                      type='checkbox'
                      checked={price.selected} // Bind checkbox to state
                      className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                      onChange={() => {/* handle checkbox change if needed */}}
                      style={{backgroundColor : data.color.ctaPrimary}}
                    />
                    {price.label}
                  </li>
                ))
                )}
                {productType && productType.length > 0 && 'Product Type' === tab && (
                  productType.map((product, index) => (
                  <li key={index} className="flex items-center py-2 px-1 gap-2">
                    <input
                      type='checkbox'
                      checked={product.selected} // Bind checkbox to state
                      className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                      onChange={() => {/* handle checkbox change if needed */}}
                    />
                    {product.title}
                  </li>
                ))
                )}
                {WeightRange && WeightRange.length > 0 && 'Weight Ranges' === tab && (
                WeightRange.map((price, index) => (
                  <li key={index} className="flex items-center py-2 px-1 gap-2">
                    <input
                      type='checkbox'
                      checked={price.selected} // Bind checkbox to state
                      className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                      onChange={() => {/* handle checkbox change if needed */}}
                    />
                    {price.label}
                  </li>
                ))
                )}
                {metalTypeData && metalTypeData.length > 0 && 'Metal Type' === tab && (
                metalTypeData.map((metal, index) => (
                  <li key={index} className="flex items-center py-2 px-1 gap-2">
                    <input
                      type='checkbox'
                      checked={isChecked('metalType', metal.name)} // Bind checkbox to state
                      onChange={(e) => handleCheckboxChange(e, 'metalType', metal.name, tab)}
                      className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                      style={{backgroundColor : data.color.ctaPrimary}}
                    />
                    {metal.name}({metal.count})
                  </li>
                ))
                )}
                {metalCaratData && metalCaratData.length > 0 && 'Metal Carat' === tab && (
                metalCaratData.map((carat, index) => (
                  <li key={index} className="flex items-center py-2 px-1 gap-2">
                    <input
                      type='checkbox'
                      checked={isChecked('metalCarat', carat.name)} // Bind checkbox to state
                      onChange={(e) => handleCheckboxChange(e, 'metalCarat', carat.name, tab)}
                      className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                    />
                    {carat.name}({carat.count})
                  </li>
                ))
                )}
                {ocassianData && ocassianData.length > 0 && 'Ocassian' === tab && (
                ocassianData.map((occasion, index) => (
                  <li key={index} className="flex items-center py-2 px-1 gap-2">
                    <input
                      type='checkbox'
                      checked={isChecked('ocassian', occasion.name)} // Bind checkbox to state
                      onChange={(e) => handleCheckboxChange(e, 'ocassian', occasion.name, tab)}
                      className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                    />
                    {occasion.name}({occasion.count})
                  </li>
                ))
                )}
                {genderData && genderData.length > 0 && 'Gender' === tab && (
                genderData.map((gender, index) => (
                  <li key={index} className="flex items-center py-2 px-1 gap-2">
                    <input
                      type='checkbox'
                      checked={isChecked('gender', gender.name)} // Bind checkbox to state
                      onChange={(e) => handleCheckboxChange(e, 'gender', gender.name, tab)}
                      className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                    />
                    {gender.name}({gender.count})
                  </li>
                ))
                )}
                {metalColorData && metalColorData.length > 0 && 'Metal Color' === tab && (
                metalColorData.map((metalColors, index) => (
                  <li key={index} className="flex items-center py-2 px-1 gap-2">
                    <input
                      type='checkbox'
                      checked={isChecked('metalColor', metalColors.name)} // Bind checkbox to state
                      onChange={(e) => handleCheckboxChange(e, 'metalColor', metalColors.name, tab)}
                      className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                    />
                    {metalColors.name}({metalColors.count})
                  </li>
                ))
                )}
                {gemstoneData && gemstoneData.length > 0 && 'Gemstone' === tab && (
                gemstoneData.map((gemstone, index) => (
                  <li key={index} className="flex items-center py-2 px-1 gap-2">
                    <input
                      type='checkbox'
                      checked={isChecked('gemstone', gemstone.name)} // Bind checkbox to state
                      onChange={(e) => handleCheckboxChange(e, 'gemstone', gemstone.name, tab)}
                      className='h-[20px] w-[20px] rounded-sm border-2 border-gray-100'
                    />
                    {gemstone.name}({gemstone.count})
                  </li>
                ))
                )}
                </ul>
              </div>
            </div>
          </div>
          <div className="flex justify-between p-3 bg-white gap-3" style={{boxShadow: '0px -2px 2px 0px #00000040'}}>
                <button className="text-white font-bold py-2 px-4 rounded-md hover:bg-blue-300 w-full" onClick={handleClearAll} style={{backgroundColor : data.color.secondaryColor}}>
                Clear All
                </button>
                <button className="text-white font-bold py-2 px-4 rounded-md hover:bg-blue-700 w-full" onClick={applyFilter} style={{backgroundColor : data.color.ctaPrimary}}>
                Apply Filter
                </button>
            </div>
        </div>
  );
};

export default PriceMobileFilter;
