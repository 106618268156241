
import { useLazyQuery } from "hooks";
import { GET_BANNERS } from "graphql/query";
 

export const useBanner = (onLoad) => {
    const { data, loading, error, executeQuery, refetch, } = useLazyQuery(GET_BANNERS, { onLoad } );
      
    return {
        executeQuery,
        data,
        loading,
        error,
        refetch,
    };
};
