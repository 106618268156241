import React, { useState } from 'react';
import './ProfileEdit.css';
import MyAccountAside from 'components/account/MyAccountAside';
import {useUpdateUserProfile} from '../../../src/api/userDetails';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetBackgroundColor } from "../../../src/api/index";
import { useNavigate   } from 'react-router-dom';

const ProfileEditMobile = () => {
  const navigate = useNavigate();
  const refUserDetails = JSON.parse(localStorage.getItem(`vjw-${window.location.hostname}user-det`));
  // Define state for the form fields
  const [firstName, setFirstName] = useState(refUserDetails?.firstName || '');
  const [lastName, setLastName] = useState(refUserDetails?.lastName || '');
  const [mobile, setMobile] = useState(refUserDetails?.phoneNumber?.replace("+91","") || '');
  const [birthDate, setBirthDate] = useState(refUserDetails?.birthDate?.split("T")[0] || '');
  const [anniversaryDate, setAnniversaryDate] = useState(refUserDetails?.anniversaryDate?.split("T")[0] || '');
  const [gender, setGender] = useState(refUserDetails?.gender || '');
  const [pincode, setPincode] = useState('');
  const [occupation, setOccupation] = useState('');
  const [email, setEmail] = useState(refUserDetails?.email || '');
  const { data } = useGetBackgroundColor({ onLoad: true });
  const updateUserProfile = useUpdateUserProfile();
  console.log("refUserDetails",refUserDetails);
  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const data = await updateUserProfile(
        refUserDetails?.id, 
        firstName, 
        lastName, 
        refUserDetails?.phoneNumber, 
        `${birthDate}T00:00:00`, 
        `${anniversaryDate}T00:00:00`, 
        email, 
        gender
      );
      toast.success('Profile updated successfully!');
      console.log("Profile updated data", data);
    } catch (error) {
      toast.error('Failed to update profile. Please try again.');
      console.error('Profile update error', error);
    }
  };
  return (
    <div className="">
    <div className='flex-col items-center'>
    <div style={{border: '1px solid #00000033'}} className='text-[#2B2B2B] font-bold text-[18px] p-4 flex gap-3 items-center'>
    <div onClick={() =>  navigate('/account')}>
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="21" viewBox="0 0 27 23" fill="none">
    <path d="M25.3125 11.5L1.6875 11.5M1.6875 11.5L10.3661 21.625M1.6875 11.5L10.3661 1.375" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </div>
    Personal Details
    </div>
    <div className="max-w-5xl mx-auto px-3 pb-3">
      <form onSubmit={handleSave}  style={{borderLeft: '1px solid #00000033', borderRight: '1px solid #00000033', borderBottom: '1px solid #00000033' }} className='p-3 rounded-b-[15px]'>
        {/* Name Section */}
        <div className="grid grid-cols-1 gap-4 mb-6 mt-2">
          <div>
            <label className="block text-[14px] font-bold text-black mb-2">Let's start with your name*</label>
              <input
                type="text"
                placeholder="First Name"
                className="mb-2 w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Last Name"
                className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
          </div>
          <div>
            <label className="block text-[14px] font-bold text-black mb-2">Enter your mobile number*</label>
            <div className="flex">
              <select className="border border-gray-300 rounded-l-lg p-2" disabled>
                <option value="91">+91</option>
              </select>
              <input
                type="text"
                disabled={true}
                placeholder="Mobile Number"
                className="w-full border border-gray-300 rounded-r-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                required
              />
            </div>
          </div>
                  {/* Birthdate and Anniversary Date Section */}
        <div className="grid grid-cols-1 gap-4">
          <div>
            <label className="block text-[14px] font-bold text-black">Tell us your birth date*</label>
            <span className='text-[12px] font-semibold'>You don’t want to miss out on special discounts</span>
            <input
              type="date"
              className="w-full border border-gray-300 mt-2 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
              required
            />
          </div>
        </div>
          {/* Gender Section */}
          <div>
            <label className="block text-[14px] font-bold text-black mb-2">Select your Gender*</label>
            <div className="flex space-x-4">
              <button
                type="button"
                className={`w-1/3 p-2 rounded-lg border ${gender === 'male' ? 'text-white' : 'bg-gray-200 text-gray-600'} hover:text-white`}
                onClick={() => setGender('male')}
                style={{backgroundColor : gender === 'male' ? data.color.ctaPrimary : ''}}

              >
                Male
              </button>
              <button
                type="button"
                className={`w-1/3 p-2 rounded-lg border ${gender === 'female' ? 'text-white' : 'bg-gray-200 text-gray-600'} hover:text-white`}
                onClick={() => setGender('female')}
                style={{backgroundColor : gender === 'female' ? data.color.ctaPrimary : ''}}

              >
                Female
              </button>
              <button
                type="button"
                className={`w-1/3 p-2 rounded-lg border ${gender === 'other' ? 'text-white' : 'bg-gray-200 text-gray-600'} hover:text-white`}
                onClick={() => setGender('other')}
                style={{backgroundColor : gender === 'other' ? data.color.ctaPrimary : ''}}
              >
                Other
              </button>
            </div>
          </div>
          <div>
            <label className="block text-[14px] font-bold text-black mb-2">Email address*</label>
            <div className="relative">
              <input
                type="email"
                placeholder="Email ID"
                className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {/* <button
                type="button"
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-lg hover:bg-indigo-500 hover:text-white"
              >
                Verify
              </button> */}
            </div>
          </div>
          {/* <div>
            <label className="block text-[14px] font-bold text-black mb-2">Share your pincode*</label>
            <div className="relative">
              <input
                type="text"
                placeholder="Enter Pincode"
                className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                required
              />
              <button
                type="button"
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-lg hover:bg-indigo-500 hover:text-white"
              >
                Locate Me
              </button>
            </div>
          </div> */}
        </div>

        
        {/* Occupation Section */}
        {/* <div className="mb-6">
          <label className="block text-[14px] font-bold text-black mb-2">Are you a professional?</label>
          <select
            className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
          >
            <option value="">Occupation (Optional)</option>
            <option value="engineer">Engineer</option>
            <option value="doctor">Doctor</option>
            <option value="teacher">Teacher</option>
          </select>
        </div> */}
        <div>
            <label className="block text-[14px] font-bold text-black mb-2">Tell us your anniversary date*</label>
            <input
              type="date"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              value={anniversaryDate}
              onChange={(e) => setAnniversaryDate(e.target.value)}
              required
            />
          </div>
        {/* Save Button */}
        <div className="text-center mt-4">
          <button
            type="submit"
            className="p-2 text-white py-3 rounded-lg font-bold w-full"
            style={{backgroundColor : data.color.ctaPrimary}}
          >
            SAVE DETAILS
          </button>
        </div>
      </form>
    </div>
    </div>
    {/* Toast container */}
    <ToastContainer />
    </div>
  );
};

export default ProfileEditMobile;
