import React, { useEffect, useRef, useState } from 'react';
import { ChevronDown, Search, User,Cart, Shop, UserCircle } from 'icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Hamburger from 'icons/hamburger';
import { useGetBackgroundColor } from "../../../src/api/index";
import GoldRate from 'components/goldRate';
const MobileMenu = ({categoryFilter, closeModel}) => {
  const menuData = useSelector((state) => state.menu?.menuData);
  const storeData = useSelector((state) => state.storeData);
  const refUserDetails = JSON.parse(localStorage.getItem(`vjw-${window.location.hostname}user-det`));
  
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);
  const storeNameRef = useRef(null);
  const [fontSize, setFontSize] = useState(16);
  const { data } = useGetBackgroundColor({ onLoad: true });
  const [showDetails, setShowDetails] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetails);
  };

  useEffect(() => {
    const adjustFontSize = () => {
      const element = storeNameRef.current;
      const containerHeight = 50;
      const lineHeight = 1.2;

      if (element) {
        const currentHeight = element.scrollHeight;
        const maxHeight = containerHeight * lineHeight;

        if (currentHeight > maxHeight) {
          setFontSize((prevFontSize) => prevFontSize - 1.45);
        }
      }
    };

    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);

    return () => {
      window.removeEventListener('resize', adjustFontSize);
    };
  }, [storeData]);

  useEffect(() => {
    setIsOpen(categoryFilter);
  }, [categoryFilter])
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if(closeModel){
      closeModel(false);
    }
  };

  const logout = () => {
    localStorage.removeItem(`vjw-${window.location.hostname}user-det`);
    setIsOpen(!isOpen);
    if(closeModel){
      closeModel(false);
    }
    };

  const toggleSubmenu = (index) => {
    setOpenSubmenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const menuD = menuData?.data?.map((item) => ({
    id:item.id,
    title: item.name,
    description: item.description, 
    submenu: [
      {
        title: 'SHOP BY STYLE',
        items: item.sub.map((subItem) => ({
          id: subItem?.id,
          img: subItem?.img,
          name: subItem?.name,
          description: subItem?.description
        })),
      },
    ],
  }));

  return (
    <>
    <header id='header' className='fixed w-full z-10 h-[55px]'>
      <div
        className='px-5 py-5'
        style={{ backgroundColor: data.color.secondaryColor }}
      >
        <div className='relative flex justify-between'>
          {/* Mobile Menu Button */}
          <button
            id='mobile-menu-button'
            className='hidden text-black lg:block'
            onClick={toggleMenu}
          >
            <Hamburger width={22} height={22} />
          </button>

          <Link
            to='/'
            className='z-15 absolute left-[50px] top-1/2 flex -translate-y-1/2 overflow-hidden'
            // className="flex" style={{ alignItems: 'center' }}
          >
            <div
              // className='col'
              className="flex" style={{ alignItems: 'center' }}
            >
              <img
                src={storeData.storeLogo}
                alt='Main Logo'
                width={57}
                height={57}
              />
              <h3
              className="text-[#FFFFFF] text-inter"
              style={{
                marginLeft: '5px',
                marginRight: '5px',
                maxWidth: '220px',
                minWidth:"180px",
                height: '50px',
                whiteSpace: 'normal',
                overflow: 'hidden',
                wordBreak: 'break-word',
                lineHeight: '1.2em',
                fontSize: `${fontSize}px`,
                transition: 'font-size 0.3s ease',
                textAlign: 'center', // Ensures text is centered within the container
                display: 'flex',
                flexDirection: 'column', // Aligns content vertically
                justifyContent: 'center', // Centers vertically
              }}
            >
              {storeData?.name
                ?.toUpperCase()
                ?.split(',')
                ?.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontFamily:data.font,
                      fontWeight: index === 0 ? 'bold' : 'unset',
                      fontSize: index === 0 ? '18px' : '14px',
                      display: 'block',
                    }}
                  >
                    {part}
                  </span>
                ))}
            </h3>
            </div>
          </Link>

          <div className='flex items-center gap-3'>
            {/* <Link to="/">
            <Search width={18} height={18} />
          </Link> */}
            <div onClick={handleClick} className='cursor-pointer'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='21'
                height='23'
                viewBox='0 0 21 23'
                fill='none'
              >
                <path
                  d='M18.375 21.9654H2.625C2.26625 21.9654 1.96875 21.684 1.96875 21.3446C1.96875 21.0053 2.26625 20.7239 2.625 20.7239H18.375C18.7337 20.7239 19.0312 21.0053 19.0312 21.3446C19.0312 21.684 18.7337 21.9654 18.375 21.9654Z'
                  fill='white'
                />
                <path
                  d='M4.89998 19.4825H3.5C2.66 19.4825 1.96875 18.8287 1.96875 18.0342V10.8998C1.96875 10.1053 2.66 9.45142 3.5 9.45142H4.89998C5.73998 9.45142 6.43123 10.1053 6.43123 10.8998V18.0342C6.43123 18.8287 5.73998 19.4825 4.89998 19.4825ZM3.5 10.6846C3.3775 10.6846 3.28125 10.7757 3.28125 10.8915V18.0342C3.28125 18.15 3.3775 18.2411 3.5 18.2411H4.89998C5.02248 18.2411 5.11873 18.15 5.11873 18.0342V10.8998C5.11873 10.7839 5.02248 10.6929 4.89998 10.6929H3.5V10.6846Z'
                  fill='white'
                />
                <path
                  d='M11.1998 19.4824H9.7998C8.9598 19.4824 8.26855 18.8285 8.26855 18.034V8.25943C8.26855 7.46488 8.9598 6.81104 9.7998 6.81104H11.1998C12.0398 6.81104 12.731 7.46488 12.731 8.25943V18.034C12.731 18.8285 12.0398 19.4824 11.1998 19.4824ZM9.7998 8.05251C9.6773 8.05251 9.58105 8.14355 9.58105 8.25943V18.034C9.58105 18.1499 9.6773 18.2409 9.7998 18.2409H11.1998C11.3223 18.2409 11.4185 18.1499 11.4185 18.034V8.25943C11.4185 8.14355 11.3223 8.05251 11.1998 8.05251H9.7998Z'
                  fill='white'
                />
                <path
                  d='M17.4996 19.4825H16.0996C15.2596 19.4825 14.5684 18.8286 14.5684 18.0341V5.61929C14.5684 4.82474 15.2596 4.1709 16.0996 4.1709H17.4996C18.3396 4.1709 19.0308 4.82474 19.0308 5.61929V18.0341C19.0308 18.8286 18.3396 19.4825 17.4996 19.4825ZM16.0996 5.41238C15.9771 5.41238 15.8809 5.50342 15.8809 5.61929V18.0341C15.8809 18.1499 15.9771 18.241 16.0996 18.241H17.4996C17.6221 18.241 17.7183 18.1499 17.7183 18.0341V5.61929C17.7183 5.50342 17.6221 5.41238 17.4996 5.41238H16.0996Z'
                  fill='white'
                />
                <path
                  d='M4.14161 6.79553C3.93161 6.79553 3.72161 6.72235 3.55582 6.56553C3.2353 6.26235 3.2353 5.76053 3.55582 5.45735L7.09267 2.11189C7.26951 1.94462 7.50161 1.86098 7.75583 1.88189C7.99898 1.9028 8.22004 2.02825 8.36372 2.22689L9.56846 3.94144L13.4921 0.230072C13.8127 -0.0731099 14.3432 -0.0731099 14.6637 0.230072C14.9842 0.533254 14.9842 1.03507 14.6637 1.33825L10.0216 5.72916C9.84477 5.89644 9.61267 5.98007 9.35846 5.95916C9.1153 5.93825 8.89425 5.8128 8.75056 5.61416L7.54582 3.89962L4.7274 6.56553C4.56161 6.72235 4.35161 6.79553 4.14161 6.79553Z'
                  fill='white'
                />
                <path
                  d='M14.0893 3.65909C13.6361 3.65909 13.2603 3.30364 13.2603 2.875V1.56818H11.8788C11.4256 1.56818 11.0498 1.21273 11.0498 0.784091C11.0498 0.355455 11.4256 0 11.8788 0H14.0893C14.5424 0 14.9182 0.355455 14.9182 0.784091V2.875C14.9182 3.30364 14.5424 3.65909 14.0893 3.65909Z'
                  fill='white'
                />
              </svg>
            </div>
            {/* <Link to='/storeList'>
              <Shop width={20} height={20} />
            </Link>
            <Link to='/account'>
              <UserCircle width={20} height={20} />
            </Link> */}
            {/* <Link to="/">
            <img
              src="/assets/img/icons/wishlist.png"
              alt="Wishlist"
              style={{ width: '15px', height: '15px' }}
            />
          </Link> */}
            <Link to='/checkout'>
              <Cart width={20} height={20}></Cart>
            </Link>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          id='mobile-menu'
          className={`fixed inset-0 z-50 bg-white text-black transition-all duration-300 ease-in-out ${
            isOpen ? 'block translate-x-0' : '-translate-x-full'
          } h-screen overflow-auto`}
        >
          <div
            className='sticky top-0 z-[99] flex items-center justify-between px-[25px] py-[17px]'
            style={{ backgroundColor: data.color.primaryColor }}
          >
            <div className='profile-section flex items-center gap-[22px] text-white'>
              <div className='flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[#D9D9D9] object-contain'>
                <User width={16} height={16} />
              </div>
              <div className='user-details flex flex-col'>
                <a className='text-[10px] font-medium'>
                  {refUserDetails ? 'Welcome Back' : 'Guest User'}
                </a>
                <Link
                  className='text-[15px] font-bold'
                  to={`/login`}
                  onClick={toggleMenu}
                >
                  {refUserDetails
                    ? refUserDetails?.firstName + ' ' + refUserDetails?.lastName
                    : 'Tap to Login / Sign Up'}
                </Link>
              </div>
            </div>
            <button
              id='close-mobile-menu'
              className='text-white'
              onClick={toggleMenu}
            >
              <svg
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div>
          {refUserDetails && (
            <div
              className='sticky top-[74px] z-[99] flex items-center justify-between px-[25px] py-[9px]'
              style={{ backgroundColor: data.color.secondaryColor }}
            >
              <button
                id='close-mobile-menu'
                className='flex items-center justify-center pl-12 text-[13px] font-bold text-white'
                onClick={toggleMenu}
              >
                <Link
                  to='/account'
                  onClick={toggleMenu}
                  className='flex items-center justify-center pl-0 text-[13px] font-bold text-white'
                >
                  MY ACCOUNT
                </Link>
              </button>
              <button
                id='close-mobile-menu'
                className='flex items-center justify-center pr-12 text-[13px] font-bold text-white'
                onClick={logout}
              >
                LOGOUT
              </button>
            </div>
          )}

          <ul className='menu sidebarNav'>
            {menuD?.map((item, index) => (
              <li key={index}>
                <h3
                  className='accordian relative flex cursor-pointer items-center justify-between border-b border-solid bg-[#F3F3F380] px-[50px] py-[20px] pr-[35px]'
                  onClick={() => toggleSubmenu(index)}
                >
                  {console.log("item",item)}
                  <div className='flex flex-col font-inter text-black'>
                    <p className='text-[13px] font-extrabold'>{item.title}</p>
                    <p className='text-[11px] font-semibold text-[#666666]'>
                      {item.description}
                    </p>
                  </div>
                  <div
                    className={`transition-all duration-300 ease-in-out ${
                      openSubmenuIndex === index ? 'rotate-180' : ''
                    }`}
                  >
                    <ChevronDown width={16} height={16} />
                  </div>
                </h3>

                {/* Submenu */}
                <ul
                  className={`accordian-item transition-max-h overflow-hidden duration-300 ${
                    openSubmenuIndex === index ? 'max-h-[1000px]' : 'max-h-0'
                  }`}
                >
                  {item.submenu.map((submenuItem, subIndex) => (
                    <li key={subIndex} className='sub-accordian'>
                      <div className='flex items-center gap-[13px] border-b-[1px] border-solid border-gray-200 bg-[#F3F3F3] px-[47px] py-[12px]'>
                        <img
                          src={
                            subIndex === 0
                              ? '/assets/img/mobile_minues.png'
                              : ''
                          }
                          className='h-[11px] w-[11px] object-contain'
                          alt=''
                        />
                        <p className='text-[14px] font-semibold'>
                          {submenuItem.title}
                        </p>
                      </div>
                      <ul className='sub-accordian-item pl-5' style={{ paddingLeft: '25px', paddingRight:'25px' }}>
                        {submenuItem.items.map((subItem, itemIndex) => (
                          <Link
                            to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_PRODUCT_QUERY_ROUTE}=${subItem?.id}`}
                            className='border-b border-black text-[20px] font-semibold md:text-[20px] sm:text-[15px]'
                            onClick={toggleMenu} // Close menu on click
                            key={itemIndex}
                          >
                            <li className='flex cursor-pointer items-center gap-[10px] rounded-[5px] border-b-[1px] border-solid border-gray-200 px-[64px] py-[8px] hover:bg-[#e5e5e5] pl-5'>
                              <img
                                className='h-[85px] w-[85px] rounded-[5px]' // Increase image size
                                src={
                                  subItem.img || '/assets/ornament-image.png'
                                }
                                alt={subItem.name}
                              />
                              <span>{subItem.description ||subItem.name}</span>{' '}
                              {/* Name in the same line as the image */}
                            </li>
                          </Link>
                        ))}
                      </ul>
                    </li>
                  ))}
                  <li key={2554} className='sub-accordian'>
                    <div className='flex items-center gap-[13px] border-b-[1px] border-solid border-gray-200 bg-[#F3F3F3] px-[47px] py-[12px]'>
                        <img
                          src={
                            '/assets/img/mobile_minues.png'
                          }
                          className='h-[11px] w-[11px] object-contain'
                          alt=''
                        />
                        <p className='text-[14px] font-semibold'>
                          Shop For
                        </p>
                      </div>
                      <ul className='sub-accordian-item pl-5' style={{ paddingLeft: '25px', paddingRight:'25px' }}>
                          <li key={1} className='flex cursor-pointer items-center gap-[10px] rounded-[5px] border-b-[1px] border-solid border-gray-200 px-[64px] py-[8px] hover:bg-[#e5e5e5] pl-5'>
                          <Link
                            to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_PRODUCT_QUERY_ROUTE}=${item?.id}&gender=male`}
                            className=' text-[20px] font-semibold md:text-[20px] sm:text-[15px]'
                            onClick={toggleMenu} // Close menu on click
                            key={1}
                          >
                            <p>FOR MEN</p>
                          </Link>
                        </li>
                        <li key={2} className='flex cursor-pointer items-center gap-[10px] rounded-[5px] border-b-[1px] border-solid border-gray-200 px-[64px] py-[8px] hover:bg-[#e5e5e5] pl-5'>
                          <Link
                            to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_PRODUCT_QUERY_ROUTE}=${item?.id}&gender=female`}
                            className=' text-[20px] font-semibold md:text-[20px] sm:text-[15px]'
                            onClick={toggleMenu} // Close menu on click
                            key={2}
                          >
                            <p>FOR WOMEN</p>
                          </Link>
                        </li>
                        <li key={3} className='flex cursor-pointer items-center gap-[10px] rounded-[5px] border-b-[1px] border-solid border-gray-200 px-[64px] py-[8px] hover:bg-[#e5e5e5] pl-5'>
                          <Link
                            to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_PRODUCT_QUERY_ROUTE}=${item?.id}&gender=kids`}
                            className=' text-[20px] font-semibold md:text-[20px] sm:text-[15px]'
                            onClick={toggleMenu} // Close menu on click
                            key={3}
                          >
                            <p >FOR KIDS</p>
                          </Link>
                        </li>
                        
                        </ul>
                      </li>
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
      </header>
      {showDetails && (
        <>
          <div
            className='relative z-10'
            aria-labelledby='modal-title'
            role='dialog'
            aria-modal='true'
          >
            <div
              className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
              aria-hidden='true'
            ></div>

            <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
              <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                <div
                  className='relative z-50 w-[275px] rounded-[10px] p-4'
                  style={{ backgroundColor: '#f3f1de' }}
                >
                  {/* Close button positioned in the top-right corner */}
                  <button
                    type='button'
                    className='absolute right-4 top-3 text-xl font-bold text-black focus:outline-none'
                    onClick={handleClick}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='17'
                      viewBox='0 0 19 19'
                      fill='none'
                    >
                      <path
                        d='M17.3125 17.3125L9.5 9.5M9.5 9.5L1.6875 1.6875M9.5 9.5L17.3125 1.6875M9.5 9.5L1.6875 17.3125'
                        stroke='black'
                        strokeWidth='2'
                        strokeLinecap='round'
                      />
                    </svg>
                  </button>
                  <div className='text-center mt-3'>
                    <GoldRate />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MobileMenu;
