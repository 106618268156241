// features/login/loginSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    designBankProducts: null
};

const dbpSlice = createSlice({
    name: 'designBankProducts',
    initialState,
    reducers: {
        setDesignBankProducts(state, action) {
            state.pricing = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Handle other action types or async actions here if needed
    }
});

export const { setDesignBankProducts } = dbpSlice.actions;
// Export other actions as needed

export default dbpSlice.reducer;
