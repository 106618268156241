import React, { useState, useEffect } from 'react';
import './App.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { CachePersistor } from 'apollo3-cache-persist';
import MasterRoutes from './routes';
import { Provider } from 'react-redux';
import store from 'hooks/redux/store';
import INITmain from 'components/master/INITmain';
import 'react-multi-carousel/lib/styles.css';
import { getPublicGraphqlUrl, getTenantGraphqlUrl } from './config/env';

// Define error handling link
const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, location, path }) => {
      alert(`Graphql error ${message}`);
    });
  }
});

// Define authentication link
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(`vjw-${window.location.hostname}-token`);
  const ref_token = localStorage.getItem(`vjw-${window.location.hostname}user`);

  let authHeader = '';
  if (
    _.operationName === 'storeDetails' ||
    _.operationName === 'getDesignBankProducts' ||
    _.operationName === 'B2BProductDetails' ||
    _.operationName === 'getB2BDBCategories' ||
    _.operationName === 'dbpProducts' ||
    _.operationName === 'themeColors'
  ) {
    authHeader = localStorage.getItem(
      `vjw-${window.location.hostname}ad-token`
    );
  } else if (token) {
    authHeader = token;
  } else if (ref_token) {
    authHeader = JSON.parse(ref_token)?.token;
  }

  return {
    headers: {
      ...headers,
      Authorization: authHeader ? `JWT ${authHeader}` : '',
    },
  };
});

// Split link for different operations
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      (definition.name?.value === 'storeDetails' ||
        definition.name?.value === 'dbpProducts' ||
        definition.name?.value === 'createToken' ||
        definition.name?.value === 'publishedBanners' ||
        definition.name?.value === 'getDesignBankProducts' ||
        definition.name?.value === 'B2BProductDetails' ||
        definition.name?.value === 'getB2BDBCategories' ||
        definition.name?.value === 'themeColors')
    );
  },
  new HttpLink({
    uri: getPublicGraphqlUrl(),
  }),
  new HttpLink({
    uri: getTenantGraphqlUrl(),
  })
);

// Initialize cache and client
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        // Define merge functions for specific query fields
        // This helps Apollo properly merge and cache paginated or filtered data
        getAllProducts: {
          // Merge function for product queries
          merge(existing = [], incoming) {
            return incoming; // For filtered queries, replace with new results
          },
          // Read function can be used to customize cache reads
          read(existing) {
            return existing || []; // Return empty array if null/undefined
          }
        },
        dbpProducts: {
          // Merge function for B2B product queries
          merge(existing = [], incoming) {
            return incoming;
          },
          read(existing) {
            return existing || []; // Return empty array if null/undefined
          }
        },
        // Add collection-related query fields to prevent null errors
        collections: {
          merge(existing = [], incoming) {
            return incoming;
          },
          read(existing) {
            return existing || { edges: [] }; // Return empty collection if null/undefined
          }
        },
        publishedBannerDetails: {
          merge(existing = [], incoming) {
            return incoming;
          },
          read(existing) {
            return existing || []; // Return empty array if null/undefined
          }
        },
        storeDetails: {
          merge(existing, incoming) {
            return incoming;
          },
          read(existing) {
            return existing || {}; // Return empty object if null/undefined
          }
        },
        // Add more field policies as needed for other query types
      }
    },
    // Define how product objects should be cached and identified
    Product: {
      // Use id as the primary key for caching products
      // This matches the 'id' field returned in all Product queries
      keyFields: ['id'],
      fields: {
        // Define specific field policies for nested objects if needed
        images: {
          // Merge images arrays by replacing rather than concatenating
          merge(existing, incoming) {
            return incoming;
          }
        },
        defaultVariant: {
          // Ensure variants are properly cached
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    },
    // Define how category objects should be cached
    Category: {
      keyFields: ['id'],
    },
    // Define how product variants should be cached
    ProductVariant: {
      keyFields: ['id'],
    }
  }
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(link),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
    // Keep mutations as network-only to ensure they're always executed
    mutate: {
      fetchPolicy: 'network-only',
    },
  },
});

// List of operations that should use cache-first policy
const CACHE_FIRST_OPERATIONS = [
  'storeDetails',
  'themeColors',
  'getCategory',
  'getDBPIdsWithAutoInventory',
  'dbpProducts',
  'allCategoryPricing',
  'publishedBanners',
  'getFAQs',
  'allProductsData',
  'collections'
];

// List of operations that must always use network-only policy
const NETWORK_ONLY_OPERATIONS = [
  'B2BProductDetails'
];

// Override the fetch policy for specific operations
const originalQuery = client.query;
client.query = function(options) {
  const operationName = options.query.definitions[0]?.name?.value;
  
  if (CACHE_FIRST_OPERATIONS.includes(operationName)) {
    options.fetchPolicy = 'cache-first';
  } else if (NETWORK_ONLY_OPERATIONS.includes(operationName)) {
    options.fetchPolicy = 'network-only';
  }
  
  return originalQuery.call(this, options);
};

const originalWatchQuery = client.watchQuery;
client.watchQuery = function(options) {
  const operationName = options.query.definitions[0]?.name?.value;
  
  if (CACHE_FIRST_OPERATIONS.includes(operationName)) {
    options.fetchPolicy = 'cache-first';
  } else if (NETWORK_ONLY_OPERATIONS.includes(operationName)) {
    options.fetchPolicy = 'network-only';
  }
  
  return originalWatchQuery.call(this, options);
};

const persistor = new CachePersistor({
  cache,
  storage: window.localStorage,
  key: 'apollo-cache',
  maxSize: 5242880, // 5MB size limit (5 * 1024 * 1024)
  debug: process.env.REACT_APP_SERVER_ENVIRONMENT === 'development',
});

// Function to handle cache persistence
const initializeCache = async () => {
  try {
    // Purge specific problematic queries from the cache
    // This prevents errors from cached data that might be incomplete or invalid
    const purgeQueries = [
      'getCategoryPricing',
      'getCategoryPricingByCatSubCat',
      'getCategoryPricingData',
      'B2BProductDetails'
    ];
    
    // Get the current cache
    const currentCache = JSON.parse(localStorage.getItem('apollo-cache') || '{}');
    
    // If we have a ROOT_QUERY, check for problematic queries
    if (currentCache.ROOT_QUERY) {
      let cacheModified = false;
      
      // Remove problematic queries from the cache
      purgeQueries.forEach(queryName => {
        if (currentCache.ROOT_QUERY[queryName]) {
          delete currentCache.ROOT_QUERY[queryName];
          cacheModified = true;
        }
      });
      
      // If we modified the cache, save it back
      if (cacheModified) {
        localStorage.setItem('apollo-cache', JSON.stringify(currentCache));
      }
    }
    
    // Now restore the cleaned cache
    await persistor.restore();
  } catch (error) {
    console.error('Error restoring cache:', error);
    // If there's an error, purge the cache completely
    await persistor.purge();
  }
};

function App() {
  const [isCacheRestored, setIsCacheRestored] = useState(false);

  useEffect(() => {
    const restoreCache = async () => {
      await initializeCache();
      setIsCacheRestored(true);
    };
    restoreCache();
  }, []);

  if (!isCacheRestored) {
    return <div>Loading...</div>;
  }

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <INITmain>
          <MasterRoutes />
        </INITmain>
      </ApolloProvider>
    </Provider>
  );
}

export default App;
