import * as React from 'react';
const Amex = props => (
  <svg
    width={47}
    height={50}
    viewBox='0 0 28 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect
      x={0.2}
      y={0.2}
      width={26.5423}
      height={17.7522}
      rx={5.5}
      fill='#1F72CD'
      stroke='#D9D9D9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.39539 6.64062L2.81848 12.3028H5.90341L6.28585 11.4H7.16002L7.54246 12.3028H10.9381V11.6138L11.2407 12.3028H12.9972L13.2997 11.5992V12.3028H20.3617L21.2204 11.4235L22.0245 12.3028L25.6517 12.3101L23.0666 9.48752L25.6517 6.64062H22.0808L21.2449 7.50372L20.4661 6.64062H12.7836L12.1239 8.10208L11.4487 6.64062H8.37022V7.30622L8.02776 6.64062H5.39539ZM15.941 7.44466H19.9964L21.2367 8.77496L22.517 7.44466H23.7574L21.8728 9.48673L23.7574 11.5053H22.4608L21.2204 10.1595L19.9336 11.5053H15.941V7.44466ZM16.9425 9.0276V8.2859V8.28519H19.4729L20.577 9.47135L19.424 10.664H16.9425V9.85428H19.1549V9.0276H16.9425ZM5.99231 7.44466H7.49605L9.20531 11.2842V7.44466H10.8526L12.1728 10.1976L13.3895 7.44466H15.0286V11.5077H14.0313L14.0231 8.32392L12.5691 11.5077H11.677L10.2148 8.32392V11.5077H8.16308L7.77411 10.5968H5.67266L5.28449 11.5069H4.1852L5.99231 7.44466ZM6.03148 9.75473L6.72383 8.1321L7.41537 9.75473H6.03148Z'
      fill='white'
    />
  </svg>
);
export default Amex;
