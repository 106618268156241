import React, { useState } from 'react';
import './UserProfileDesktop.css'; // Make sure to create this CSS file
import MyAccountAside from 'components/account/MyAccountAside';
import { Link } from 'react-router-dom';
import { useGetBackgroundColor } from "../../../src/api/index";

function UserProfileDesktop() {
    const refUserDetails = JSON.parse(localStorage.getItem(`vjw-${window.location.hostname}user-det`));
    const { data } = useGetBackgroundColor({ onLoad: true });

  return (
    <div className="orders-page-container">
      <MyAccountAside/>
        <div className="user-profile">
        <div className="profile-content">
            <div className="profile-header border-b">
                <h3 className='font-bold text-[19px]'>Your Profile Details</h3>
                <div className="mb-3">
                    <button className="text-white py-2 px-4 rounded-md w-full" style={{backgroundColor : data.color.ctaPrimary}}>
                    <Link to="/edit-profile" className="text-white-700">
                        Edit Profile
                    </Link>
                    </button>
                </div>
            </div>
            <div className="profile-details">
            <div className="detail">
                <label>Name</label>
                <div>{refUserDetails?.firstName +" "+refUserDetails?.lastName}</div>
            </div>
            <div className="detail">
                <label>Gender</label>
                <div className="detail">
                    {refUserDetails?.gender 
                        ? refUserDetails.gender.charAt(0).toUpperCase() + refUserDetails.gender.slice(1).toLowerCase() 
                        : '-'}
                </div>
            </div>
            <div className="detail">
                <label>Mobile No.</label>
                <div>{refUserDetails?.phoneNumber}</div>
            </div>
            <div className="detail">
                <label>Email</label>
                <div className="detail">{refUserDetails?.email || '-' }</div>
            </div>
            <div className="detail">
                <label>Pincode</label>
                <div className="detail">{refUserDetails?.addresses.length>0 ? refUserDetails?.addresses[0]?.postalCode :'-' }</div>
            </div>
            <div className="detail">
                <label>Birthday</label>
                <div>{refUserDetails?.birthDate ? refUserDetails?.birthDate?.split("T")[0] : '-'}</div>
            </div>
            <div className="detail">
                <label>Anniversary</label>
                <div>{refUserDetails?.anniversaryDate ? refUserDetails?.anniversaryDate?.split("T")[0] : '-'}</div>
            </div>
            </div>
        </div>
        </div>
    </div>
  );
}

export default UserProfileDesktop;
