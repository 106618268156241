import React, { useRef, useState, useEffect } from 'react';
import { useSendAndValidateOtp } from "../api";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetBackgroundColor } from "../../../../src/api/index";

const OTPFormDesktop = () => {
    const { data } = useGetBackgroundColor({ onLoad: true });
    const [values, setValues] = useState(Array(6).fill(''));
    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(false);
    const inputsRef = useRef([]);
    const { verifyOtp, error, success, resendOtp } = useSendAndValidateOtp();
    const navigate = useNavigate();

    const location = useLocation();
    const { phone } = location.state || {};

    useEffect(() => {
        // Retrieve saved timer from localStorage
        const savedTimer = localStorage.getItem('otpTimer');
        const savedTimestamp = localStorage.getItem('otpTimestamp');
        
        if (savedTimer && savedTimestamp) {
            const timeElapsed = Math.floor((Date.now() - parseInt(savedTimestamp)) / 1000);
            const remainingTime = parseInt(savedTimer) - timeElapsed;
            
            if (remainingTime > 0) {
                setTimer(remainingTime);
            } else {
                setCanResend(true);
            }
        } else {
            setCanResend(true);
        }
    }, []);

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
            localStorage.setItem('otpTimer', timer);
            localStorage.setItem('otpTimestamp', Date.now().toString());

            return () => clearInterval(interval);
        } else {
            setCanResend(true);
            localStorage.removeItem('otpTimer');
            localStorage.removeItem('otpTimestamp');
        }
    }, [timer]);

    const handleInputChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]$/.test(value) || value === '') {
            const newValues = [...values];
            newValues[index] = value;
            setValues(newValues);
            if (value && index < values.length - 1) {
                inputsRef.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && values[index] === '') {
            if (index > 0) {
                inputsRef.current[index - 1].focus();
            }
        }
    };

    const handleFocus = (e) => {
        e.target.select();
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text');
        if (/^\d{6}$/.test(pastedText)) {
            const digits = pastedText.split('');
            setValues(digits);
            inputsRef.current[digits.length - 1].focus();
        }
    };
    useEffect(() => {
        console.log(phone);
        
        if (!phone) {
            navigate('/login')
        }
    }, [phone, navigate])


    const handleSubmit = (e) => {
        e.preventDefault();        
        const otp = values.join('');
        verifyOtp({otp,phone});             
    };

    const handleResend = () => {
        if (canResend) {
            resendOtp(); // Call your resend OTP function
            setTimer(60); // Reset timer
            setCanResend(false);
        }
    };

    return (
        <main className="relative min-h-screen flex flex-col justify-center bg-[#F8FAFC] overflow-hidden">
            <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-5">
                <div className="flex justify-center">
                    <div className="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
                        <header className="mb-8">
                            <h1 className="text-2xl font-bold mb-1">Mobile Phone Verification</h1>
                            <p className="text-[15px] text-slate-500">Enter the 4-digit verification code that was sent to your phone number.</p>
                        </header>
                        <form onSubmit={handleSubmit}>
                            <div className="flex items-center justify-center gap-3">
                                {values.map((value, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        value={value}
                                        onChange={(e) => handleInputChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        onFocus={handleFocus}
                                        onPaste={handlePaste}
                                        ref={(el) => inputsRef.current[index] = el}
                                        className="w-14 h-14 text-center text-2xl border-none font-extrabold text-slate-900 bg-[#F1F5F9] border border-transparent hover:border-[#E2E8F0] appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                                        pattern="\d*"
                                        maxLength="1"
                                    />
                                ))}
                            </div>
                            <div className="max-w-[260px] mx-auto mt-4">
                                <button
                                    type="submit"
                                    className="w-full inline-flex justify-center whitespace-nowrap rounded-lg px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
                                    style={{backgroundColor : data.color.ctaPrimary}}
                                >
                                    Verify Account
                                </button>
                            </div>
                        </form>
                        <div className="text-sm text-slate-500 mt-4">
                            Didn't receive code?{' '}
                            <button
                                className={`font-medium ${canResend ? '' : 'text-gray-400 cursor-not-allowed'}`}
                                onClick={handleResend}
                                disabled={!canResend}
                                style={{color : canResend ? data.color.secondaryColor : ""}}
                            >
                                Resend
                            </button>
                        </div>
                        {error && <p className="text-red-500 text-[14px] pt-4">{error.message}</p>}
                        {success && <p className="text-green-500 text-[14px] pt-4">{success.message}</p>}
                        {timer > 0 && <p className="text-sm text-slate-500 mt-4">Resend available in {timer}s</p>}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default OTPFormDesktop;
