import { capitalizeFirstLetter } from 'functions/global';
import { useMarquee } from './marquee';
import { CurrencyPrefix } from 'utils';
import { displayprice, generateMCXRates } from 'functions';

import {
  useAllB2BProducts,
  useGetAllCategories,
  useGetDBPInventory,
  useGetStoreProducts,
} from './products';
import { useDispatch } from 'react-redux';
// import { setMenuData, setStoreData } from "hooks/redux/actions/front";
import { useEffect } from 'react';
import { useFAQs } from './useFAQs';
import { useSelector } from 'react-redux';
import { useGetUserDetails } from './userDetails';
import { useMarqueeApi } from 'pages/home/api';
import { useStoreData, useThemeColorData, useSaveVisitor } from './storeData';
import { setStoreData, setMenuData, setPricing } from 'hooks/redux/reducers';
import { useCreateToken } from './createToken';
import { useProductDetails } from './singleProductDetails';
import {
  useCategoryPricing,
  useCategoryPricingByCatSubCat,
  useGetAllCategoryPricing,
} from './categoryPricing';
import { color } from 'framer-motion';

// export const useMarqueeApi = (onLoad) => {
//     const { executeQuery, data, loading, error, refetch } = useMarquee(onLoad);
//     let marqueeText = data;

//     if (data) {
//         if (data?.loading) {
//             marqueeText = "Loading..."
//         } else if (data?.error) {
//             marqueeText = "Something Went Wrong! Please Reload"
//         } else {

//             const rates = data?.storeDetails?.metalRates;

//             const rateEntries = rates ? Object.entries(generateMCXRates(rates)) : [];

//             // Generate the text for the marquee
//             marqueeText = rateEntries
//                 .filter(([key, value]) => key.includes("Rate") && !key.includes("diamond") && value !== 0)
//                 .map(([key, value]) =>
//                     `${capitalizeFirstLetter(key.split("Rate")[0])}: ${CurrencyPrefix()}${value.toFixed(2)}/-`
//                 )
//                 .join(" - ");
//         }
//     }
//     return {
//         executeQuery,
//         data: marqueeText,
//         loading,
//         error,
//         refetch,
//     };
// };

export const useGetStoreProductsApi = ({ onLoad, searchParams }) => {
  const { executeQuery, data, loading, error, refetch } = useGetStoreProducts({
    onLoad,
    searchParams,
  });
  const storeProducts = [];
  const pricing = useSelector(state => state.categoryPricing)?.pricing;
  const storeDetails = useSelector(state => state.storeData);

  if (data) {
    const productsData = data.products.edges || [];
    for (const edge of productsData) {
      const product = edge.node;
      // console.log("product Line 58 :: ",product?.subcategory);
      const subCategoryId = product.subcategory?.id;
      const parentCategoryId = product.subcategory?.parent?.id;
      const parentCategoryName = product.subcategory?.parent?.name;
      const parentImageCategory = product.subcategory.backgroundImage?.url;
      const childImageCategory =
        product.subcategory?.parent?.backgroundImage?.url;

      const subCategoryName =
        product.subcategory?.seoDescription || product.subcategory?.name;

      let parentCategoryObj = storeProducts.find(
        item => item.id === parentCategoryId
      );

      if (
        parentCategoryObj === undefined &&
        parentCategoryId &&
        parentCategoryName &&
        parentImageCategory
      ) {
        parentCategoryObj = {
          id: parentCategoryId,
          name: parentCategoryName,
          img: parentImageCategory,
          sub: [],
        };
        storeProducts.push(parentCategoryObj);
      }

      let subCategoryObj = parentCategoryObj?.sub?.find(
        sub => sub.id === subCategoryId
      );

      if (
        subCategoryObj === undefined &&
        subCategoryId &&
        subCategoryName &&
        childImageCategory
      ) {
        subCategoryObj = {
          id: subCategoryId,
          name: subCategoryName,
          img: childImageCategory,
          products: [],
        };
        parentCategoryObj.sub.push(subCategoryObj);
      }
      const metalTypeAttribute = product?.attributes?.find(
        att => att.attribute?.slug === 'metal-type'
      );
      const metalTypeName = metalTypeAttribute?.values?.[0]?.name;
      const metalTypeSlug = metalTypeAttribute?.values?.[0]?.slug;
      const metalCaratAttribute = product?.attributes?.find(
        att => att.attribute?.slug === `${metalTypeSlug}-carat`
      );
      const metalCaratName = metalCaratAttribute?.values?.[0]?.name;
      const metalColorAttribute = product?.attributes?.find(
        att => att.attribute?.slug === `${metalTypeSlug}-color`
      )?.values?.[0]?.name;
      const ocassian = product?.attributes?.find(
        att => att.attribute?.slug === `ocassian`
      )?.values?.[0]?.name;
      const gender = product?.attributes?.find(
        att => att.attribute?.slug === `gender`
      )?.values?.[0]?.name;

      const availability = product?.attributes?.find(
        att => att.attribute?.slug === `availability`
      ).values?.[0]?.name;
      const type = product?.attributes?.find(
        att => att.attribute?.slug === `type`
      ).values?.[0]?.name;
      let minWeight;
      let maxWeight;
      if (availability?.toLowerCase() !== 'ready') {
        minWeight = product?.attributes?.find(
          att => att.attribute?.slug === `enter-min-weight-range`
        )?.values?.[0]?.name;
        maxWeight = product?.attributes?.find(
          att => att.attribute?.slug === `enter-max-weight-range`
        )?.values?.[0]?.name;
      } else if (
        availability?.toLowerCase() === 'ready' &&
        product?.defaultVariant !== null
      ) {
        minWeight = product?.defaultVariant?.attributes?.find(
          att => att.attribute?.slug === `net-weight`
        )?.values?.[0]?.name;
      }
      let categoryPricingPriceForMadeToOrder = 0;
      let gemstonePrices = 0;
      let gemstone = 'Non-Studded';
      if (!product?.pricingStatus && availability?.toLowerCase() !== 'ready') {
        let stoneType = 'none';
        if (type?.toLowerCase() === 'studded') {
          const gemStoneDetails = product?.attributes?.find(
            att => att.attribute?.slug === `gemstone-details-product`
          )?.values?.[0]?.name;
          if (gemStoneDetails) {
            let gem = JSON.parse(gemStoneDetails)?.[0];
            stoneType = gem?.name?.toLowerCase() || 'none';
            gemstone = gem?.name || 'Non-Studded';
            if (gem?.name?.toLowerCase() === 'diamond') {
              let metalPricea = storeDetails?.metalRates?.diamondRate;
              let gemPrices = metalPricea ? JSON.parse(metalPricea) : {};
              if (
                gemPrices[
                  `${gem['shape']},${gem['size']?.split('(')[0]},${gem['dg']}`
                ]
              ) {
                gemstonePrices =
                  parseFloat(
                    gemPrices[
                      `${gem['shape']},${gem['size']?.split('(')[0]},${gem['dg']}`
                    ] * gem?.tw
                  ).toFixed(2) || 0;
              }
            } else {
              gemstonePrices = parseFloat(gem?.tp).toFixed(2);
            }
          }
        }
        const matchedPricing = pricing && Array.isArray(pricing) ? pricing.find(
          price =>
            price?.carat === parseInt(metalCaratName) &&
            price?.stoneType?.toLowerCase() === stoneType &&
            price?.category?.id === parentCategoryId &&
            price?.subcategory?.id === subCategoryId &&
            price?.metalType === metalTypeName?.toLowerCase()
        ) : null;
        if (matchedPricing) {
          let metalPrice =
            storeDetails?.metalRates[
              `${metalTypeName?.toLowerCase()}${metalCaratName}kRate`
            ];
          categoryPricingPriceForMadeToOrder =
            metalPrice * parseFloat(minWeight);
          categoryPricingPriceForMadeToOrder += parseInt(gemstonePrices) || 0;
          let makingC =
            matchedPricing?.makingChargeMode?.toLowerCase() === 'percent'
              ? (categoryPricingPriceForMadeToOrder *
                  matchedPricing?.makingCharge) /
                100
              : matchedPricing?.makingChargeMode?.toLowerCase() === 'flat'
                ? matchedPricing?.makingCharge
                : matchedPricing?.makingCharge * parseFloat(minWeight);
          let wastageC =
            matchedPricing?.wastageChargeMode?.toLowerCase() === 'percent'
              ? (categoryPricingPriceForMadeToOrder *
                  matchedPricing?.wastageCharge) /
                100
              : matchedPricing?.wastageChargeMode?.toLowerCase() === 'flat'
                ? matchedPricing?.wastageCharge
                : matchedPricing?.wastageCharge * parseFloat(minWeight);
          categoryPricingPriceForMadeToOrder += makingC;
          categoryPricingPriceForMadeToOrder += wastageC;
          categoryPricingPriceForMadeToOrder +=
            categoryPricingPriceForMadeToOrder * 0.03;
        }
      }
      subCategoryObj?.products?.push({
        metalType: metalTypeName,
        metalCarat: metalCaratName,
        metalColor: metalColorAttribute,
        ocassian: ocassian,
        gender: gender,
        id: product.id,
        pricingStatus:
          categoryPricingPriceForMadeToOrder > 0
            ? true
            : product?.pricingStatus,
        netWeight: minWeight,
        name: product.name,
        img: product?.images?.slice(0, 2) || [],
        gemstone: gemstone,
        isDBPProduct: false,
        inStock: product?.productDisplayPrice ? true : false,
        displayPrice:
          categoryPricingPriceForMadeToOrder > 0
            ? parseInt(categoryPricingPriceForMadeToOrder)
            : product?.productDisplayPrice,
      });
    }
  }
  return {
    executeQuery,
    data: storeProducts,
    loading,
    error,
    refetch,
  };
};

export const useGetDBPInventoryProductsApi = ({ onLoad, searchParams }) => {
  const { executeQuery, data, loading, error, refetch } = useGetDBPInventory({
    onLoad,
    searchParams,
  });
  const DBPInventoryData = [];
  const pricing = useSelector(state => state.categoryPricing)?.pricing;
  const storeDetails = useSelector(state => state.storeData);
  if (data) {
    const productsData = data?.products?.edges || [];
    if (productsData?.length) {
      for (const edge of productsData) {
        const product = edge.node;

        console.log('product Line 119 :: ', product);
        const parentCategoryName = product.subcategory.parent.name;
        const parentCategoryId = product.subcategory.parent.id;
        const parentImageCategory = product.subcategory.backgroundImage?.url;
        const childImageCategory =
          product.subcategory?.parent?.backgroundImage?.url;
        const subCategoryName = product.subcategory.name;
        const subCategoryId = product.subcategory.id;

        let parentCategoryObj = DBPInventoryData.find(
          item => item.id === parentCategoryId
        );

        if (
          parentCategoryObj === undefined &&
          parentCategoryId &&
          parentCategoryName &&
          parentImageCategory
        ) {
          parentCategoryObj = {
            id: parentCategoryId,
            name: parentCategoryName,
            img: parentImageCategory,
            sub: [],
          };
          DBPInventoryData.push(parentCategoryObj);
        }

        let subCategoryObj = parentCategoryObj.sub.find(
          sub => sub.id === subCategoryId
        );

        if (
          subCategoryObj === undefined &&
          subCategoryId &&
          subCategoryName &&
          childImageCategory
        ) {
          subCategoryObj = {
            id: subCategoryId,
            name: subCategoryName,
            img: childImageCategory,
            products: [],
          };
          parentCategoryObj.sub.push(subCategoryObj);
        }
        const metalTypeAttribute = product?.attributes?.find(
          att => att.attribute?.slug === 'metal-type'
        );

        const metalTypeName = metalTypeAttribute?.values?.[0]?.name;
        const metalTypeSlug = metalTypeAttribute?.values?.[0]?.slug;
        const metalCaratAttribute = product?.attributes?.find(
          att => att.attribute?.slug === `${metalTypeSlug}-carat`
        );
        const metalColorAttribute = product?.attributes?.find(
          att => att.attribute?.slug === `${metalTypeSlug}-color`
        ).values?.[0]?.name;
        const ocassian = product?.attributes?.find(
          att => att.attribute?.slug === `ocassian`
        )?.values?.[0]?.name;
        const gender = product?.attributes?.find(
          att => att.attribute?.slug === `gender`
        )?.values?.[0]?.name;
        const metalCaratName = metalCaratAttribute?.values?.[0]?.name;
        const availability = product?.attributes?.find(
          att => att.attribute?.slug === `availability`
        ).values?.[0]?.name;
        const type = product?.attributes?.find(
          att => att.attribute?.slug === `type`
        ).values?.[0]?.name;
        let minWeight;
        if (availability?.toLowerCase() !== 'ready') {
          minWeight = product?.metadata?.find(
            att => att.key === 'net_weight'
          ).value;
        }
        let categoryPricingPriceForMadeToOrder = 0;
        let gemstonePrices = 0;
        let gemstone = 'Non-Studded';
        if (
          !product?.pricingStatus &&
          availability?.toLowerCase() !== 'ready'
        ) {
          console.log(product?.metadata);
          let stoneType = 'none';
          if (type?.toLowerCase() === 'studded') {
            const gemStoneDetails = product?.attributes?.find(
              att => att.attribute?.slug === `gemstone-details-product`
            )?.values?.[0]?.name;
            if (gemStoneDetails) {
              let gem = JSON.parse(gemStoneDetails)?.[0];
              stoneType = gem?.name?.toLowerCase() || 'none';
              gemstone = gem?.name || 'Non-Studded';
              if (gem?.name?.toLowerCase() === 'diamond') {
                let metalPricea = storeDetails?.metalRates?.diamondRate;
                let gemPrices = JSON.parse(metalPricea);
                if (
                  gemPrices[
                    `${gem['shape']},${gem['size'].split('(')[0]},${gem['dg']}`
                  ]
                ) {
                  gemstonePrices =
                    parseFloat(
                      gemPrices[
                        `${gem['shape']},${gem['size'].split('(')[0]},${gem['dg']}`
                      ] * gem?.tw
                    ).toFixed(2) || 0;
                }
              } else {
                gemstonePrices = parseFloat(gem?.tp).toFixed(2);
              }
            }
          }
          const matchedPricing = pricing && Array.isArray(pricing) ? pricing.find(
            price =>
              price?.carat === parseInt(metalCaratName) &&
              price?.stoneType?.toLowerCase() === stoneType &&
              price?.category?.id === parentCategoryId &&
              price?.subcategory?.id === subCategoryId &&
              price?.metalType === metalTypeName?.toLowerCase()
          ) : null;

          if (matchedPricing) {
            let metalPrice =
              storeDetails?.metalRates[
                `${metalTypeName?.toLowerCase()}${metalCaratName}kRate`
              ];
            categoryPricingPriceForMadeToOrder =
              metalPrice * parseFloat(minWeight);
            categoryPricingPriceForMadeToOrder += parseInt(gemstonePrices) || 0;
            let makingC =
              matchedPricing?.makingChargeMode?.toLowerCase() === 'percent'
                ? (categoryPricingPriceForMadeToOrder *
                    matchedPricing?.makingCharge) /
                  100
                : matchedPricing?.makingChargeMode?.toLowerCase() === 'flat'
                  ? matchedPricing?.makingCharge
                  : matchedPricing?.makingCharge * parseFloat(minWeight);
            let wastageC =
              matchedPricing?.wastageChargeMode?.toLowerCase() === 'percent'
                ? (categoryPricingPriceForMadeToOrder *
                    matchedPricing?.wastageCharge) /
                  100
                : matchedPricing?.wastageChargeMode?.toLowerCase() === 'flat'
                  ? matchedPricing?.wastageCharge
                  : matchedPricing?.wastageCharge * parseFloat(minWeight);
            categoryPricingPriceForMadeToOrder += makingC;
            categoryPricingPriceForMadeToOrder += wastageC;
            categoryPricingPriceForMadeToOrder +=
              categoryPricingPriceForMadeToOrder * 0.03;
          }
        }
        subCategoryObj.products.push({
          metalType: metalTypeName,
          metalCarat: metalCaratName,
          metalColor: metalColorAttribute,
          ocassian: ocassian,
          gender: gender,
          gemstone: gemstone,
          id: product.id,
          netWeight: product?.metadata?.find(key => key?.key === 'net_weight')
            ?.value,
          name: product.name,
          img: product?.images?.slice(0, 2) || [],
          isDBPProduct: true,
          inStock: product?.productDisplayPrice ? true : false,
          displayPrice: product?.productDisplayPrice,
          displayPrice:
            categoryPricingPriceForMadeToOrder > 0
              ? parseInt(categoryPricingPriceForMadeToOrder)
              : product?.productDisplayPrice,
          pricingStatus:
            categoryPricingPriceForMadeToOrder > 0
              ? true
              : product?.pricingStatus,
        });
      }
    }
  }

  return {
    executeQuery,
    data: DBPInventoryData,
    loading,
    error,
    refetch,
  };
};

export const useGetDBPInventoryApi = ({ onLoad }) => {
  const dispatch = useDispatch();

  const { executeQuery, data, loading, error, refetch } = useGetDBPInventory({
    onLoad,
  });
  const DBPInventoryData = [];
  let menuData = [];

  if (data) {
    const productsData = data?.products?.edges || [];
    if (productsData?.length) {
      for (const edge of productsData) {
        const product = edge.node;
        const parentCategoryName = product.subcategory.parent.name;
        const parentCategoryId = product.subcategory.parent.id;
        const parentImageCategory =
          product.subcategory?.parent?.backgroundImage?.url;
        const childImageCategory = product.subcategory?.backgroundImage?.url;
        const subCategoryName = product.subcategory.name;
        console.log('product Line 348', product);
        const subCategoryDescription = product?.subCategory?.seoDescription;
        const subCategoryId = product.subcategory.id;

        let parentCategoryObj = DBPInventoryData.find(
          item => item.id === parentCategoryId
        );

        if (!parentCategoryObj) {
          parentCategoryObj = {
            id: parentCategoryId,
            name: parentCategoryName,
            img: parentImageCategory,
            sub: [],
          };
          DBPInventoryData.push(parentCategoryObj);
        }

        let subCategoryObj = parentCategoryObj.sub.find(
          sub => sub.id === subCategoryId
        );

        if (!subCategoryObj) {
          subCategoryObj = {
            id: subCategoryId,
            name: subCategoryName,
            description: subCategoryDescription,
            img: childImageCategory,
            products: [],
          };
          parentCategoryObj.sub.push(subCategoryObj);
        }
        const metalTypeAttribute = product?.attributes?.find(
          att => att.attribute?.slug === 'metal-type'
        );

        const metalTypeName = metalTypeAttribute?.values?.[0]?.name;
        const metalTypeSlug = metalTypeAttribute?.values?.[0]?.slug;
        const metalCaratAttribute = product?.attributes?.find(
          att => att.attribute?.slug === `${metalTypeSlug}-carat`
        );
        const metalCaratName = metalCaratAttribute?.values?.[0]?.name;
        subCategoryObj.products.push({
          metalType: metalTypeName,
          metalCarat: metalCaratName,
          id: product.id,
          name: product.name,
          img: product?.images,
          isDBPProduct: true,
          inStock: product?.productDisplayPrice ? true : false,
          displayPrice: product?.productDisplayPrice,
        });
      }

      menuData = DBPInventoryData.map(({ id, name, img, sub }) => ({
        id,
        name: name,
        img,
        sub: sub.map(subCategory => ({
          id: subCategory.id,
          name: subCategory.name,
          description: subCategory?.seoDescription,
          img: subCategory.img,
        })),
      }));
    }
  }

  // Follow this practice while dispatch any data from api to avoid unnecessary re-render and state-updates
  useEffect(() => {
    if (data) {
      dispatch(setMenuData({ data: menuData, type: 'mainStore' }));
    }
  }, [data, menuData, dispatch]);

  return {
    executeQuery,
    data: DBPInventoryData,
    loading,
    error,
    refetch,
  };
};

export const useAllB2BProductsApi = ({ id }) => {
  const { executeQuery, data, loading, error, refetch } = useAllB2BProducts({
    id,
  });

  return {
    executeQuery,
    data,
    loading,
    error,
    refetch,
  };
};
export const useGetAllCategoriesApi = ({ onLoad, productLimit }) => {
  const dispatch = useDispatch();

  const { executeQuery, data, loading, error, refetch } = useGetAllCategories({
    onLoad,
    productLimit,
  });
  const menuData = [];
  const productCollections = [];

  if (data) {
    const categories = data.categories?.edges;

    for (const edge of categories || []) {
      const parentCategory = edge.node;
      const childCategories = parentCategory.children?.edges;
      const backgroundImage = parentCategory.backgroundImage?.url;

      let subCategories = [];
      let subCategoryProducts = [];

      for (const childEdge of childCategories || []) {
        const childCategory = childEdge.node;

        if (
          childCategory?.level === 1 &&
          childCategory?.products?.totalCount > 0
        ) {
          console.log('childCategory Line 446', childCategory);
          subCategories.push({
            id: childCategory.id,
            name: childCategory.name,
            description: childCategory.seoDescription,
            img: childCategory.backgroundImage?.url,
          });

          const products =
            childCategory.products?.edges.map(productEdge => ({
              id: productEdge.node?.id,
              name: productEdge.node?.name,
              images: productEdge.node?.images?.map(image => image.url) || [],
            })) || [];

          subCategoryProducts.push({
            name: childCategory.name,
            products: products,
          });
        }
      }

      if (subCategories.length > 0) {
        menuData.push({
          id: parentCategory.id,
          name: parentCategory.name,
          img: backgroundImage,
          sub: subCategories,
        });
      }

      if (subCategoryProducts.length > 0) {
        productCollections.push({
          id: parentCategory.id,
          name: parentCategory.name,
          img: backgroundImage,
          sub: subCategoryProducts,
        });
      }
    }
  }

  // Follow this practice while dispatch any data from api to avoid unnecessary re-render and state-updates
  useEffect(() => {
    if (data) {
      dispatch(setMenuData({ data: menuData, type: 'localStore' }));
    }
  }, [data, menuData, dispatch]);

  return {
    executeQuery,
    data: productCollections,
    loading,
    error,
    refetch,
  };
};

export const useFAQsApi = ({ onLoad }) => {
  const { executeQuery, data, loading, error, refetch } = useFAQs({ onLoad });

  return {
    executeQuery,
    data,
    loading,
    error,
    refetch,
  };
};

export const useGetUserDetailsData = ({ onLoad }) => {
  const { executeQuery, data, loading, error, refetch } = useGetUserDetails({
    onLoad,
  });

  return {
    executeQuery,
    data: data?.me,
    loading,
    error,
    refetch,
  };
};

export const useStoreDataApi = ({ onLoad }) => {
  const dispatch = useDispatch();
  const { executeQuery, data, loading, error, refetch } = useStoreData({
    onLoad,
  });
  const { data: themeData } = useThemeColorData({ onLoad });
  const saveVisitorData = useTrackVisitor();
  let storeData = data?.storeDetails || null;
  let themeColors = themeData?.themeColors || null;
  if (storeData && themeColors) {
    storeData = {
      id: storeData?.id,
      domain: storeData?.domain,
      name: storeData?.name,
      storeLogo: storeData?.storeLogo,
      storePhoneNumber: storeData?.storePhoneNumber,
      user: storeData?.user,
      userId: storeData?.userId,
      footer: storeData?.user?.note,
      metalRates: storeData?.metalRates,
      themeColors,
    };
    saveVisitorData(storeData?.domain);
  }

  // Follow this practice while dispatch any data from api to avoid unnecessary re-render and state-updates
  useEffect(() => {
    if (data) {
      dispatch(setStoreData(storeData));
    }
  }, [data, storeData, dispatch]);
  return {
    executeQuery,
    data: storeData,
    loading,
    error,
    refetch,
  };
};

export const useCreateTokenApi = ({ isToken }) => {
  let { executeQuery: createToken, data, loading, error } = useCreateToken();

  useEffect(() => {
    if (!data && !isToken) {
      createToken();
    }
  }, [data, isToken, createToken]);
  data = data ? data?.tokenCreate : null;

  return { data, error, loading };
};

export const useProductDetailsByIdApi = ({ id, isDBP, onLoad }) => {
  const { executeQuery, data, loading, error, refetch } = useProductDetails({
    id,
    isDBP,
    onLoad,
  });
  const { response: marqueeData } = useMarqueeApi({ onLoad: true });
  let productDetails = {};
  if (data && marqueeData) {
    let metalRates = marqueeData?.storeDetails?.metalRates;
    // console.log("marqueeData",metalRates)
    const metalTypeAttribute = data?.product?.attributes?.find(
      att => att.attribute?.slug === 'metal-type'
    );
    const metalTypeName = metalTypeAttribute?.values?.[0]?.name;
    const metalTypeSlug = metalTypeAttribute?.values?.[0]?.slug;

    const availability = data?.product?.attributes?.find(
      att => att.attribute?.slug === 'availability'
    )?.values?.[0]?.name;

    const type = data?.product?.attributes?.find(
      att => att.attribute?.slug === 'type'
    )?.values?.[0]?.name;

    const metalColor = data?.product?.attributes?.find(
      att => att.attribute?.slug === `${metalTypeSlug}-color`
    )?.values[0]?.name;

    const productHeight = data?.product?.attributes?.find(
      att => att.attribute?.slug === `product-height`
    )?.values[0]?.name;

    const productWidth = data?.product?.attributes?.find(
      att => att.attribute?.slug === `product-width`
    )?.values[0]?.name;
    const metalCaratAttribute = data?.product?.attributes?.find(
      att => att.attribute?.slug === `${metalTypeSlug}-carat`
    );
    const metalCaratName = metalCaratAttribute?.values?.[0]?.name;
    // let productMetalRates = metalRates[`${metalTypeSlug}${metalCaratName}kRate`];
    // console.log("Metal price :: ",productMetalRates)
    let variantsDetails = [];
    const productVariants = data?.product?.variants;
    for (let variant in productVariants) {
      let varDetails = {};
      varDetails.productId = data?.product?.id;
      varDetails.variantId = productVariants[variant]?.id;
      varDetails.metalColor = metalColor;
      varDetails.type = type;
      varDetails.priceBreakdown = productVariants[variant].priceBreakdown
        ?.replaceAll('" -', '# -')
        .replaceAll("'", '"')
        .replaceAll('# -', "' -");
      varDetails.priceBreakdown = varDetails.priceBreakdown
        ? JSON.parse(varDetails.priceBreakdown)
        : {};
      varDetails.availability = availability;
      varDetails.metalTypeName = metalTypeName;
      varDetails.metalCarat = metalCaratName;
      varDetails.metalRates = metalRates;
      varDetails.price = data?.product?.productDisplayPrice;
      for (let attribute in productVariants[variant]?.attributes) {
        if (
          productVariants[variant]?.attributes[attribute].attribute.name ===
          'Size'
        ) {
          varDetails.size =
            productVariants[variant]?.attributes[attribute].values[0].name;
        }
        if (
          productVariants[variant]?.attributes[attribute].attribute.slug ===
          'net-weight'
        ) {
          varDetails.netWeight =
            productVariants[variant]?.attributes[attribute].values[0].name;
        }
        if (
          productVariants[variant]?.attributes[attribute].attribute.slug ===
          'gross-weight'
        ) {
          varDetails.grossWeight =
            productVariants[variant]?.attributes[attribute].values[0].name;
        }
        if (
          productVariants[variant]?.attributes[attribute].attribute.slug ===
          'gemstone-details'
        ) {
          varDetails.gemstoneDetails =
            productVariants[variant]?.attributes[attribute].values[0].name;
        }
        if (
          productVariants[variant]?.attributes[attribute].attribute.slug ===
          'total-gemstone-price'
        ) {
          varDetails.totalGemstonePrice =
            productVariants[variant]?.attributes[attribute].values[0].name;
        }
        if (
          productVariants[variant]?.attributes[attribute].attribute.slug ===
          'total-gemstone-weight'
        ) {
          varDetails.totalGemstoneWeight =
            productVariants[variant]?.attributes[attribute].values[0].name;
        }
      }
      variantsDetails.push(varDetails);
    }
    productDetails.parentCategoryId = data?.product?.category?.parent?.id;
    productDetails.parentCategoryName = data?.product?.category?.parent?.name;
    productDetails.childCategoryName = data?.product?.category?.name;
    productDetails.childCategoryId = data?.product?.category?.id;
    productDetails.id = data?.product?.id;
    productDetails.isDBP = isDBP;
    productDetails.pricingStatus = data?.product?.pricingStatus;
    productDetails.makingDays = data?.product?.makingDays;
    productDetails.productHeight = productHeight;
    productDetails.productWidth = productWidth;
    productDetails.priceBreakdown = data?.product?.priceBreakdown
      ?.replaceAll('" -', '# -')
      .replaceAll("'", '"')
      .replaceAll('# -', "' -");
    productDetails.priceBreakdown = productDetails.priceBreakdown
      ? JSON.parse(productDetails.priceBreakdown)
      : {};
    productDetails.name = data?.product?.name;
    productDetails.images = data?.product?.images;
    productDetails.price = data?.product?.productDisplayPrice;
    productDetails.availability = availability;
    productDetails.metalColor = metalColor;
    productDetails.metalType = metalTypeName;
    productDetails.carat = metalCaratName;
    productDetails.type = type;
    productDetails.metalRates = metalRates;
    productDetails.productVariant = variantsDetails;
    //Child Product
    let childProductVariantsDetails = [];
    for (let childProduct in data?.product?.childProduct) {
      const metalTypeAttribute = data?.product?.childProduct[
        childProduct
      ]?.attributes?.find(att => att.attribute?.slug === 'metal-type');
      const metalTypeName = metalTypeAttribute?.values?.[0]?.name;
      const metalTypeSlug = metalTypeAttribute?.values?.[0]?.slug;

      const availability = data?.product?.childProduct[
        childProduct
      ]?.attributes?.find(att => att.attribute?.slug === 'availability')
        ?.values?.[0]?.name;

      const type = data?.product?.childProduct[childProduct]?.attributes?.find(
        att => att.attribute?.slug === 'type'
      )?.values?.[0]?.name;
      const metalColor = data?.product?.childProduct[
        childProduct
      ]?.attributes?.find(
        att => att.attribute?.slug === `${metalTypeSlug}-color`
      )?.values[0]?.name;
      let childProductVariants =
        data?.product?.childProduct[childProduct]?.variants;
      const metalCaratAttribute = data?.product?.childProduct[
        childProduct
      ]?.attributes?.find(
        att => att.attribute?.slug === `${metalTypeSlug}-carat`
      );
      const metalCaratName = metalCaratAttribute?.values?.[0]?.name;
      for (let variant in childProductVariants) {
        let varDetails = {};
        varDetails.productId = data?.product?.childProduct[childProduct]?.id;
        varDetails.variantId = childProductVariants[variant]?.id;
        varDetails.metalColor = metalColor;
        varDetails.type = type;
        varDetails.metalRates = metalRates;
        varDetails.priceBreakdown = JSON.parse(
          data?.product?.childProduct[childProduct]?.priceBreakdown
            ?.replaceAll('" -', '# -')
            .replaceAll("'", '"')
            .replaceAll('# -', "' -")
        );
        varDetails.availability = availability;
        varDetails.metalTypeName = metalTypeName;
        varDetails.metalCarat = metalCaratName;
        varDetails.price =
          data?.product?.childProduct[childProduct]?.productDisplayPrice;
        for (let attribute in childProductVariants[variant]?.attributes) {
          if (
            childProductVariants[variant]?.attributes[attribute].attribute
              .name === 'Size'
          ) {
            varDetails.size =
              childProductVariants[variant]?.attributes[
                attribute
              ].values[0].name;
          }
          if (
            childProductVariants[variant]?.attributes[attribute].attribute
              .slug === 'net-weight'
          ) {
            varDetails.netWeight =
              childProductVariants[variant]?.attributes[
                attribute
              ].values[0].name;
          }
          if (
            childProductVariants[variant]?.attributes[attribute].attribute
              .slug === 'gross-weight'
          ) {
            varDetails.grossWeight =
              childProductVariants[variant]?.attributes[
                attribute
              ].values[0].name;
          }
          if (
            childProductVariants[variant]?.attributes[attribute].attribute
              .slug === 'gemstone-details'
          ) {
            varDetails.gemstoneDetails =
              childProductVariants[variant]?.attributes[
                attribute
              ].values[0].name;
          }
          if (
            childProductVariants[variant]?.attributes[attribute].attribute
              .slug === 'total-gemstone-price'
          ) {
            varDetails.totalGemstonePrice =
              childProductVariants[variant]?.attributes[
                attribute
              ].values[0].name;
          }
          if (
            childProductVariants[variant]?.attributes[attribute].attribute
              .slug === 'total-gemstone-weight'
          ) {
            varDetails.totalGemstoneWeight =
              childProductVariants[variant]?.attributes[
                attribute
              ].values[0].name;
          }
        }
        childProductVariantsDetails.push(varDetails);
      }
    }
    productDetails.variants = childProductVariantsDetails;
    productDetails.allVariants = [
      ...productDetails.productVariant,
      ...productDetails.variants,
    ];
  }
  return {
    executeQuery,
    data: productDetails,
    loading,
    error,
    refetch,
  };
};
export const useGetCategoryPricing = ({
  categoryId,
  subcategoryId,
  metalType,
  carat,
  stoneType,
}) => {
  const onLoad = true;

  // Always call useCategoryPricing
  const { executeQuery, data, loading, error, refetch } = useCategoryPricing({
    categoryId,
    subcategoryId,
    metalType,
    carat,
    stoneType,
    onLoad,
  });

  // Ensure we return a safe default if data is null
  return {
    executeQuery,
    data: data || { categoryPricing: [] },
    loading,
    error,
    refetch,
  };
};

export const useGetCategoryPricingByCatSubCat = ({
  categoryId,
  subcategoryId,
}) => {
  const onLoad = true;

  const { executeQuery, data, loading, error, refetch } = useCategoryPricing({
    categoryId,
    subcategoryId,
    onLoad,
  });

  // console.log("useGetCategoryPricingByCatSubCat data", data);

  return {
    executeQuery,
    data: data || { categoryPricing: [] },
    loading,
    error,
    refetch,
  };
};

export const useGetCategoryPricingDataApi = ({ onLoad }) => {
  const dispatch = useDispatch();
  const { executeQuery, data, loading, error, refetch } =
    useGetAllCategoryPricing({ onLoad });
  // console.log("data of useGetCategoryPricingDataApi",data?.getAllCategoryPrices)
  let categoryPricing = data?.getAllCategoryPrices || [];

  // // Follow this practice while dispatch any data from api to avoid unnecessary re-render and state-updates
  useEffect(() => {
    if (data) {
      dispatch(setPricing(categoryPricing));
    }
  }, [data, dispatch, categoryPricing]);

  return {
    executeQuery,
    data: data || { getAllCategoryPrices: [] },
    loading,
    error,
    refetch,
  };
};
// ##########################################################################################
//                                    COLOR USAGE
// ##########################################################################################
// ###### Primary color ######
// All Page - Site Top Marquee
// Home Page - Brand USP Base
// All Page - Footer
// Multi-Product Page - Product Title Base
// Cart Section - All CTAs in Cart Section (Mobile & Desktop)

// ###### Secondary color ######
// All Page - Text Highlight Color
// All Page - Alt. Header Base Color
// All Page - General Button Boundary / Stroke Color

// ###### CTA Primary ######
// Product Page - Add to Cart / Request Price CTA
// Product Page - Customize Design Main CTA
// Product Page - Customize Design Popup Confirmation CTA
// Multi-Product Page - Apply Filters CTA

// ###### Shade 1 ######
// Multi-Product Page - Product Filter Section (in b/w product rows)
// Product Page - Selected Size / Material / Metal in Customization Popup
// Multi-Product Page - Sort Popup for Mobile - Title Bar BG

// ###### Shade 2 ######
// Product Page - Non-Selected Size / Material / Metal in Customization Popup

// ###### Shade 3 ######
// Product Page - Price Breakup Popup Title BG
// Product Page - Customization Popup with Estimated Price Title Bar BG

export const useGetBackgroundColor = () => {
  const storeDetails = useSelector(state => state.storeData);
  let data = {
    color: storeDetails?.themeColors?.find(
      color =>
        color.colorName?.toLowerCase() ===
        storeDetails?.user?.brandColor?.toLowerCase()
    ),
    font: storeDetails?.user?.brandFont,
  };
  if (!data?.color) {
    data.color = {
      colorName: 'Navy Blue',
      primaryColor: '#111579',
      secondaryColor: '#1A2299',
      ctaPrimary: '#326BFF',
      shade1: '#6DA5E8',
      shade2: '#C2DEFF',
      shade3: '#D9E6F1',
    };
  }
  return { data };
};

export const useTrackVisitor = () => {
  const callTrackVisitor = async domain => {
    try {
      try {
        const ipResponse = await fetch('https://api64.ipify.org?format=json');
        const { ip } = await ipResponse.json();
        const userAgent = navigator.userAgent;
        let prefix = 'https://';
        if (process.env.NODE_ENV === 'development') {
          prefix = 'http://';
        }
        let url = prefix + '' + domain + '/graphql/';

        // Send visitor data to Django GraphQL mutation
        await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: `
                  mutation {
                    saveVisitor(ipAddress: "${ip}", userAgent: "${userAgent}") {
                      visitor {
                        ipAddress
                        visitedAt
                      }
                    }
                  }
                `,
          }),
        });
      } catch (error) {
        console.error('Error tracking visitor:', error);
      }
    } catch (error) {
      console.error('Error 858:', error);
      throw error;
    }
  };
  return callTrackVisitor;
};
