import React, { useState } from 'react';
import './checkout.css';
import { useGetBackgroundColor } from '../../../src/api/index';
import { useSelector } from 'react-redux';

const ConfirmationModal = ({ address, phoneNumber, onEdit, onConfirm, close }) => {
  const { data } = useGetBackgroundColor({ onLoad: true });
  const [confirmModel, setConfirmModel] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [popupUrl, setPopupUrl] = useState('');
  const storeData = useSelector(state => state.storeData);

  // Function to open the popup
  const openPopup = (url) => {
    setPopupUrl(url);
    setShowPopup(true);
  };
  // Function to close the popup
  const closePopup = () => {
    setShowPopup(false);
    setPopupUrl('');
  };
  const Popup = ({ url, onClose }) => {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="relative bg-white rounded-lg w-full max-w-4xl mx-4 h-4/5">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          >
            ✕
          </button>

          {/* Iframe */}
          <iframe
            src={url}
            className="w-full h-full rounded-lg mt-5 cursor-pointer overflow-hidden"
            title="Popup Content"
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
    );
  };
  return (
    <div
      class='relative z-10'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div>{showPopup && <Popup url={popupUrl} onClose={closePopup} />}</div>
      <div className={`fixed inset-0 flex  justify-center items-center bg-[#393838b3] bg-opacity-50`}>
      <div className={`sm:${confirmModel ?  'mx-[8%]' : 'mx-[0%]'} sm:m-3`}>
      <div className={`confirmation-model w-full max-w-md shadow-lg`}>
          {confirmModel && (
            <>
              <div className='header flex flex-col items-center p-4 text-center' style={{ backgroundColor: data.color.shade1 }}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='19'
                  height='19'
                  viewBox='0 0 19 19'
                  fill='none'
                >
                  <path
                    d='M9.49967 18.0104C4.80509 18.0104 0.989258 14.1946 0.989258 9.5C0.989258 4.80542 4.80509 0.989586 9.49967 0.989586C14.1943 0.989586 18.0101 4.80542 18.0101 9.5C18.0101 14.1946 14.1943 18.0104 9.49967 18.0104ZM9.49967 2.17709C5.46217 2.17709 2.17676 5.4625 2.17676 9.5C2.17676 13.5375 5.46217 16.8229 9.49967 16.8229C13.5372 16.8229 16.8226 13.5375 16.8226 9.5C16.8226 5.4625 13.5372 2.17709 9.49967 2.17709Z'
                    fill='#292D32'
                  />
                  <path
                    d='M9.5 10.8854C9.17542 10.8854 8.90625 10.6162 8.90625 10.2917V6.33333C8.90625 6.00874 9.17542 5.73958 9.5 5.73958C9.82458 5.73958 10.0938 6.00874 10.0938 6.33333V10.2917C10.0938 10.6162 9.82458 10.8854 9.5 10.8854Z'
                    fill='#292D32'
                  />
                  <path
                    d='M9.49967 13.4583C9.39676 13.4583 9.29384 13.4346 9.19884 13.395C9.10384 13.3554 9.01676 13.3 8.93759 13.2287C8.86634 13.1496 8.81092 13.0704 8.77134 12.9675C8.73176 12.8725 8.70801 12.7696 8.70801 12.6667C8.70801 12.5637 8.73176 12.4608 8.77134 12.3658C8.81092 12.2708 8.86634 12.1837 8.93759 12.1046C9.01676 12.0333 9.10384 11.9779 9.19884 11.9383C9.38884 11.8592 9.61051 11.8592 9.80051 11.9383C9.89551 11.9779 9.98259 12.0333 10.0618 12.1046C10.133 12.1837 10.1884 12.2708 10.228 12.3658C10.2676 12.4608 10.2913 12.5637 10.2913 12.6667C10.2913 12.7696 10.2676 12.8725 10.228 12.9675C10.1884 13.0704 10.133 13.1496 10.0618 13.2287C9.98259 13.3 9.89551 13.3554 9.80051 13.395C9.70551 13.4346 9.60259 13.4583 9.49967 13.4583Z'
                    fill='#292D32'
                  />
                </svg>
                <h2 className='mt-2 text-lg font-semibold text-blue-900'>
                  Confirmation Required
                </h2>
              </div>

              {/* Body */}
              <div className='p-6'>
                <p className='description mb-4 text-center text-[13px]'>
                  Our delivery agent will call this number at the time of
                  delivery. Please confirm your address and phone number.
                </p>

                {/* Shipping Address */}
                <div
                  className='details mb-6 p-4'
                  style={{ backgroundColor: data.color.shade1, color : 'black' }}
                >
                  <p className='text-xs font-semibold'>Shipping Address :</p>
                  <p className='mt-2 text-xs'>{address}</p>

                  <hr className='my-4 border-white' />

                  <p className='text-xs font-semibold'>Contact Number :</p>
                  <p className='mt-2 text-xs'>{phoneNumber}</p>
                </div>

                {/* Buttons */}
                <div className='flex justify-between'>
                  {/* Edit Button */}
                  <button
                    onClick={onEdit}
                    className='edit-btn w-full bg-white px-6 py-2 text-sm font-semibold'
                  >
                    EDIT
                  </button>
                  <div className='mx-4'></div>
                  {/* Confirm Button */}
                  <button
                    onClick={() => setConfirmModel(false)}
                    className='checkout-btn conform-btn w-full px-6 py-2 text-sm font-semibold text-white'
                    style={{ backgroundColor: data.color.primaryColor }}
                  >
                    CONFIRM
                  </button>
                </div>
              </div>
            </>
          )}
          {!confirmModel && (
            <>
              <div className={`confirmation-model w-full max-w-md shadow-lg sm:${confirmModel ?  'mx-[5%]' : 'mx-[0%]'}`}>
                <div className='rounded-lg bg-white p-6 shadow-lg'>
                <div className='relative'>
                     <button type="button" className="text-black font-bold text-2xl focus:outline-none absolute right-2" onClick={() => {close()}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <path d="M17.3125 17.3125L9.5 9.5M9.5 9.5L1.6875 1.6875M9.5 9.5L17.3125 1.6875M9.5 9.5L1.6875 17.3125" stroke="black" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    </button>
                    </div>
                  <div className='mb-4 flex justify-center'>
                    <img
                      src={storeData?.storeLogo}
                      alt='logo'
                      style={{width: '100px', height: '100px'}}
                    />
                  </div>
                  <h2 className='mb-5 text-center text-[18px] font-extrabold'>
                    This Order Will Be Delivered By
                    <br /> {storeData.name?.replace(","," ")}
                  </h2>
                  <p className='mb-6 text-center text-[16px] text-[#434343] font-medium'>
                  {storeData.name} would need your name, phone number,
                    and address that you’ve provided to deliver this order. No
                    other personal information is saved or shared.
                  </p>
                  <p className='mb-6 text-center text-[16px]'>
                    For further details, check out{' '}
                    <a className='underline text-bold cursor-pointer' onClick={()=>openPopup('https://goldclub.co/terms.html')}>
                      Terms and Conditions
                    </a>
                  </p>
                  <button
                    onClick={onConfirm}
                    className='w-full rounded-md py-2 font-semibold text-white hover:bg-red-700'
                    style={{ backgroundColor: data.color.primaryColor }}
                  >
                    I Understand, Continue
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
