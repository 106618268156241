import { useState, useEffect, useCallback } from 'react';
import { useLazyQuery as useApolloLazyQuery } from '@apollo/client';
import { getTenantDomain, getTenantDomainWithPort } from '../config/env';

/**
 * Custom useLazyQuery Hook
 * @param {DocumentNode} query - GraphQL query document
 * @param {object} options - Apollo client query options
 * @returns {object} { data, loading, error, executeQuery, refetch }
 */

const useLazyQuery = (query, options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Operations that need domain without port
  const DOMAIN_WITHOUT_PORT_OPERATIONS = [
    'storeDetails',
    'publishedBanners',
    'getDesignBankProducts',
    'B2BProductDetails',
    'getB2BDBCategories',
    'dbpProducts',
    'themeColors',
  ];

  // Operations that should use cache-first policy
  const CACHE_FIRST_OPERATIONS = [
    'storeDetails',
    'themeColors',
    'getCategory',
    'getDBPIdsWithAutoInventory',
    'dbpProducts',
    'allCategoryPricing',
    'publishedBanners',
    'getFAQs',
    'allProductsData',
    'collections'
  ];

  // Operations that must always use network-only policy
  const NETWORK_ONLY_OPERATIONS = [
    'B2BProductDetails'
  ];

  // Get operation name from the query definition
  const queryDefinition = query.definitions[0];
  const operationName = queryDefinition.name?.value;

  // Determine the appropriate fetch policy
  let fetchPolicy = 'network-only'; // Default to network-only
  if (options.fetchPolicy) {
    // If explicitly provided in options, use that
    fetchPolicy = options.fetchPolicy;
  } else if (NETWORK_ONLY_OPERATIONS.includes(operationName)) {
    // If it's in our list of network-only operations, force network-only
    fetchPolicy = 'network-only';
  } else if (CACHE_FIRST_OPERATIONS.includes(operationName)) {
    // If it's in our list of cache-first operations, use cache-first
    fetchPolicy = 'cache-first';
  }

  const variables = !options.variables
    ? {
        variables: {
          domain: DOMAIN_WITHOUT_PORT_OPERATIONS.includes(operationName)
            ? getTenantDomain()
            : getTenantDomainWithPort(),
        },
      }
    : '';

  const [execute, { called, refetch }] = useApolloLazyQuery(query, {
    ...options,
    ...variables,
    onCompleted: responseData => {
      if (!cancelled) {
        setData(responseData);
        setLoading(false);
        setError(null);

        if (options.onCompleted) {
          options.onCompleted(responseData);
        }
      }
    },
    onError: err => {
      if (!cancelled) {
        setError(err);
        setLoading(false);

        if (options.onError) {
          options.onError(err);
        }
      }
    },
    fetchPolicy: fetchPolicy,
  });

  const [cancelled, setCancelled] = useState(false);

  const executeQuery = useCallback(
    variables => {
      setLoading(true);
      execute({ variables });
    },
    [execute]
  );

  useEffect(() => {
    setCancelled(false);

    if (options.onLoad) {
      executeQuery();
    }

    return () => {
      setCancelled(true);
    };
  }, []);

  useEffect(() => {
    if (called && options.refetchOnMount) {
      refetch();
    }
  }, [called, refetch, options.refetchOnMount]);

  return {
    data,
    loading,
    error,
    executeQuery,
    refetch,
  };
};

export default useLazyQuery;
