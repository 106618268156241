import { useLazyQuery } from "hooks";
import { GET_CATEGORY_PRICING, GET_CATEGORY_PRICING_BY_CAT_SUBCAT,GET_ALL_CATEGORY_PRICING } from "graphql/query";

export const useCategoryPricing = ({ categoryId,subcategoryId,metalType,carat,stoneType, onLoad }) => {
  const {executeQuery, data, loading, error, refetch } = useLazyQuery(
    GET_CATEGORY_PRICING,
    {
      variables: {
        categoryId: categoryId,
        subcategoryId:subcategoryId,
        metalType: metalType,
        carat:carat,
        stoneType:stoneType
      },
      fetchPolicy: "network-only",
      onLoad
    },
    onLoad
  );
//   executeQuery();
  if(!data){
      console.log("Data of Product Details", data);
  }

  return {
    executeQuery,
    data,
    loading,
    error,
    refetch,
  };
};


export const useCategoryPricingByCatSubCat = ({ categoryId,subcategoryId, onLoad }) => {
  const {executeQuery, data, loading, error, refetch } = useLazyQuery(
    GET_CATEGORY_PRICING_BY_CAT_SUBCAT,
    {
      variables: {
        categoryId: categoryId,
        subcategoryId:subcategoryId
      },
      fetchPolicy: "network-only",
      onLoad
    },
    onLoad
  );
  if(!data){
      console.log("Data of Product Details", data);
  }

  return {
    executeQuery,
    data,
    loading,
    error,
    refetch,
  };
};


export const useGetAllCategoryPricing = ({ onLoad }) => {
  const {executeQuery, data, loading, error, refetch } = useLazyQuery(
    GET_ALL_CATEGORY_PRICING,
    {
      variables: {},
      fetchPolicy: "network-only",
      onLoad
    },
    onLoad
  );
  if(!data){
      console.log("Data of Category Pricing Details", data);
  }

  return {
    executeQuery,
    data,
    loading,
    error,
    refetch,
  };
};
