import * as React from 'react';

const Hamburger = props => (
  <svg
    width={27}
    height={23}
    viewBox='0 0 27 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width={26.0667} height={4.6} rx={2} fill='#FFFFFF' />
    <rect y={18.4} width={26.0667} height={4.6} rx={2} fill='#FFFFFF' />
    <rect y={9.19999} width={19.9333} height={4.6} rx={2} fill='#FFFFFF' />
  </svg>
);
export default Hamburger;
