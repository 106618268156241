import React, { useCallback, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const BannerSwiper = ({ images }) => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    sliderRef.current?.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    sliderRef.current?.swiper.slideNext();
  }, []);

  return (
    <section className='relative'>
      <div className='relative'>
        <Swiper
          ref={sliderRef}
          className='extra_swiper'
          modules={[Pagination, Autoplay, EffectFade]}
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          // navigation={{
          //   nextEl: '.swiper-button-next',
          //   prevEl: '.swiper-button-prev',
          // }}
          pagination={{
            el: '.swiper-pagination',
            dynamicBullets: false,
            clickable: true,
          }}
          speed={2000}
        >
          {images?.map((imgUrl, index) => (
            <SwiperSlide key={index}>
              {imgUrl?.ctaUrl ?
              <a href={imgUrl?.ctaUrl} target="_blank" rel="noopener noreferrer">
                <img src={imgUrl?.image} alt={`Slide ${index}`} className='w-full sm:h-[190px] h-[520px]' />
                </a>:
                <img src={imgUrl?.image} alt={`Slide ${index}`} className='w-full sm:h-[190px] h-[520px]' />
              }
            </SwiperSlide>
          ))}
        </Swiper>

        {/* <div
          className='prev-arrow absolute left-16 top-1/2 z-10 hidden h-[35px] w-[35px] -translate-y-1/2 cursor-pointer items-center justify-center rounded-full border border-white bg-transparent md:flex'
          onClick={handlePrev}
        >
          <FontAwesomeIcon icon={faChevronLeft} className='text-white' />
        </div> */}
        {/* <div
          className='next-arrow absolute right-16 top-1/2 z-10 hidden h-[35px] w-[35px] -translate-y-1/2 cursor-pointer items-center justify-center rounded-full border border-white bg-transparent md:flex'
          onClick={handleNext}
        >
          <FontAwesomeIcon icon={faChevronRight} className='text-white' />
        </div> */}
      </div>
      {/* <div className='swiper-pagination'></div> */}
    </section>
  );
};

export default BannerSwiper;
