
import { useLazyQuery } from "hooks";
import { GET_STORE_DETAILS, GET_THEME_COLORS } from "graphql/query";
import { generateDynamicManifest } from '../functions/index';

export const useStoreData = (onLoad) => {
    const { data, loading, error, executeQuery, refetch, } = useLazyQuery(GET_STORE_DETAILS, { onLoad });
    generateDynamicManifest(data?.storeDetails?.name, data?.storeDetails?.name, data?.storeDetails?.user?.phoneNumber, data?.storeDetails?.storeLogo)
    return {
        executeQuery,
        data,
        loading,
        error,
        refetch,
    };
};

export const useThemeColorData = (onLoad) => {
    const { data, loading, error, executeQuery, refetch, } = useLazyQuery(GET_THEME_COLORS, { onLoad });
    
    return {
        executeQuery,
        data,
        loading,
        error,
        refetch,
    };
};