import { useLazyQuery } from "hooks";
import { GET_PRODUCT_BY_ID, GET_B2B_PRODUCT_BY_ID } from "graphql/query";

export const useProductDetails = ({ id, isDBP,onLoad }) => {
  const {executeQuery, data, loading, error, refetch } = useLazyQuery(
    isDBP === true ? GET_B2B_PRODUCT_BY_ID : GET_PRODUCT_BY_ID,
    {
      variables: {
        id: id,
        countryCode: "IN",
      },
      fetchPolicy: "no-cache",
      onLoad
    },
    onLoad
  );
//   executeQuery();
  if(!data){
      console.log("Data of Product Details", data);
  }

  return {
    executeQuery,
    data,
    loading,
    error,
    refetch,
  };
};
