
const ShareToWhatsApp = (productDetails,storeData, price) => {
    const encodedMessage = encodeURIComponent(`Hi ${storeData?.name?.replace(","," ")}, I’m interested in the ${productDetails?.name} ${productDetails?.price>0 ? 'priced at ₹'+parseInt(productDetails?.price) : ''} ${productDetails?.priceBreakdown?.netWeight ? 'and Weighing '+productDetails?.priceBreakdown?.netWeight+'gm' : ''}. Link: ${window.location.href}.`);
    const whatsappURL = `https://wa.me/${storeData?.storePhoneNumber}?text=${encodedMessage}`;

    // Open the WhatsApp share URL in a new tab
    window.open(whatsappURL, '_blank');
  };

export default ShareToWhatsApp;
