import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetBackgroundColor } from "../../../src/api/index";
import { useResponsive } from 'hooks';

const SizeGuide = ({ringSizeModelClose, type, diamondGuideModel}) => {
  const storeData = useSelector(state => state.storeData);
  const { data } = useGetBackgroundColor({ onLoad: true });
  const [gender, setGender] = useState('male');
  const { isDesktop } = useResponsive();
  const ringSizes = [
    { size: 6, circumference: '159.6(mm)' },
    { size: 7, circumference: '164.6(mm)' },
    { size: 8, circumference: '169.6(mm)' },
    { size: 9, circumference: '174.7(mm)' },
    { size: 10, circumference: '179.7(mm)' },
    { size: 11, circumference: '184.4(mm)' },
    { size: 12, circumference: '189.4(mm)' },
    { size: 13, circumference: '194.5(mm)' },
    { size: 14, circumference: '199.5(mm)' },
    { size: 15, circumference: '204.5(mm)' },
    { size: 16, circumference: '209.5(mm)' },
    { size: 17, circumference: '214.6(mm)' },
    { size: 18, circumference: '214.6(mm)' },
    { size: 19, circumference: '214.6(mm)' },
    { size: 20, circumference: '214.6(mm)' },
    { size: 21, circumference: '214.6(mm)' },
    { size: 22, circumference: '214.6(mm)' },
    { size: 23, circumference: '214.6(mm)' },
    { size: 24, circumference: '214.6(mm)' },
    { size: 25, circumference: '214.6(mm)' },
    { size: 26, circumference: '66(mm)' },
    { size: 27, circumference: '66.9(mm)' },
    { size: 28, circumference: '67.9(mm)' },
    { size: 29, circumference: '69.1(mm)' },
    { size: 30, circumference: '70.1(mm)' },
    // Add more sizes as needed
  ];
  const banglesSizes = [
    { size: '2-0', circumference: '159.6(mm)' },
    { size: '2-1', circumference: '164.6(mm)' },
    { size: '2-2', circumference: '169.6(mm)' },
    { size: '2-3', circumference: '174.7(mm)' },
    { size: '2-4', circumference: '179.7(mm)' },
    { size: '2-5', circumference: '184.4(mm)' },
    { size: '2-6', circumference: '189.4(mm)' },
    { size: '2-7', circumference: '194.5(mm)' },
    { size: '2-8', circumference: '199.5(mm)' },
    { size: '2-9', circumference: '204.5(mm)' },
    { size: '2-10', circumference: '209.5(mm)' },
    { size: '2-11', circumference: '214.6(mm)' },
  ]
  const chainSizes = [
    { size: '14', circumference: '35.56(cm)' },
    { size: '16', circumference: '40.50(cm)' },
    { size: '18', circumference: '45.75(cm)' },
    { size: '20', circumference: '50.75(cm)' },
    { size: '22', circumference: '55.88(cm)' },
    { size: '24', circumference: '61.00(cm)' },
    { size: '30', circumference: '76.25(cm)' },
    { size: '34', circumference: '109.20(cm)' },
  ]
  const ringGuideSteps = [
    { image : '/assets/img/sizeGuide/Bangle_Measure_S1.png', title : 'Step 1', description : 'To measure your ring finger, you will need a measuring tape or a piece of paper and a ruler.'},
    { image : '/assets/img/sizeGuide/ring_2.png', title : 'Step 2', description : 'Measure the length of the paper strip/thread with a ruler.'},
    { image : '/assets/img/sizeGuide/Bangle_Measure_S3.png', title : 'Step 3', description : 'Bring your thumb and little finger together and measure where your hand is the widest as shown in the picture.'},
    { image : '/assets/img/sizeGuide/ring_4.png', title : 'Step 4', description : 'Measure the length of the paper strip/thread with a ruler.'}
  ];
  const banglesGuideSteps = [
    { image : '/assets/img/sizeGuide/step-1.png', title : 'Step 1', description : 'Bring your thumb and little finger together and measure where your hand is the widest as shown in the picture.'},
    { image : '/assets/img/sizeGuide/step-2.png', title : 'Step 2', description : 'Measure the length of the paper strip/thread with a ruler.'}
  ]
  let chainsGuideStepsMale = [
    { image : '/assets/img/sizeGuide/chainSize.png', title : '', description : '', gender : 'male'},
  ]
  let chainsGuideStepsFeMale = [
    { image : '/assets/img/sizeGuide/chainSizeFemale.png', title : '', description : '', gender : 'female'},
  ]
  return (
    <>
      {isDesktop && 
      <>
      {diamondGuideModel && 
        <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-[#393838b3] bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-center justify-center p-1 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-[40%] sm:my-8 sm:w-full sm:max-w-lg">
        <div class="bg-white px-2 pb-2 pt-5 sm:p-6 sm:pb-2">
          <div class="sm:flex sm:items-start">
          <div class="flex justify-between items-center mt-3 sm:ml-4 sm:mt-0">
          <div></div>
          <p class="font-medium text-black text-[24px]">Diamond Buying Guide</p>
          <button type="button" className="text-black font-bold text-2xl focus:outline-none mr-3" onClick={() => ringSizeModelClose(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
          <path d="M17.3125 17.3125L9.5 9.5M9.5 9.5L1.6875 1.6875M9.5 9.5L17.3125 1.6875M9.5 9.5L1.6875 17.3125" stroke="black" stroke-width="2" stroke-linecap="round"/>
          </svg>
          </button>
          </div>
          <div>
          <div className="p-4 bg-white text-gray-800">
            {/* Diamond Colour Section */}
            <section className="mb-8">
              <h2 className="text-[15px] font-semibold mb-4">Diamond Colour</h2>
              <p className="mb-4 text-[14px] font-medium">
                Ironically, the best color for a diamond would be ‘colourless’. The colors range from 
                D (colourless) and go all the way to Z (light yellow). To be honest, the color difference 
                in diamonds within a colour family is quite subtle to an untrained eye. Nevertheless, 
                they do create a difference in the price and quality of the diamond. Bottom line is, 
                colourless diamonds are the most precious!
              </p>
            </section>

            {/* GoldClub Recommendation Section */}
            <section className="mb-8">
              <h3 className="text-[15px] font-semibold text-[#222AE7]">{storeData?.name?.replace(","," ")} Recommendation:</h3>
              <p className="mt-2 text-[14px] font-medium">
                As diamond size increases, colour becomes more noticeable. This is especially important 
                to keep in mind if you are purchasing a diamond of two carats or greater. If you pick a 
                slightly yellow colored diamond, you can pair it with a white gold or platinum band. The 
                white gold will complement the yellow hue of your diamond and they won’t clash with each other!
              </p>
            </section>

            {/* Diamond Color Range */}
            <div className="py-4 mb-2">
            <div className="flex justify-center items-center space-x-5">
              {['D', 'E', 'F', 'G-J', 'K-M', 'N-R', 'S-Z'].map((grade, index) => (
                    <div key={index} className="text-center">
                      <div className="w-16 h-16">
                        {/* Placeholder for diamond illustration */}
                       <img src='/assets/img/sizeGuide/Diamond_Colors_Variations.png'></img>
                      </div>
                      <p className="text-[12px] font-medium border-t mt-3 border-[#222AE7]">{grade}</p>
                    </div>
                  ))}
                {/* <img src='/assets/img/sizeGuide/Diamond_Colors_Variations.png' className="w-full h-full object-contain" /> */}
              </div>
            </div>
            <div style={{background: '#F4F4F4', borderRadius : '5px', height : '25px'}} className='mb-8'>
              <p className='text-[12px] font-semibold p-1'>S-Z - Light</p>
            </div>
            {/* Diamond Clarity Section */}
            <section className='mb-8'>
              <h2 className="text-[15px] font-semibold mb-4">Diamond Clarity</h2>
              <p className='text-[14px] font-medium'>
                When light passes through a diamond, it bounces back, making the diamond sparkle! 
                Some diamonds have imperfections in the form of spots and blemishes. The more the 
                imperfections, the more difficult it is for light to pass through, thereby reducing 
                the sparkle. If you're lost, here’s all you need to remember: Lesser imperfection 
                and inclusions = Better Clarity
              </p>
            </section>
             {/* GoldClub Recommendation Section */}
             <section className="mb-2">
                <h3 className="text-[15px] font-semibold text-[#222AE7]">{storeData?.name?.replace(","," ")} Recommendation:</h3>
                <p className="mt-2 text-[14px] font-medium">
                  If you're buying a diamond that's under a carat, don’t obsess over the spots or blemishes 
                  because they aren’t really visible to an untrained eye. It’s better to invest in the cut 
                  and sparkle on!
                </p>
              </section>
              {/* Diamond Clarity Range */}
              <div className="py-4 mb-2">
                <div className="flex justify-center items-center space-x-8">
                  {/* Diamond Grades */}
                  {['FL', 'IF', 'VVS1/VVS2', 'VS1/VS2', 'SI1/SI2', 'I1/I2/I3'].map((grade, index) => (
                    <div key={index} className="text-center">
                      <div className="w-16 h-16">
                        {/* Placeholder for diamond illustration */}
                       <img src='/assets/img/sizeGuide/Diamond_Clarity_Variation.png'></img>
                      </div>
                      <p className="text-[12px] font-medium border-t mt-3 border-[#222AE7]">{grade}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* FL - Flawless Explanation */}
              <div className="bg-[#f4f4f4] p-2 rounded-[5px]">
                <p className="font-medium text-[12px]">
                  <span className="font-semibold">FL - Flawless</span> diamonds have no inclusions or blemishes 
                  detectable under 10x magnification, and are extremely rare.
                </p>
              </div>
          </div>
          </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
    </div>
    </div>
      }
      {!diamondGuideModel && 
      <div
          className='relative z-10'
          aria-labelledby='slide-over-title'
          role='dialog'
          aria-modal='true'
        >
          <div
            className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
            aria-hidden='true'
          ></div>
          <div className='fixed inset-0 overflow-auto'>
            <div className='absolute inset-0 overflow-auto'>
              <div className='pointer-events-none fixed inset-x-0 right-0 flex max-w-full'>
                <div className='pointer-events-auto relative w-screen max-w-[26%]'>
                  <div className='header flex justify-between rounded-tr-[50px]' style={{backgroundColor : data.color.shade3}}>
                   <div></div>
                    <div className='py-3'>
                      <div className='title text-center'>{type} Size Guide</div>
                    </div>
                    <button
                      type='button'
                      className='close relative cursor-pointer rounded-md text-black'
                      onClick={() => ringSizeModelClose(false)}
                    >
                      <span className='absolute -inset-2.5'></span>
                      <span className='sr-only'>Close panel</span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='41'
                        height='41'
                        viewBox='0 0 41 41'
                        fill='none'
                      >
                        <path
                          d='M20.5003 38.8646C10.3699 38.8646 2.13574 30.6304 2.13574 20.5C2.13574 10.3696 10.3699 2.13542 20.5003 2.13542C30.6307 2.13542 38.8649 10.3696 38.8649 20.5C38.8649 30.6304 30.6307 38.8646 20.5003 38.8646ZM20.5003 4.69792C11.7878 4.69792 4.69824 11.7875 4.69824 20.5C4.69824 29.2125 11.7878 36.3021 20.5003 36.3021C29.2128 36.3021 36.3024 29.2125 36.3024 20.5C36.3024 11.7875 29.2128 4.69792 20.5003 4.69792Z'
                          fill='#292D32'
                        />
                        <path
                          d='M15.6657 26.6158C15.3411 26.6158 15.0165 26.4963 14.7602 26.24C14.2648 25.7446 14.2648 24.9246 14.7602 24.4292L24.4294 14.76C24.9248 14.2646 25.7448 14.2646 26.2402 14.76C26.7357 15.2554 26.7357 16.0754 26.2402 16.5708L16.5711 26.24C16.3319 26.4963 15.9902 26.6158 15.6657 26.6158Z'
                          fill='#292D32'
                        />
                        <path
                          d='M25.3348 26.6158C25.0102 26.6158 24.6857 26.4963 24.4294 26.24L14.7602 16.5708C14.2648 16.0754 14.2648 15.2554 14.7602 14.76C15.2557 14.2646 16.0757 14.2646 16.5711 14.76L26.2402 24.4292C26.7357 24.9246 26.7357 25.7446 26.2402 26.24C25.984 26.4963 25.6594 26.6158 25.3348 26.6158Z'
                          fill='#292D32'
                        />
                      </svg>
                    </button>
                  </div>
                  <div className='flex h-full flex-col bg-white overflow-y-auto max-h-[92vh]'>
                  <div className="max-w-2xl mx-auto p-2 mr-3">
                    {type !== 'Chains' && <p className="text-center mb-4 text-[#535353] text-[11px]">Measure your ring size using a piece of paper or string as shown in the steps below:</p>}
                    {type === 'Chains' && <div>
                    <label className="block text-sm font-medium text-gray-600 mb-2">Select your gender*</label>
                    <select
                      className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      required
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>}
                    <div className={`grid ${type === 'Chains' ? "grid-cols-1" :"grid-cols-2"} mb-6 gap-2`}>
                      {type === 'Ring' && ringGuideSteps && ringGuideSteps.length > 0 && ringGuideSteps.map(item => (
                        <div className="flex flex-col items-center p-2" key={item.title}>
                          <div className={`flex items-center justify-center`}
                            style={{border: '1px solid #0000001A',  width : '175px', height : '175px'}}>
                            <img src={item.image} alt={item.title} className="w-full h-full object-contain" />
                          </div>
                          <h3 className="font-semibold mt-4 text-[10px]">{item.title}</h3>
                          <p className="text-center mt-2 text-[9px]">
                            {item.description}
                          </p>
                        </div>
                      ))}
                      {type === 'Bangles' && banglesGuideSteps && banglesGuideSteps.length > 0 && banglesGuideSteps.map(item => (
                        <div className="flex flex-col items-center p-2" key={item.title}>
                          <div className={`flex items-center justify-center`}
                            style={{border: '1px solid #0000001A', width : '175px', height : '175px'}}>
                            <img src={item.image} alt={item.title} className="w-full h-full object-contain" />
                          </div>
                          <h3 className="font-semibold mt-4 text-[10px]">{item.title}</h3>
                          <p className="text-center mt-2 text-[9px]">
                            {item.description}
                          </p>
                        </div>
                      ))}
                      {type === 'Chains' && gender === 'male' && chainsGuideStepsMale && chainsGuideStepsMale.length > 0 && chainsGuideStepsMale.map(item => (
                        <div className="flex flex-col items-center p-2" key={item.title}>
                          <div className={`w-96 h-80 flex items-center justify-center`}
                            style={{border: '1px solid #0000001A'}}>
                            <img src={item.image} alt={item.title} className="w-full h-full object-contain" />
                          </div>
                        </div>
                      ))}
                      {type === 'Chains' && gender === 'female' && chainsGuideStepsFeMale && chainsGuideStepsFeMale.length > 0 && chainsGuideStepsFeMale.map(item => (
                        <div className="flex flex-col items-center p-2" key={item.title}>
                          <div className={`w-96 h-80 flex items-center justify-center`}
                            style={{border: '1px solid #0000001A'}}>
                            <img src={item.image} alt={item.title} className="w-full h-full object-contain" />
                          </div>
                        </div>
                      ))} 
                    </div>

                    <table className="min-w-full bg-white text-center">
                      <thead className='text-[12px]'>
                        <tr>
                        {type === 'Ring' && (<><th className="py-2 px-4">Ring Size</th>
                          <th className="py-2 px-4">Circumference (mm)</th></>)}
                        {type === 'Bangles' && (<><th className="py-2 px-4">Bangle Size (Anna)</th>
                          <th className="py-2 px-4">Circumference (mm)</th></>)}
                        {type === 'Chains' && (<><th className="py-2 px-4">Length(in)</th>
                          <th className="py-2 px-4">Length(cm)</th></>)}
                        </tr>
                      </thead>
                      <tbody className="text-[#484848] text-[10px]">
                        {type === 'Ring' && ringSizes.map((ring, index) => (
                          <tr key={index}>
                            <td className="py-2 px-4">{ring.size}</td>
                            <td className="py-2 px-4">{ring.circumference}</td>
                          </tr>
                        ))}
                        {type === 'Bangles' && banglesSizes.map((ring, index) => (
                          <tr key={index}>
                            <td className="py-2 px-4">{ring.size}</td>
                            <td className="py-2 px-4">{ring.circumference}</td>
                          </tr>
                        ))}
                        {type === 'Chains' && chainSizes.map((ring, index) => (
                          <tr key={index}>
                            <td className="py-2 px-4">{ring.size}</td>
                            <td className="py-2 px-4">{ring.circumference}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {type === 'Chains' && <p className='p-2 text-[11px]' style={{color: '#535353'}}>*The base unit of Length is in meter as per Legal Metrology Act, 2009. For Convenience of customer its equivalent length in inches is also reflected.</p>}
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </>}
      {!isDesktop && 
      <>
      {diamondGuideModel && 
        <div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-[#393838b3] bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-center justify-center px-3 py-1 text-center">
      <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-[40%] sm:my-1 sm:w-full sm:max-w-lg">
        <div class="bg-white px-2 pb-2 pt-3 sm:p-2 sm:pb-2">
          <div>
          <div class="flex justify-between items-center sm:ml-4 sm:mt-0">
          <div></div>
          <p class="font-medium text-black text-[16px]">Diamond Buying Guide</p>
          <button type="button" className="text-black font-bold text-2xl focus:outline-none mr-3" onClick={() => ringSizeModelClose(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 19 19" fill="none">
          <path d="M17.3125 17.3125L9.5 9.5M9.5 9.5L1.6875 1.6875M9.5 9.5L17.3125 1.6875M9.5 9.5L1.6875 17.3125" stroke="black" stroke-width="2" stroke-linecap="round"/>
          </svg>
          </button>
          </div>
          <div>
          <div className="px-3 py-0 bg-white text-gray-800">
            {/* Diamond Colour Section */}
            <section className="mb-2">
              <h2 className="text-[13px] font-semibold">Diamond Colour</h2>
              <p className="mt-1 text-[10px] font-medium">
                Ironically, the best color for a diamond would be ‘colourless’. The colors range from 
                D (colourless) and go all the way to Z (light yellow). To be honest, the color difference 
                in diamonds within a colour family is quite subtle to an untrained eye. Nevertheless, 
                they do create a difference in the price and quality of the diamond. Bottom line is, 
                colourless diamonds are the most precious!
              </p>
            </section>

            {/* GoldClub Recommendation Section */}
            <section>
              <h3 className="text-[13px] font-semibold text-[#222AE7]">{storeData?.name?.replace(","," ")} Recommendation:</h3>
              <p className="mt-1 text-[10px] font-medium">
                As diamond size increases, colour becomes more noticeable. This is especially important 
                to keep in mind if you are purchasing a diamond of two carats or greater. If you pick a 
                slightly yellow colored diamond, you can pair it with a white gold or platinum band. The 
                white gold will complement the yellow hue of your diamond and they won’t clash with each other!
              </p>
            </section>

            {/* Diamond Color Range */}
            <div className="py-1">
            <div className="flex justify-center items-center space-x-2">
              {['D', 'E', 'F', 'G-J', 'K-M', 'N-R', 'S-Z'].map((grade, index) => (
                    <div key={index} className="text-center">
                      <div className="w-10 h-10">
                        {/* Placeholder for diamond illustration */}
                       <img src='/assets/img/sizeGuide/Diamond_Colors_Variations.png'></img>
                      </div>
                      <p className="text-[10px] font-medium border-t border-[#222AE7]">{grade}</p>
                    </div>
                  ))}
                {/* <img src='/assets/img/sizeGuide/Diamond_Colors_Variations.png' className="w-full h-full object-contain" /> */}
              </div>
            </div>
            <div style={{background: '#F4F4F4', borderRadius : '5px', height : '22px'}} className='mb-1'>
              <p className='text-[10px] font-semibold p-1'>S-Z - Light</p>
            </div>
            {/* Diamond Clarity Section */}
            <section className='mb-2'>
              <h2 className="text-[13px] font-semibold">Diamond Clarity</h2>
              <p className='text-[10px] font-medium mt-1'>
                When light passes through a diamond, it bounces back, making the diamond sparkle! 
                Some diamonds have imperfections in the form of spots and blemishes. The more the 
                imperfections, the more difficult it is for light to pass through, thereby reducing 
                the sparkle. If you're lost, here’s all you need to remember: Lesser imperfection 
                and inclusions = Better Clarity
              </p>
            </section>
             {/* GoldClub Recommendation Section */}
             <section>
                <h3 className="text-[13px] font-semibold text-[#222AE7]">{storeData?.name?.replace(","," ")} Recommendation:</h3>
                <p className="mt-1 text-[10px] font-medium">
                  If you're buying a diamond that's under a carat, don’t obsess over the spots or blemishes 
                  because they aren’t really visible to an untrained eye. It’s better to invest in the cut 
                  and sparkle on!
                </p>
              </section>
              {/* Diamond Clarity Range */}
              <div className="py-1">
                <div className="flex justify-center items-center space-x-3">
                  {/* Diamond Grades */}
                  {['FL', 'IF', 'VVS1/VVS2', 'VS1/VS2', 'SI1/SI2', 'I1/I2/I3'].map((grade, index) => (
                    <div key={index} className="text-center">
                      <div className="w-10 h-10">
                        {/* Placeholder for diamond illustration */}
                       <img src='/assets/img/sizeGuide/Diamond_Clarity_Variation.png'></img>
                      </div>
                      <p className="text-[10px] font-medium border-t mt-1 border-[#222AE7]">{grade}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* FL - Flawless Explanation */}
              <div className="bg-[#f4f4f4] p-1 rounded-[5px]">
                <p className="font-medium text-[8px]">
                  <span className="font-semibold">FL - Flawless</span> diamonds have no inclusions or blemishes 
                  detectable under 10x magnification, and are extremely rare.
                </p>
              </div>
          </div>
          </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
    </div>
    </div>
      }
    {!diamondGuideModel && <div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-[#393838b3] bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <div class="fixed inset-0 z-50 w-full overflow-y-auto">
      <div class="flex min-h-full text-center">
      <div class="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
        <div className='p-4'>
        <div>
        <div className="flex items-center cursor-pointer">
        <div onClick={() => ringSizeModelClose(false)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M16.875 9L1.125 9M1.125 9L6.91071 15.75M1.125 9L6.91071 2.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        </div>
        <span className="text-black font-medium ml-3">Size Guide</span>
        </div>
        </div>
        </div>
      <div style={{backgroundColor : data.color.shade3}} className='p-3'>{type}</div>
      <div className='flex h-full flex-col bg-white overflow-y-auto max-h-[100vh]'>
      <div className="w-full mx-auto p-2">
      {type === 'Ring' && <p style={{fontFamily: 'Inter', fontSize: '15px',fontWeight: '500',lineHeight: '18.15px',textAlign: 'center',}}>Find your Ring Size</p>}
      {type === 'Bangles' && <p className="text-center mb-4 text-[#535353] text-[10px]">Measure your ring size using a piece of paper or string as shown in the steps below:</p>}
      {type === 'Chains' && (
        <div>
            <label className="block text-sm font-medium text-gray-600 mb-2">Select your Gender*</label>
            <div className="flex space-x-4">
              <button
                type="button"
                className={`w-1/3 p-2 rounded-lg border ${gender === 'male' ? 'text-white' : 'bg-gray-200 text-gray-600'} hover:text-white`}
                onClick={() => setGender('male')}
                style={{backgroundColor : gender === 'male' ? data.color.ctaPrimary : ''}}

              >
                Male
              </button>
              <button
                type="button"
                className={`w-1/3 p-2 rounded-lg border ${gender === 'female' ? 'text-white' : 'bg-gray-200 text-gray-600'} hover:text-white`}
                onClick={() => setGender('female')}
                style={{backgroundColor : gender === 'female' ? data.color.ctaPrimary : ''}}

              >
                Female
              </button>
            </div>
          </div>
      )}

        <div className={`grid ${type === 'Chains' ? "grid-cols-1" :"grid-cols-2"} mb-6 gap-2 p-2`}>
          {type === 'Ring' && ringGuideSteps && ringGuideSteps.length > 0 && ringGuideSteps.map(item => (
            <div className="flex flex-col items-center p-2" key={item.title}>
              <div className={`flex items-center justify-center`}
                style={{border: '1px solid #0000001A', width : '170px', height : '170px'}}>
                <img src={item.image} alt={item.title} className="h-full w-full object-contain" />
              </div>
              <h3 className="text-[10px] font-semibold mt-4">{item.title}</h3>
              <p className="text-center mt-2 text-[9px]">
                {item.description}
              </p>
            </div>
          ))}
          {type === 'Bangles' && banglesGuideSteps && banglesGuideSteps.length > 0 && banglesGuideSteps.map(item => (
            <div className="flex flex-col items-center p-2" key={item.title}>
              <div className={`flex items-center justify-center`}
                style={{border: '1px solid #0000001A',width : '170px', height : '170px'}}>
                <img src={item.image} alt={item.title} className="w-full h-full object-contain" />
              </div>
              <h3 className="text-[10px] font-semibold mt-4">{item.title}</h3>
              <p className="text-center mt-2 text-[9px]">
                {item.description}
              </p>
            </div>
          ))}
          {type === 'Chains' && gender === 'male' && chainsGuideStepsMale && chainsGuideStepsMale.length > 0 && chainsGuideStepsMale.map(item => (
            <div className="flex flex-col items-center p-2" key={item.title}>
              <div className={`w-96 h-80 flex items-center justify-center`}
                style={{border: '1px solid #0000001A'}}>
                <img src={item.image} alt={item.title} className="w-full h-full object-contain" />
              </div>
              
            </div>
          ))}
          {type === 'Chains' && gender === 'female' && chainsGuideStepsFeMale && chainsGuideStepsFeMale.length > 0 && chainsGuideStepsFeMale.map(item => (
            <div className="flex flex-col items-center p-2" key={item.title}>
              <div className={`w-96 h-80 flex items-center justify-center`}
                style={{border: '1px solid #0000001A'}}>
                <img src={item.image} alt={item.title} className="w-full h-full object-contain" />
              </div>
            </div>
          ))} 
        </div>

        <table className="min-w-full bg-white text-center">
          <thead>
            <tr className='text-[12px]'>
            {type === 'Ring' && (<><th className="py-2 px-4">Ring Size</th>
              <th className="py-2 px-4">Circumference (mm)</th></>)}
            {type === 'Bangles' && (<><th className="py-2 px-4">Bangle Size (Anna)</th>
              <th className="py-2 px-4">Circumference (mm)</th></>)}
            {type === 'Chains' && (<><th className="py-2 px-4">Length(in)</th>
              <th className="py-2 px-4">Length(cm)</th></>)}
            </tr>
          </thead>
          <tbody className="text-[#484848] text-[10px]">
            {type === 'Ring' && ringSizes.map((ring, index) => (
              <tr key={index}>
                <td className="py-2 px-4">{ring.size}</td>
                <td className="py-2 px-4">{ring.circumference}</td>
              </tr>
            ))}
            {type === 'Bangles' && banglesSizes.map((ring, index) => (
              <tr key={index}>
                <td className="py-2 px-4">{ring.size}</td>
                <td className="py-2 px-4">{ring.circumference}</td>
              </tr>
            ))}
            {type === 'Chains' && chainSizes.map((ring, index) => (
              <tr key={index}>
                <td className="py-2 px-4">{ring.size}</td>
                <td className="py-2 px-4">{ring.circumference}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {type === 'Chains' && <p className='p-2 text-[11px]' style={{color: '#535353'}}>*The base unit of Length is in meter as per Legal Metrology Act, 2009. For Convenience of customer its equivalent length in inches is also reflected.</p>}
      </div>
      </div>
      </div>
      </div>
    </div>
    </div>}
    </>}
    </>
  );
};

export default SizeGuide;
