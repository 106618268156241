import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Cart, Like, Shop, UserCircle } from 'icons';
import { useState, useRef, useEffect } from 'react';
import { useGetBackgroundColor } from "../../../src/api/index";
import GoldRate from 'components/goldRate';
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const storeNameRef = useRef(null);
  const [fontSize, setFontSize] = useState(20);
  const menuData = useSelector(state => state.menu?.menuData);
  const storeData = useSelector(state => state.storeData);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const firstMenuItemRef = useRef(null); // Ref for the first menu item
  const { data } = useGetBackgroundColor({ onLoad: true });
  const [isHovered, setIsHovered] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const detailsRef = useRef(null);
  console.log("menuData Line 22",menuData)
  const handleClick = () => {
    setShowDetails(!showDetails);
  };
  const checkUserLogin = (path) => {
    let userD = JSON.parse(localStorage.getItem(`vjw-${window.location.hostname}user-det`));
    if(!userD){
      setIsLoggedIn(true) 
    }else{
      setTimeout(() => navigate(path), 0);
    }
  }
  // Close details when clicking outside
  useEffect(() => {
    function handleOutsideClick(event) {
      if (detailsRef.current && !detailsRef.current.contains(event.target)) {
        setIsLoggedIn(false);
        setTimeout(() => {
          setShowDetails(false);
        }, 230);
      }
    }

    // Add event listener for clicks
    document.addEventListener('mousedown', handleOutsideClick);

    // Cleanup the event listener
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const firstItemRef = firstMenuItemRef.current;
    const boundingRect = firstItemRef?.getBoundingClientRect();
    const firstItemX = boundingRect?.x ?? 0; // Get the X coordinate of the first menu item
    setPopupPosition({ x: firstItemX ?? 0 });
  }, []);

  useEffect(() => {
    const adjustFontSize = () => {
      const element = storeNameRef.current;
      const containerHeight = 50;
      const lineHeight = 1.2;

      if (element) {
        const currentHeight = element.scrollHeight;
        const maxHeight = containerHeight * lineHeight;

        if (currentHeight > maxHeight) {
          setFontSize((prevFontSize) => prevFontSize - 1.45);
        }
      }
    };

    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);

    return () => {
      window.removeEventListener('resize', adjustFontSize);
    };
  }, [storeData]);

  const handleMouseEnter = (e) => { 
    setIsHovered(true)  
    // Get the position of the hovered menu item
    const rect = e.target.getBoundingClientRect();
    setPopupPosition({ x: rect.left, y: rect.bottom }); // Set the popup to appear below the item
  };  

  return (
    <>
      <header id='header' className='flex items-center fixed w-full z-10 h-[100px]'>
        <div
          className='relative flex w-full flex-wrap items-center gap-5 pl-[30px] pr-[30px]'
          style={{ backgroundColor: data.color.secondaryColor }}
        >
          <div className='logo order-1 flex items-center gap-4'>
          <Link to="/" className="flex" style={{ alignItems: 'center' }}>
            <img
              src={storeData?.storeLogo}
              alt="Main Logo"
              width={87}
              height={87}
            />
            <h3
              className="text-[#FFFFFF] text-inter"
              style={{
                marginLeft: '15px',
                marginRight: '5px',
                maxWidth: '380px',
                minWidth:"250px",
                height: '50px',
                whiteSpace: 'normal',
                overflow: 'hidden',
                wordBreak: 'break-word',
                lineHeight: '1.2em',
                fontSize: `${fontSize}px`,
                transition: 'font-size 0.3s ease',
                textAlign: 'center', // Ensures text is centered within the container
                display: 'flex',
                flexDirection: 'column', // Aligns content vertically
                justifyContent: 'center', // Centers vertically
              }}
            >
              {storeData?.name
                ?.toUpperCase()
                ?.split(',')
                ?.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontFamily:data.font,
                      fontWeight: index === 0 ? 'bold' : 'unset',
                      fontSize: index === 0 ? '22px' : '18px',
                      display: 'block',
                    }}
                  >
                    {part}
                  </span>
                ))}
            </h3>
          </Link>


          </div>
          <ul className='order-2 flex w-auto items-center justify-center gap-5 text-sm font-medium lg:order-3 lg:w-full'>
            {menuData &&
              !menuData?.isLoading?.localStore &&
              menuData?.data?.map((item, index) => {
                return (
                  <li
                    ref={index === 0 ? firstMenuItemRef : null} // Attach ref to the first item
                    className={`group relative w-full cursor-pointer py-10 after:absolute after:bottom-0 after:left-0 after:h-1 after:w-full after:scale-x-0  after:transition-all after:duration-300 after:ease-in-out hover:after:scale-x-100`}
                    key={index}
                    onMouseEnter={handleMouseEnter} // Set popup position on hover
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <Link
                     style={{
                      color: isHovered ? data?.color?.ctaPrimary : '#f5f5f5',
                      fontSize: '14px',
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_PRODUCT_QUERY_ROUTE}=${item?.id}`}
                    >
                    <h3 className='text-white'
                    >
                        {item.name}
                    </h3>
                      </Link>
                    {/* Sub-menu for each item */}
                    {item.sub && item.sub.length > 0 && (
                      <div
                        className={`absolute z-20 hidden group-hover:flex`}
                        style={{
                          top: '100%', // Set the y-position dynamically
                          left: `${-popupPosition.x + 380}px`, // Set the x-position dynamically
                          position: 'absolute',
                        }}
                      >
                        <div
                          className={`w-[${item.sub?.length > 6 ? '1300px' : '650px'}] flex rounded-lg border bg-white shadow-lg`}
                          style={{minHeight:"250px"}}
                        >
                          {/* First Column (Shop by Style) */}
                          <div
                              className={`flex ${
                                item.sub && item.sub.length > 6 ? 'w-[600px]' : 'w-[400px]'
                              } flex-col gap-5 pb-1`}
                            >
                              <h4 className="text-center font-semibold text-gray-900 pt-4">
                                Shop by Style
                              </h4>
                              <div
                                className={`flex ${
                                  item.sub && item.sub.length > 6 ? 'flex-row' : 'flex-col'
                                } gap-5`}
                              >
                                {/* First Column: First 6 Items */}
                                <ul className={`flex flex-col gap-2 ${item?.sub?.length > 6 ? 'w-1/2':'' }`}>
                                  {item.sub.slice(0, 6).map((subItem, subIndex) => (
                                    <li key={subIndex}>
                                      <Link
                                        to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_PRODUCT_QUERY_ROUTE}=${subItem?.id}`}
                                        className="flex pl-8 text-gray-600 hover:text-brand"
                                      >
                                        {/* Image container */}
                                        <div className="relative mt-1 h-[86px] w-[86px] overflow-hidden rounded-md bg-gray-300">
                                          <img
                                            width={86}
                                            height={86}
                                            src={subItem.img}
                                            alt={subItem.name || 'Sub item'}
                                            className="h-full w-full object-cover"
                                          />
                                        </div>
                                        {/* Space between image and text */}
                                        <span className="ml-4 mt-8 font-bold">{subItem.description ||subItem.name}</span>
                                      </Link>
                                    </li>
                                  ))}
                                </ul>

                                {/* Second Column: Next 6 Items */}
                                <ul className="flex flex-col gap-2 w-1/2">
                                  {item.sub.slice(6, 12).map((subItem, subIndex) => (
                                      <li key={subIndex}>
                                        <Link
                                          to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_PRODUCT_QUERY_ROUTE}=${subItem?.id}`}
                                          className="flex pl-8 text-gray-600 hover:text-brand"
                                        >
                                          {/* Image container */}
                                          <div className="relative mt-1 h-[86px] w-[86px] overflow-hidden rounded-md bg-gray-300">
                                            <img
                                              width={86}
                                              height={86}
                                              src={subItem.img}
                                              alt={subItem.name || 'Sub item'}
                                              className="h-full w-full object-cover"
                                            />
                                          </div>
                                          {/* Space between image and text */}
                                          <span className="ml-4 mt-8 font-bold">{subItem.description ||subItem.name}</span>
                                        </Link>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>


                          {/* Second Column (Shop by Metal & Stone) */}
                          <div className='ml-10 flex w-[250px] flex-col gap-2 bg-gray-300 text-center'>
                            <h4 className='pb-10 font-semibold text-gray-900 pt-4'>
                              Shop For
                            </h4>
                            <ul className='pb-30 text-left'>
                              <li key={1} className='pb-5'>
                                <Link
                                  to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_PRODUCT_QUERY_ROUTE}=${item?.id}&gender=male`}
                                >
                                  <span className='ml-4 mt-3'>FOR MEN</span>
                                </Link>
                              </li>
                              <li key={2} className='pb-5'>
                                <Link
                                  to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_PRODUCT_QUERY_ROUTE}=${item?.id}&gender=female`}
                                >
                                  <span className='ml-4 mt-3'>FOR WOMEN</span>
                                </Link>
                              </li>
                              <li key={3} className='pb-5'>
                                <Link
                                  to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_PRODUCT_QUERY_ROUTE}=${item?.id}&gender=kids`}
                                >
                                  <span className='ml-4 mt-3'>FOR KIDS</span>
                                </Link>
                              </li>
                              <Link
                                to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_PRODUCT_QUERY_ROUTE}=${item?.id}`}
                                className="absolute bottom-2 right-2 text-black mt-10 py-2 px-4 text-sm font-extrabold"
                              >
                                SHOW ALL DESIGNS
                              </Link>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                );
              })}
          </ul>

          <div
            className='nav_rightside_text order-3 ml-auto flex items-center gap-8 lg:order-2'
            id='nav_desktop'
          >
            <div className='flex items-center gap-6'>
              <div onClick={handleClick} className='cursor-pointer'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='21'
                  height='23'
                  viewBox='0 0 21 23'
                  fill='none'
                >
                  <path
                    d='M18.375 21.9654H2.625C2.26625 21.9654 1.96875 21.684 1.96875 21.3446C1.96875 21.0053 2.26625 20.7239 2.625 20.7239H18.375C18.7337 20.7239 19.0312 21.0053 19.0312 21.3446C19.0312 21.684 18.7337 21.9654 18.375 21.9654Z'
                    fill='white'
                  />
                  <path
                    d='M4.89998 19.4825H3.5C2.66 19.4825 1.96875 18.8287 1.96875 18.0342V10.8998C1.96875 10.1053 2.66 9.45142 3.5 9.45142H4.89998C5.73998 9.45142 6.43123 10.1053 6.43123 10.8998V18.0342C6.43123 18.8287 5.73998 19.4825 4.89998 19.4825ZM3.5 10.6846C3.3775 10.6846 3.28125 10.7757 3.28125 10.8915V18.0342C3.28125 18.15 3.3775 18.2411 3.5 18.2411H4.89998C5.02248 18.2411 5.11873 18.15 5.11873 18.0342V10.8998C5.11873 10.7839 5.02248 10.6929 4.89998 10.6929H3.5V10.6846Z'
                    fill='white'
                  />
                  <path
                    d='M11.1998 19.4824H9.7998C8.9598 19.4824 8.26855 18.8285 8.26855 18.034V8.25943C8.26855 7.46488 8.9598 6.81104 9.7998 6.81104H11.1998C12.0398 6.81104 12.731 7.46488 12.731 8.25943V18.034C12.731 18.8285 12.0398 19.4824 11.1998 19.4824ZM9.7998 8.05251C9.6773 8.05251 9.58105 8.14355 9.58105 8.25943V18.034C9.58105 18.1499 9.6773 18.2409 9.7998 18.2409H11.1998C11.3223 18.2409 11.4185 18.1499 11.4185 18.034V8.25943C11.4185 8.14355 11.3223 8.05251 11.1998 8.05251H9.7998Z'
                    fill='white'
                  />
                  <path
                    d='M17.4996 19.4825H16.0996C15.2596 19.4825 14.5684 18.8286 14.5684 18.0341V5.61929C14.5684 4.82474 15.2596 4.1709 16.0996 4.1709H17.4996C18.3396 4.1709 19.0308 4.82474 19.0308 5.61929V18.0341C19.0308 18.8286 18.3396 19.4825 17.4996 19.4825ZM16.0996 5.41238C15.9771 5.41238 15.8809 5.50342 15.8809 5.61929V18.0341C15.8809 18.1499 15.9771 18.241 16.0996 18.241H17.4996C17.6221 18.241 17.7183 18.1499 17.7183 18.0341V5.61929C17.7183 5.50342 17.6221 5.41238 17.4996 5.41238H16.0996Z'
                    fill='white'
                  />
                  <path
                    d='M4.14161 6.79553C3.93161 6.79553 3.72161 6.72235 3.55582 6.56553C3.2353 6.26235 3.2353 5.76053 3.55582 5.45735L7.09267 2.11189C7.26951 1.94462 7.50161 1.86098 7.75583 1.88189C7.99898 1.9028 8.22004 2.02825 8.36372 2.22689L9.56846 3.94144L13.4921 0.230072C13.8127 -0.0731099 14.3432 -0.0731099 14.6637 0.230072C14.9842 0.533254 14.9842 1.03507 14.6637 1.33825L10.0216 5.72916C9.84477 5.89644 9.61267 5.98007 9.35846 5.95916C9.1153 5.93825 8.89425 5.8128 8.75056 5.61416L7.54582 3.89962L4.7274 6.56553C4.56161 6.72235 4.35161 6.79553 4.14161 6.79553Z'
                    fill='white'
                  />
                  <path
                    d='M14.0893 3.65909C13.6361 3.65909 13.2603 3.30364 13.2603 2.875V1.56818H11.8788C11.4256 1.56818 11.0498 1.21273 11.0498 0.784091C11.0498 0.355455 11.4256 0 11.8788 0H14.0893C14.5424 0 14.9182 0.355455 14.9182 0.784091V2.875C14.9182 3.30364 14.5424 3.65909 14.0893 3.65909Z'
                    fill='white'
                  />
                </svg>
              </div>
              <Link to='/storeList'>
                <Shop />
              </Link>
              <Link  onClick={() => checkUserLogin('/account')}>
                <UserCircle />
              </Link>             
              <Link onClick={() => checkUserLogin('/checkout')}>
                <Cart />
              </Link>
            </div>
          </div>
        </div>
      </header>
      {showDetails && (
        <>
          <div
            ref={detailsRef}
            className='absolute right-4 top-[120px] z-50 w-[275px] rounded-[10px] p-4 text-center'
            style={{ backgroundColor: '#f3f1de' }}
          >
            <GoldRate></GoldRate>
          </div>
        </>
      )}
      {isLoggedIn && (
        <>
          <div
            ref={detailsRef}
            className='absolute right-4 top-[120px] z-50 w-[370px] rounded-[10px] bg-white px-8 py-3'
          >
            <div className='text-bold text-[25px]'>Your Account</div>
            <div className='text-medium text-[15px]'>
              Access account & mange your orders
            </div>
            <div className='mt-2 flex gap-3'>
              <button
                type='button'
                className='block w-full rounded-[5px] p-2 text-[14px] font-bold text-white'
                style={{ backgroundColor: data.color.ctaPrimary }}
                onClick={() => navigate('/register')}
              >
                SIGN UP
              </button>
              <button
                type='button'
                className='block w-full rounded-[5px] p-2 text-[14px] font-bold text-black'
                style={{ border: `1px solid ${data.color.secondaryColor}` }}
                onClick={() => navigate('/login')}
              >
                LOGIN
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
