import React, { useState } from 'react';
import SortMobileFilter from './sortMobileFilter';
import PriceMobileFilter from './priceMobileFilter';
import { useGetBackgroundColor } from "../../../../src/api/index";
import {MobileMenu} from 'components/index';

const MobileFilter = ({sortMobileFilter ,metalTypeData, metalCaratData, ocassianData, genderData, metalColorData,gemstoneData, onFilterChange,filteredData}) => {
  const [isOpenSortFilter, setIsOpenSortFilter] = useState(false);
  const [isOpenPriceFilter, setIsOpenPriceFilter] = useState(false);
  const [isOpenCategoriesFilter, setIsOpenCategoriesFilter] = useState(false);
  const { data } = useGetBackgroundColor({ onLoad: true });

  const sortFilter = (filterData) => {
    sortMobileFilter(filterData)
  }
  const closeModel = () => {
    setIsOpenSortFilter(false);
    setIsOpenPriceFilter(false);
    setIsOpenCategoriesFilter(false);
  }
  return (
  <>
      <div className="text-white flex items-center p-2 w-full h-12 justify-between fixed bottom-0 left-0 z-50 px-5" style={{backgroundColor : data.color.shade1}}>
      {/* Categories */}
      <div className="flex items-center space-x-2 cursor-pointer" onClick={() => setIsOpenCategoriesFilter(true)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path d="M9.375 14.2188H5.625C2.23125 14.2188 0.78125 12.7688 0.78125 9.375V5.625C0.78125 2.23125 2.23125 0.78125 5.625 0.78125H9.375C12.7688 0.78125 14.2188 2.23125 14.2188 5.625V9.375C14.2188 12.7688 12.7688 14.2188 9.375 14.2188ZM5.625 1.71875C2.74375 1.71875 1.71875 2.74375 1.71875 5.625V9.375C1.71875 12.2563 2.74375 13.2812 5.625 13.2812H9.375C12.2563 13.2812 13.2812 12.2563 13.2812 9.375V5.625C13.2812 2.74375 12.2563 1.71875 9.375 1.71875H5.625Z" fill="white"/>
      <path d="M7.5 14.2188C7.24375 14.2188 7.03125 14.0063 7.03125 13.75V1.25C7.03125 0.99375 7.24375 0.78125 7.5 0.78125C7.75625 0.78125 7.96875 0.99375 7.96875 1.25V13.75C7.96875 14.0063 7.75625 14.2188 7.5 14.2188Z" fill="white"/>
      <path d="M7.5 6.40625H1.25C0.99375 6.40625 0.78125 6.19375 0.78125 5.9375C0.78125 5.68125 0.99375 5.46875 1.25 5.46875H7.5C7.75625 5.46875 7.96875 5.68125 7.96875 5.9375C7.96875 6.19375 7.75625 6.40625 7.5 6.40625Z" fill="white"/>
      <path d="M13.75 9.53125H7.5C7.24375 9.53125 7.03125 9.31875 7.03125 9.0625C7.03125 8.80625 7.24375 8.59375 7.5 8.59375H13.75C14.0062 8.59375 14.2188 8.80625 14.2188 9.0625C14.2188 9.31875 14.0062 9.53125 13.75 9.53125Z" fill="white"/>
      </svg>
      <span className="text-sm">CATEGORIES</span>
      </div>

      {/* Sort */}
      <div className="flex items-center space-x-2 cursor-pointer" onClick={() => setIsOpenSortFilter(!isOpenSortFilter)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g clip-path="url(#clip0_179_8734)">
      <path d="M11.25 1.5H0.75M8.25 4.5H0.75M11.25 7.5H0.75M8.25 10.5H0.75" stroke="white" stroke-width="2" stroke-linecap="round"/>
      </g>
      <defs>
      <clipPath id="clip0_179_8734">
      <rect width="12" height="12" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      <span className="text-sm">SORT</span>
      </div>

      {/* Filter with Badge */}
      <div className="flex items-center space-x-2 cursor-pointer relative" onClick={() => setIsOpenPriceFilter(!isOpenPriceFilter)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M16.5 13.125H11.25" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.75 13.125H1.5" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.5 4.875H14.25" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.75 4.875H1.5" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.25 10.875H9.75C10.575 10.875 11.25 11.25 11.25 12.375V13.875C11.25 15 10.575 15.375 9.75 15.375H5.25C4.425 15.375 3.75 15 3.75 13.875V12.375C3.75 11.25 4.425 10.875 5.25 10.875Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.25 2.625H12.75C13.575 2.625 14.25 3 14.25 4.125V5.625C14.25 6.75 13.575 7.125 12.75 7.125H8.25C7.425 7.125 6.75 6.75 6.75 5.625V4.125C6.75 3 7.425 2.625 8.25 2.625Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
        <span className="text-sm">FILTER</span>

        {/* Badge */}
        <div className='flex h-[18px] items-center justify-center rounded-[4px] px-1 text-[12px] font-extrabold' style={{backgroundColor : data.color.shade2}}>
        {filteredData ? filteredData.length : 0}
        </div>
      </div>
      </div>
      { isOpenSortFilter && <SortMobileFilter closeModel={closeModel} filter={sortFilter}></SortMobileFilter> }
      { isOpenPriceFilter && <PriceMobileFilter 
                    closeModel={closeModel} 
                    metalTypeData={metalTypeData}
                    metalCaratData={metalCaratData} 
                    ocassianData={ocassianData} 
                    genderData={genderData} 
                    metalColorData={metalColorData}
                    gemstoneData={gemstoneData}
                    onFilterChange={onFilterChange}
                    filteredData={filteredData} />}
      { isOpenCategoriesFilter && <MobileMenu closeModel={closeModel} categoryFilter={isOpenCategoriesFilter}></MobileMenu> }

  </>
  );
};

export default MobileFilter;
