// features/login/loginSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { actionConstants } from 'constants/actionConstants';

const initialState = {
    whatsappNumber: null,
    logged: false,
    trial: false,
    access: '',
    message: '',
    user: null
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setWhatsappNumber(state, action) {
            state.whatsappNumber = action.payload;
        },
        // Uncomment and use these actions as needed
        // login(state) {
        //     state.trial = true;
        //     state.logged = false;
        //     state.access = '';
        //     state.message = '';
        //     state.user = null;
        // },
        // loginSuccess(state, action) {
        //     state.trial = false;
        //     state.logged = true;
        //     state.access = '';
        //     state.message = 'login Success';
        //     state.user = action.payload;
        // },
        // loginFail(state, action) {
        //     state.trial = false;
        //     state.logged = false;
        //     state.access = '';
        //     state.message = action.payload;
        //     state.user = null;
        // },
        // logout(state) {
        //     state.trial = false;
        //     state.logged = false;
        //     state.access = '';
        //     state.message = '';
        //     state.user = null;
        // },
    },
    extraReducers: (builder) => {
        // Handle other action types or async actions here if needed
    }
});

export const { setWhatsappNumber } = loginSlice.actions;
// Export other actions as needed

export default loginSlice.reducer;
