import React from 'react';
import { useQuery, gql } from '@apollo/client';

const GET_HEADER_FOOTER_DATA = gql`
  query GetHeaderFooterData {
    header {
      title
      links
    }
    footer {
      text
      links
    }
  }
`;

const withData = (WrappedComponent) => {
    return (props) => {
        const { loading, error, data } = useQuery(GET_HEADER_FOOTER_DATA);

        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error loading data</div>;

        return <WrappedComponent {...props} data={data} />;
    };
};
 
 
export default withData;
