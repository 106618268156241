import React, { useState } from "react";
import './checkout.css';
import { useNavigate  } from 'react-router-dom';
import { useRemoveCheckoutLine } from "../../../src/pages/products/singleProductDetails/cartAPI";
import { useGetUserDetailsData } from "api";
const OrderSummaryProducts = ({products, isCart}) =>{
  const navigate = useNavigate();
  const removeCheckoutLine = useRemoveCheckoutLine();
  const { data: userData } = useGetUserDetailsData({ onLoad: true });
  
  const removeFromCart =async (product)=>{   
    let userDetails = userData;
      let data = await removeCheckoutLine(userDetails?.checkout?.id,product?.id)     
      window.location.reload();
  }
  let subtotal = 0;
  if(!isCart){
    subtotal = products?.reduce(
      (total, product) => total + product?.price,
      0
    );
  }
    if(products?.length===0) navigate({pathname: `/checkout`});
    return(
    <>
    {isCart && products?.length && products?.map(product => (
    <div key={product?.id}>
    <div className='relative mb-4 flex items-center justify-between product-card p-4 shadow'>
        {product && (<><img
          src={product?.imgSrc}
          alt={product?.name}
          className='h-28 w-24 product-img'
        />
        <div className='ml-4 flex-1'>
          <div className='font-semibold'>{product?.name}</div>
          <p className='product-price font-bold mt-1'>₹{product?.price?.toLocaleString()}</p>
          <p className='product-details font-medium mt-2'>
            Size: {product?.size} &nbsp; Quantity: {product?.quantity}
          </p>
          <p className='product-details mt-1'>
            Est. Delivery by {product?.estDelivery}
          </p>
        </div>
        <button className='text-red-500 absolute top-3 right-5' onClick={()=>removeFromCart(product)}>
          {/* Close/Remove Icon */}
          <svg
            width='24'
            height='24'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10.0003 1.66667C5.40866 1.66667 1.66699 5.40833 1.66699 10C1.66699 14.5917 5.40866 18.3333 10.0003 18.3333C14.592 18.3333 18.3337 14.5917 18.3337 10C18.3337 5.40833 14.592 1.66667 10.0003 1.66667ZM12.8003 11.9167C13.042 12.1583 13.042 12.5583 12.8003 12.8C12.6753 12.925 12.517 12.9833 12.3587 12.9833C12.2003 12.9833 12.042 12.925 11.917 12.8L10.0003 10.8833L8.08366 12.8C7.95866 12.925 7.80033 12.9833 7.64199 12.9833C7.48366 12.9833 7.32533 12.925 7.20033 12.8C6.95866 12.5583 6.95866 12.1583 7.20033 11.9167L9.11699 10L7.20033 8.08333C6.95866 7.84167 6.95866 7.44167 7.20033 7.2C7.44199 6.95833 7.84199 6.95833 8.08366 7.2L10.0003 9.11667L11.917 7.2C12.1587 6.95833 12.5587 6.95833 12.8003 7.2C13.042 7.44167 13.042 7.84167 12.8003 8.08333L10.8837 10L12.8003 11.9167Z'
              fill='#292D32'
            />
          </svg>
        </button></>)}
      </div>
     </div>
    ))}
    {!isCart && products?.length!==0 && 
    <div>
    {products?.length>0 && products?.map(product => (
    <div key={product?.id}>
    <div className='relative mb-4 flex items-center justify-between product-card-order p-4 shadow'>
        {product && (<><img
          src={product?.imgSrc}
          alt={product?.name}
          className='h-28 w-24 product-img'
        />
        <div className='ml-4 flex-1'>
          <div className='font-semibold'>{product?.name}</div>
          {/* <p className="text-sm">SKU: GC01038-8YP9RS</p> */}
          <p className='product-details font-medium mt-2'>
            Size: {product?.size} &nbsp; Quantity: {product?.quantity}
          </p>
          <p className='product-details mt-1'>
            Est. Delivery by {product?.estDelivery}
          </p>
          <p className='product-price font-bold'>₹{product?.price?.toLocaleString()}</p>
        </div>
        <button className='text-red-500 absolute top-3 right-5' onClick={()=>removeFromCart(product)}>
          {/* Close/Remove Icon */}
          <svg
            width='24'
            height='24'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10.0003 1.66667C5.40866 1.66667 1.66699 5.40833 1.66699 10C1.66699 14.5917 5.40866 18.3333 10.0003 18.3333C14.592 18.3333 18.3337 14.5917 18.3337 10C18.3337 5.40833 14.592 1.66667 10.0003 1.66667ZM12.8003 11.9167C13.042 12.1583 13.042 12.5583 12.8003 12.8C12.6753 12.925 12.517 12.9833 12.3587 12.9833C12.2003 12.9833 12.042 12.925 11.917 12.8L10.0003 10.8833L8.08366 12.8C7.95866 12.925 7.80033 12.9833 7.64199 12.9833C7.48366 12.9833 7.32533 12.925 7.20033 12.8C6.95866 12.5583 6.95866 12.1583 7.20033 11.9167L9.11699 10L7.20033 8.08333C6.95866 7.84167 6.95866 7.44167 7.20033 7.2C7.44199 6.95833 7.84199 6.95833 8.08366 7.2L10.0003 9.11667L11.917 7.2C12.1587 6.95833 12.5587 6.95833 12.8003 7.2C13.042 7.44167 13.042 7.84167 12.8003 8.08333L10.8837 10L12.8003 11.9167Z'
              fill='#292D32'
            />
          </svg>
        </button></>)}
    </div>
    <hr className="divider-horizontal-order-summary mb-3"></hr>
    </div>
    ))}
    <div className="py-2 text-center">
    <div className="order-summary-text">
      {/* Subtotal, Shipping, and Total Cost */}
      <div className="flex justify-between mb-2 font-semibold text-sm">
          <p>Subtotal</p>
          <p>₹{subtotal?.toLocaleString()}</p>
        </div>
        <div className="flex justify-between mb-2 font-semibold text-sm">
          <p>SHIPPING CHARGES (STANDARD)</p>
          <p>Free</p>
        </div>
        <hr className="divider-horizontal-order-summary"></hr>
        <div className="flex justify-between mb-2 font-semibold text-sm mt-4">
          <p>TOTAL COST</p>
          <p>₹{subtotal?.toLocaleString()}</p>
        </div>
    </div>

      {/* Need Help Section */}
      <div className="mt-[5%]">
        <p className="text-lg font-semibold">Need Help?</p>
        <div className="flex justify-center space-x-6 mt-4">
          {/* Call Icon */}
          <div className="flex flex-col items-center">
          <div className="bg-black rounded-full p-1.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
          <path d="M9.66875 13.0812L8.05 14.7C7.70875 15.0412 7.16625 15.0412 6.81625 14.7087C6.72 14.6125 6.62375 14.525 6.5275 14.4287C5.62625 13.5187 4.8125 12.565 4.08625 11.5675C3.36875 10.57 2.79125 9.5725 2.37125 8.58375C1.96 7.58625 1.75 6.6325 1.75 5.7225C1.75 5.1275 1.855 4.55875 2.065 4.03375C2.275 3.5 2.6075 3.01 3.07125 2.5725C3.63125 2.02125 4.24375 1.75 4.89125 1.75C5.13625 1.75 5.38125 1.8025 5.6 1.9075C5.8275 2.0125 6.02875 2.17 6.18625 2.3975L8.21625 5.25875C8.37375 5.4775 8.4875 5.67875 8.56625 5.87125C8.645 6.055 8.68875 6.23875 8.68875 6.405C8.68875 6.615 8.6275 6.825 8.505 7.02625C8.39125 7.2275 8.225 7.4375 8.015 7.6475L7.35 8.33875C7.25375 8.435 7.21 8.54875 7.21 8.68875C7.21 8.75875 7.21875 8.82 7.23625 8.89C7.2625 8.96 7.28875 9.0125 7.30625 9.065C7.46375 9.35375 7.735 9.73 8.12 10.185C8.51375 10.64 8.93375 11.1037 9.38875 11.5675C9.47625 11.655 9.5725 11.7425 9.66 11.83C10.01 12.1712 10.0188 12.7312 9.66875 13.0812Z" fill="white"/>
          <path d="M19.2241 16.0389C19.2241 16.2839 19.1804 16.5377 19.0929 16.7827C19.0666 16.8527 19.0404 16.9227 19.0054 16.9927C18.8566 17.3077 18.6641 17.6052 18.4104 17.8852C17.9816 18.3577 17.5091 18.6989 16.9754 18.9177C16.9666 18.9177 16.9579 18.9264 16.9491 18.9264C16.4329 19.1364 15.8729 19.2502 15.2691 19.2502C14.3766 19.2502 13.4229 19.0402 12.4166 18.6114C11.4104 18.1827 10.4041 17.6052 9.40664 16.8789C9.06539 16.6252 8.72414 16.3714 8.40039 16.1002L11.2616 13.2389C11.5066 13.4227 11.7254 13.5627 11.9091 13.6589C11.9529 13.6764 12.0054 13.7027 12.0666 13.7289C12.1366 13.7552 12.2066 13.7639 12.2854 13.7639C12.4341 13.7639 12.5479 13.7114 12.6441 13.6152L13.3091 12.9589C13.5279 12.7402 13.7379 12.5739 13.9391 12.4689C14.1404 12.3464 14.3416 12.2852 14.5604 12.2852C14.7266 12.2852 14.9016 12.3202 15.0941 12.3989C15.2866 12.4777 15.4879 12.5914 15.7066 12.7402L18.6029 14.7964C18.8304 14.9539 18.9879 15.1377 19.0841 15.3564C19.1716 15.5752 19.2241 15.7939 19.2241 16.0389Z" fill="white"/>
          </svg>
          </div>
          <p className="mt-2 text-sm">Call</p>
          </div>

          {/* WhatsApp Icon */}
          <div className="flex flex-col items-center">
          <div className="bg-black rounded-full p-1.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M0 20L1.40583 14.8642C0.538332 13.3608 0.0824998 11.6567 0.0833332 9.90917C0.0858331 4.44583 4.53166 0 9.99414 0C12.645 0.000833333 15.1333 1.03333 17.005 2.90667C18.8758 4.78 19.9058 7.27 19.905 9.91833C19.9025 15.3825 15.4566 19.8283 9.99414 19.8283C8.33581 19.8275 6.70165 19.4117 5.25415 18.6217L0 20ZM5.49749 16.8275C6.89415 17.6567 8.22748 18.1533 9.99081 18.1542C14.5308 18.1542 18.2291 14.4592 18.2316 9.91667C18.2333 5.365 14.5525 1.675 9.99748 1.67333C5.45415 1.67333 1.75833 5.36833 1.75666 9.91C1.75583 11.7642 2.29916 13.1525 3.21166 14.605L2.37916 17.645L5.49749 16.8275ZM14.9866 12.2742C14.925 12.1708 14.76 12.1092 14.5116 11.985C14.2641 11.8608 13.0466 11.2617 12.8191 11.1792C12.5925 11.0967 12.4275 11.055 12.2616 11.3033C12.0966 11.5508 11.6216 12.1092 11.4775 12.2742C11.3333 12.4392 11.1883 12.46 10.9408 12.3358C10.6933 12.2117 9.89498 11.9508 8.94915 11.1067C8.21331 10.45 7.71582 9.63917 7.57165 9.39083C7.42748 9.14333 7.55665 9.00917 7.67998 8.88583C7.79165 8.775 7.92748 8.59667 8.05165 8.45167C8.17748 8.30833 8.21832 8.205 8.30165 8.03917C8.38415 7.87417 8.34332 7.72917 8.28082 7.605C8.21832 7.48167 7.72332 6.2625 7.51748 5.76667C7.31582 5.28417 7.11165 5.34917 6.95998 5.34167L6.48499 5.33333C6.31999 5.33333 6.05165 5.395 5.82499 5.64333C5.59832 5.89167 4.95832 6.49 4.95832 7.70917C4.95832 8.92833 5.84582 10.1058 5.96915 10.2708C6.09332 10.4358 7.71498 12.9375 10.1991 14.01C10.79 14.265 11.2516 14.4175 11.6108 14.5317C12.2041 14.72 12.7441 14.6933 13.1708 14.63C13.6466 14.5592 14.6358 14.0308 14.8425 13.4525C15.0491 12.8733 15.0491 12.3775 14.9866 12.2742Z" fill="white"/>
          </svg>
          </div>
            <p className="mt-2 text-sm">Chat</p>
          </div>

          {/* Email Icon */}
          {/* <div className="flex flex-col items-center">
          <div className="bg-black rounded-full p-1.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M14.167 17.7087H5.83366C2.79199 17.7087 1.04199 15.9587 1.04199 12.917V7.08366C1.04199 4.04199 2.79199 2.29199 5.83366 2.29199H14.167C17.2087 2.29199 18.9587 4.04199 18.9587 7.08366V12.917C18.9587 15.9587 17.2087 17.7087 14.167 17.7087ZM5.83366 3.54199C3.45033 3.54199 2.29199 4.70033 2.29199 7.08366V12.917C2.29199 15.3003 3.45033 16.4587 5.83366 16.4587H14.167C16.5503 16.4587 17.7087 15.3003 17.7087 12.917V7.08366C17.7087 4.70033 16.5503 3.54199 14.167 3.54199H5.83366Z" fill="white"/>
          <path d="M9.99998 10.725C9.29998 10.725 8.59165 10.5083 8.04998 10.0666L5.44164 7.98331C5.17498 7.76664 5.12498 7.37497 5.34165 7.10831C5.55831 6.84164 5.94998 6.79164 6.21665 7.00831L8.82497 9.09164C9.45831 9.59998 10.5333 9.59998 11.1666 9.09164L13.775 7.00831C14.0416 6.79164 14.4416 6.83331 14.65 7.10831C14.8666 7.37497 14.825 7.77498 14.55 7.98331L11.9416 10.0666C11.4083 10.5083 10.7 10.725 9.99998 10.725Z" fill="white"/>
          </svg>
          </div>
            <p className="mt-2 text-sm">Email</p>
          </div> */}
        </div>
      </div>
    </div>
    </div>}
    </>
    )
}
export default OrderSummaryProducts;
