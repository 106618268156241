import { gql } from "@apollo/client";
import {
  BASIC_PRODUCT_FIELDS,
  SELECTED_ATTRIBUTE,
  PRICE,
  PRODUCT_VARIANT_FIELDS,
  PRODUCT_PRICING_FIELD,
} from "./fragments";

export const GET_STORE_DETAILS = gql`
  query storeDetails($domain: String!) {
    storeDetails(domain: { domain: $domain }) {
      id
      name
      domain
      storePhoneNumber
      userId
      storeLogo
      user {
        id
        isAccountVerified
        note
        brandColor
        brandFont
        facebookLink
        youtubeLink
        instagramLink
        phoneNumber
        showProductPrices
        addresses {
          id
          firstName
          lastName
          companyName
          streetAddress1
          streetAddress2
          city
          cityArea
          postalCode
          country {
            code
            country
          }
          countryArea
          phone
          latitude
          longitude
          isDefaultShippingAddress
          isDefaultBillingAddress
        }
      }
      metalRates {
        id
        name
        gold24kRate
        gold24kPremium
        gold22kRate
        gold22kPremium
        gold20kRate
        gold20kPremium
        gold18kRate
        gold18kPremium
        gold14kRate
        gold14kPremium
        gold9kRate
        gold9kPremium
        silver999kRate
        silver999kPremium
        silver990kRate
        silver990kPremium
        silver970kRate
        silver970kPremium
        silver925kRate
        silver925kPremium
        silver700kRate
        silver700kPremium
        silver600kRate
        silver600kPremium
        silver500kRate
        silver500kPremium
        silver450kRate
        silver450kPremium
        platinum999kRate
        platinum999kPremium
        platinum950kRate
        platinum950kPremium
        platinum900kRate
        platinum900kPremium
        diamondRate
        designBankDiamondRate
        updatedAt
      }
    }
  }
`;
export const GET_SHOP = gql`
  query GetShop {
    shop {
      displayGrossPrices
      defaultCountry {
        code
        country
        __typename
      }
      countries {
        country
        code
        __typename
      }
      geolocalization {
        country {
          code
          country
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_PUBLIC_DETAILS = gql`
  fragment Address on Address {
    id
    firstName
    lastName
    companyName
    streetAddress1
    streetAddress2
    city
    postalCode
    country {
      code
      country
      __typename
    }
    countryArea
    phone
    isDefaultBillingAddress
    isDefaultShippingAddress
    __typename
  }

  fragment User on User {
    id
    email
    firstName
    lastName
    isStaff
    defaultShippingAddress {
      ...Address
      __typename
    }
    defaultBillingAddress {
      ...Address
      __typename
    }
    addresses {
      ...Address
      __typename
    }
    __typename
  }

  query UserDetails {
    me {
      ...User
      __typename
    }
  }
`;
export const GET_MENU_ITEMS = gql`
  fragment MainMenuSubItem on MenuItem {
    id
    name
    category {
      id
      name
      __typename
    }
    url
    collection {
      id
      name
      __typename
    }
    page {
      slug
      __typename
    }
    parent {
      id
      __typename
    }
    __typename
  }

  query MainMenu {
    shop {
      navigation {
        main {
          id
          items {
            ...MainMenuSubItem
            children {
              ...MainMenuSubItem
              children {
                ...MainMenuSubItem
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_MENU_SUBITEMS = gql`
  fragment SecondaryMenuSubItem on MenuItem {
    id
    name
    category {
      id
      name
      __typename
    }
    url
    collection {
      id
      name
      __typename
    }
    page {
      slug
      __typename
    }
    __typename
  }

  query SecondaryMenu {
    shop {
      navigation {
        secondary {
          items {
            ...SecondaryMenuSubItem
            children {
              ...SecondaryMenuSubItem
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_BANNERS = gql`
  query publishedBanners($domain: String!) {
    publishedBannerDetails(domain: { domain: $domain }) {
      id
      user {
        id
      }
      prefered
      image
      published
      ctaUrl
      __typename
    }
  }
`;

export const GET_ALL_CATEGORIES = gql`
  query getCategory($productsFirst: Int) {
   categories(first: 100, level: 0) {
    totalCount
    edges {
      node {
        id
        name
        description
        backgroundImage {
                url
              }
        children(first: 100) {
          totalCount
          edges {
            node {
              id
              name
              description
              seoDescription
              level
              backgroundImage {
                url
              }
              products(first: $productsFirst) {
                edges{
                  node{
                    id
                    name
                    images{
                      url
                    }
                  }
                }
                totalCount
              }
            }
          }
        }
      }
    }
  }
}`;

export const GET_FAQ = gql`
  query getFAQs {
    faqs {
      id
      logo
      question
      answer
    }
  }
`;

export const GET_COLLECTIONS = gql`
  query collections($after: String, $afterProduct: String) {
    collections(first: 100, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          seoTitle
          seoDescription
          id
          name
          description
          descriptionJson
          publicationDate
          slug
          backgroundImage {
            url
            alt
          }
          products(first: 100, after: $afterProduct) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                seoTitle
                seoDescription
                name
                description
                descriptionJson
                publicationDate
                slug
                category {
                  seoTitle
                  id
                  seoDescription
                  name
                  description
                  descriptionJson
                  slug
                }
              }
              cursor
            }
            totalCount
          }
        }
        cursor
      }
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  fragment BasicProductFields on Product {
    id
    name
    makingDays
    priceBreakdown
    pricingStatus
    productDisplayPrice
    images{
      id
      url
    }
    __typename
  }

  fragment SelectedAttributeFields on SelectedAttribute {
    attribute {
      id
      name
      slug
      __typename
    }
    values {
      id
      name
      slug
      __typename
    }
    __typename
  }

  fragment Price on TaxedMoney {
    gross {
      amount
      currency
      __typename
    }
    net {
      amount
      currency
      __typename
    }
    __typename
  }

  fragment ProductVariantFields on ProductVariant {
    id
    sku
    name
    isAvailable
    quantityAvailable(countryCode: $countryCode)
    images {
      id
      url
      alt
      __typename
    }
    pricing {
      onSale
      priceUndiscounted {
        ...Price
        __typename
      }
      price {
        ...Price
        __typename
      }
      __typename
    }
    attributes {
      attribute {
        id
        name
        slug
        __typename
      }
      values {
        id
        name
        value: name
        __typename
      }
      __typename
    }
    __typename
  }

  fragment ProductPricingField on Product {
    pricing {
      onSale
      priceRangeUndiscounted {
        start {
          ...Price
          __typename
        }
        stop {
          ...Price
          __typename
        }
        __typename
      }
      priceRange {
        start {
          ...Price
          __typename
        }
        stop {
          ...Price
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }

  query ProductDetails($id: ID!, $countryCode: CountryCode) {
    product(id: $id) {
      childProduct{
        ...BasicProductFields
        ...ProductPricingField
        descriptionJson
        category {
          id
          name
          level
          slug
          parent{
            id
            name
            slug
          }
          products(first: 3) {
            edges {
              node {
                ...BasicProductFields
                ...ProductPricingField
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        images {
          id
          alt
          url
          __typename
        }
        attributes {
          ...SelectedAttributeFields
          __typename
        }
        variants {
          ...ProductVariantFields
          __typename
        }
        seoDescription
        seoTitle
        isAvailable
        isAvailableForPurchase
        availableForPurchase
      }
      ...BasicProductFields
      ...ProductPricingField
      descriptionJson
      category {
        id
        name
        level
        slug
        parent{
          id
          name
          slug
        }
        products(first: 3) {
          edges {
            node {
              ...BasicProductFields
              ...ProductPricingField
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      images {
        id
        alt
        url
        __typename
      }
      attributes {
        ...SelectedAttributeFields
        __typename
      }
      variants {
        ...ProductVariantFields
        __typename
      }
      seoDescription
      seoTitle
      isAvailable
      isAvailableForPurchase
      availableForPurchase
      __typename
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  ${BASIC_PRODUCT_FIELDS}
  ${PRICE}
  ${PRODUCT_PRICING_FIELD}
  query allProductsData(
    $after: String
    $search: String
    $categories: [ID]
    $collections: [ID]
    $first: Int
  ) {
    products(
      filter: {
        isPublished: true
        hasCategory: true
        search: $search
        categories: $categories  # This field will be included or omitted based on the variable's value
        collections: $collections
      }
      first: $first
      after: $after
    ) {
      edges {
        node {
          ...BasicProductFields
          ...ProductPricingField
          category {
            id
            name
            __typename
          }
          isPublished
          isAvailableForPurchase
          attributes {
            attribute {
              id
              name
              slug
            }
            values {
              id
              name
              slug
              inputType
            }
          }
          __typename
        }
        __typename
      }
      totalCount
      __typename
    }
  }
`;


// All products
export const GET_ALL_PRODUCTS_FILTERED = gql`
  fragment BasicProductFields on Product {
  id
  name
  pricingStatus
  makingDays
  priceBreakdown
  defaultVariant {
    id
    name
    attributes {
      attribute {
        id
        name
        slug
      }
      values {
        id
        name
        slug
      }
    }
  }
  productDisplayPrice
  subcategory: category {
    id
    name
    seoDescription
    level
    backgroundImage {
      url
    }
    parent {
      level
      id
      name
      seoDescription
      backgroundImage {
        url
      }
    }
    __typename
  }
  collections {
    id
    name
    __typename
  }
  productType {
    id
    name
    __typename
  }
  images {
    id
    url
  }
  __typename
}

fragment Price on TaxedMoney {
  gross {
    amount
    currency
    __typename
  }
  net {
    amount
    currency
    __typename
  }
  __typename
}
fragment ProductPricingField on Product {
  pricing {
    onSale
    priceRangeUndiscounted {
      start {
        ...Price
        __typename
      }
      stop {
        ...Price
        __typename
      }
      __typename
    }
    priceRange {
      start {
        ...Price
        __typename
      }
      stop {
        ...Price
        __typename
      }
      __typename
    }
    __typename
  }
  __typename
}
query allProductsData(
  #$search: String!
  #$isPublished: Boolean!
  $filter: ProductFilterInput #$attribute_slug: String! #$attribute_value: [String] #$categories: [ID]! # $collections: [ID]! # $productType: ID!
) {
  products(
    filter: $filter
    #{
    #search:$search # user search text
    # attributes:[{
    #     slug: $attribute_slug# attribute slug
    #    values:$attribute_value # attribute value
    # }]
    # categories: $categories # category id
    # collections:$collections # collection id
    #productType:$productType # product type id
    #}
    first: 100
  ) {
    edges {
      node {
        ...BasicProductFields
        ...ProductPricingField
        metadata {
          key
          value
        }
        category {
          id
          name
          seoDescription
          __typename
        }
        isPublished
        isAvailableForPurchase
        attributes {
          attribute {
            id
            name
            slug
          }
          values {
            id
            name
            slug
            inputType
          }
        }
        __typename
      }
      __typename
    }
    totalCount
    __typename
  }
}
`;
export const GET_FEATURED_PRODUCTS_LIST = gql`
  query FeaturedProducts {
    products(first: 50) {
      totalCount
      edges {
        node {
          ...BasicProductFields
          ...ProductPricingField
          category {
            id
            name
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_FEATURED_PRODUCTS = gql`
  fragment BasicProductFields on Product {
    id
    name
    images{
      id
      url
    }
    __typename
  }

  fragment Price on TaxedMoney {
    gross {
      amount
      currency
      __typename
    }
    net {
      amount
      currency
      __typename
    }
    __typename
  }

  fragment ProductPricingField on Product {
    pricing {
      onSale
      priceRangeUndiscounted {
        start {
          ...Price
          __typename
        }
        stop {
          ...Price
          __typename
        }
        __typename
      }
      priceRange {
        start {
          ...Price
          __typename
        }
        stop {
          ...Price
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }

  query FeaturedProducts {
    shop {
      homepageCollection {
        id
        products(first: 20) {
          edges {
            node {
              ...BasicProductFields
              ...ProductPricingField
              category {
                id
                name
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const GET_PRODUCT_LIST = gql`
  query ProductsList {
    shop {
      description
      name
      homepageCollection {
        id
        backgroundImage {
          url
          __typename
        }
        name
        __typename
      }
      __typename
    }
    categories(level: 0, first: 4) {
      edges {
        node {
          id
          name
          backgroundImage {
            url
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const GET_ALL_ORDERS = gql`
  # Write your query or mutation here
  fragment User on User {
    id
    phoneNumber
    firstName
    lastName
    userPermissions {
      code
      name
      __typename
    }
    avatar {
      url
      __typename
    }
    orders(first: 100) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          created
          status
          lines {
            id
            productName
            variantName
            quantity
            quantityFulfilled
          }
          billingAddress {
            id
            firstName
            lastName
            streetAddress1
            companyName
            streetAddress2
            city
            cityArea
            postalCode
            country {
              code
              country
            }
            countryArea
            phone
            latitude
            longitude
            isDefaultShippingAddress
            isDefaultBillingAddress
            __typename
          }
        }
        cursor
      }
    }
    __typename
  }
  query userDetails {
    me {
      ...User
      __typename
    }
  }
`;

export const GET_USER_CHECKOUT_DETAILS = gql`
  fragment Price on TaxedMoney {
    gross {
      amount
      currency
      __typename
    }
    net {
      amount
      currency
      __typename
    }
    __typename
  }

  fragment ProductVariant on ProductVariant {
    id
    name
    sku
    quantityAvailable
    isAvailable
    pricing {
      onSale
      priceUndiscounted {
        ...Price
        __typename
      }
      price {
        ...Price
        __typename
      }
      __typename
    }
    attributes {
      attribute {
        id
        name
        __typename
      }
      values {
        id
        name
        value: name
        __typename
      }
      __typename
    }
    product {
      id
      name
      images{
      id
      url
    }
      productType {
        id
        isShippingRequired
        __typename
      }
      __typename
    }
    __typename
  }

  fragment CheckoutLine on CheckoutLine {
    id
    quantity
    totalPrice {
      ...Price
      __typename
    }
    variant {
      ...ProductVariant
      __typename
    }
    __typename
  }

  fragment Address on Address {
    id
    firstName
    lastName
    companyName
    streetAddress1
    streetAddress2
    city
    postalCode
    country {
      code
      country
      __typename
    }
    countryArea
    phone
    isDefaultBillingAddress
    isDefaultShippingAddress
    __typename
  }

  fragment ShippingMethod on ShippingMethod {
    id
    name
    price {
      currency
      amount
      __typename
    }
    __typename
  }

  fragment PaymentGateway on PaymentGateway {
    id
    name
    config {
      field
      value
      __typename
    }
    currencies
    __typename
  }

  fragment Checkout on Checkout {
    token
    id
    totalPrice {
      ...Price
      __typename
    }
    subtotalPrice {
      ...Price
      __typename
    }
    billingAddress {
      ...Address
      __typename
    }
    shippingAddress {
      ...Address
      __typename
    }

    availableShippingMethods {
      ...ShippingMethod
      __typename
    }
    shippingMethod {
      ...ShippingMethod
      __typename
    }
    shippingPrice {
      ...Price
      __typename
    }
    lines {
      ...CheckoutLine
      __typename
    }
    isShippingRequired
    discount {
      currency
      amount
      __typename
    }
    discountName
    translatedDiscountName
    voucherCode
    availablePaymentGateways {
      ...PaymentGateway
      __typename
    }
    __typename
  }

  query UserCheckoutDetails {
    me {
      id
      checkout {
        ...Checkout
        __typename
      }
      __typename
    }
  }
`;

export const CHECKOUT_PRODUCT_VARIANTS = gql`
  fragment Price on TaxedMoney {
    gross {
      amount
      currency
      __typename
    }
    net {
      amount
      currency
      __typename
    }
    __typename
  }

  fragment ProductVariant on ProductVariant {
    id
    name
    sku
    quantityAvailable
    isAvailable
    pricing {
      onSale
      priceUndiscounted {
        ...Price
        __typename
      }
      price {
        ...Price
        __typename
      }
      __typename
    }
    attributes {
      attribute {
        id
        name
        __typename
      }
      values {
        id
        name
        value: name
        __typename
      }
      __typename
    }
    product {
      id
      name
      images{
        id
        url
      }
      productType {
        id
        isShippingRequired
        __typename
      }
      __typename
    }
    __typename
  }

  query CheckoutProductVariants($ids: [ID]) {
    productVariants(ids: $ids, first: 100) {
      edges {
        node {
          ...ProductVariant
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;


export const GET_SUBCATEGORY_PRICING = gql`
query getCategoryPricing(
  $categoryId: ID
  $subcategoryId : ID
  $metalType : String
  $carat:Int
  $stoneType:String
) {
  categoryPrice(
    categoryId: $categoryId
    subcategoryId :$subcategoryId
    metalType: $metalType
    carat:$carat
    stoneType :$stoneType
  ){
    id
    makingCharge
    makingChargeMode
    designBankMakingCharge
    designBankMakingChargeMode
    wastageCharge
    wastageChargeMode
    designBankWastageCharge
    designBankWastageChargeMode
  }
}
`

export const DESIGN_BANK_PRODUCTS_IDS = gql`
query designBankProductIds{
  designBankProducts{
    childCategoryId
    parentCategoryId
    b2bProduct{
      id
      name
    }
  }
}
`;
// All Design Bank products
export const GET_ALL_B2B_PRODUCTS = gql`
  fragment BasicProductFields on Product {
    id
    name
    makingDays
    priceBreakdown
    productDisplayPrice
    category {
      id
      name
      parent{
        id
        name
      }
      __typename
    }
    collections {
      id
      name
      __typename
    }
    productType {
      id
      name
      __typename
    }
    images{
      id
      url
    }
    __typename
  }

  fragment Price on TaxedMoney {
    gross {
      amount
      currency
      __typename
    }
    net {
      amount
      currency
      __typename
    }
    __typename
  }
  fragment ProductPricingField on Product {
    pricing {
      onSale
      priceRangeUndiscounted {
        start {
          ...Price
          __typename
        }
        stop {
          ...Price
          __typename
        }
        __typename
      }
      priceRange {
        start {
          ...Price
          __typename
        }
        stop {
          ...Price
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
  query getDesignBankProducts(
    #$search: String!
    #$isPublished: Boolean!
    $filter: ProductFilterInput
  ) {
    products(
      filter: $filter
      first: 100
    ) {
      edges {
        node {
        childProduct{
          ...BasicProductFields
          ...ProductPricingField
          category {
            id
            name
            __typename
          }
          isPublished
          isAvailableForPurchase
          attributes {
            attribute {
              id
              name
              slug
            }
            values {
              id
              name
              slug
              inputType
            }
          }
        }
          ...BasicProductFields
          ...ProductPricingField
          category {
            id
            name
            __typename
          }
          isPublished
          isAvailableForPurchase
          attributes {
            attribute {
              id
              name
              slug
            }
            values {
              id
              name
              slug
              inputType
            }
          }
          __typename
        }
        __typename
      }
      totalCount
      __typename
    }
  }
`;


export const GET_B2B_PRODUCT_BY_ID = gql`
  fragment BasicProductFields on Product {
    id
    name
    makingDays
    priceBreakdown
    pricingStatus
    productDisplayPrice
    images{
      id
      url
    }
    __typename
  }

  fragment SelectedAttributeFields on SelectedAttribute {
    attribute {
      id
      name
      slug
      __typename
    }
    values {
      id
      name
      slug
      __typename
    }
    __typename
  }

  fragment Price on TaxedMoney {
    gross {
      amount
      currency
      __typename
    }
    net {
      amount
      currency
      __typename
    }
    __typename
  }

  fragment ProductVariantFields on ProductVariant {
    id
    sku
    name
    isAvailable
    quantityAvailable(countryCode: $countryCode)
    images {
      id
      url
      alt
      __typename
    }
    pricing {
      onSale
      priceUndiscounted {
        ...Price
        __typename
      }
      price {
        ...Price
        __typename
      }
      __typename
    }
    attributes {
      attribute {
        id
        name
        slug
        __typename
      }
      values {
        id
        name
        value: name
        __typename
      }
      __typename
    }
    __typename
  }

  fragment ProductPricingField on Product {
    pricing {
      onSale
      priceRangeUndiscounted {
        start {
          ...Price
          __typename
        }
        stop {
          ...Price
          __typename
        }
        __typename
      }
      priceRange {
        start {
          ...Price
          __typename
        }
        stop {
          ...Price
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }

  query B2BProductDetails($id: ID!, $countryCode: CountryCode) {
    product(id: $id) {
    childProduct{
        ...BasicProductFields
        ...ProductPricingField
        descriptionJson
        priceBreakdown
        category {
          id
          name
          level
          slug
          parent{
            id
            name
            slug
          }
          products(first: 3) {
            edges {
              node {
                ...BasicProductFields
                ...ProductPricingField
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        images {
          id
          alt
          url
          __typename
        }
        attributes {
          ...SelectedAttributeFields
          __typename
        }
        variants {
          ...ProductVariantFields
          __typename
        }
        seoDescription
        seoTitle
        isAvailable
        isAvailableForPurchase
        availableForPurchase
        __typename
    }
    ...BasicProductFields
    ...ProductPricingField
    descriptionJson
    priceBreakdown
    category {
      id
      name
      level
      slug
      parent{
        id
        name
        slug
      }
      products(first: 3) {
        edges {
          node {
            ...BasicProductFields
            ...ProductPricingField
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    images {
      id
      alt
      url
      __typename
    }
    attributes {
      ...SelectedAttributeFields
      __typename
    }
    variants {
      ...ProductVariantFields
      __typename
    }
    seoDescription
    seoTitle
    isAvailable
    isAvailableForPurchase
    availableForPurchase
    __typename
    }
  }
`;

export const GET_B2B_SELECTED_CATEGORIES = gql`
  query getB2BDBCategories($ids:[ID]!){
  categories(first:100,filter:{ids:$ids
  }){
    edges{
      node{
        id
        name
        backgroundImage{
          url
          alt
        }
        parent{
          id
          name
        }
      }
    }
  }
}
`;

export const GET_SECTION_VIDEO = gql`
query publishedBanners($domain: String!) {
  publishedMarqueeBannerDetails(domain: { domain: $domain }) {
    id
    file
    isVideo
    published
    ctaUrl
    __typename
  }
}`

export const GET_DBP_INVENTORY = gql`
query getDBPIdsWithAutoInventory{
  getDesignBankProductsId
}`;


export const DBP_PRODUCTS = gql`
query dbpProducts($ids: [ID]!) {
  products(first:100,filter: { ids: $ids }) {
    totalCount
    edges {
      node {
        id
        name
        images{
          id
          url
        }
        subcategory: category {
          id
          name
          level
          slug
          description
          backgroundImage {
            url
          }
          parent {
            id
            name
            slug
            description
            backgroundImage {
              url
            }
          }
        }
      }
    }
  }
}

`;

export const DBP_PRODUCTS_WITH_FILTER = gql`
fragment BasicProductFields on Product {
  id
  name
  pricingStatus
  makingDays
  priceBreakdown
  productDisplayPrice
  metadata{
    key
    value
  }
  subcategory: category {
    id
    name
    description
    seoDescription
    level
    slug
    backgroundImage {
      url
    }
    parent {
      id
      name
      description
      seoDescription
      slug
      backgroundImage {
        url
      }
    }
  }
  images {
    id
    url
  }
  __typename
}
query dbpProducts(
  #$search: String!
  #$isPublished: Boolean!
  $filter: ProductFilterInput
) {
  products(filter: $filter, first: 100) {
    edges {
      node {
        ...BasicProductFields
        metadata {
          key
          value
        }
        category {
          id
          name
          description
          seoDescription
          __typename
        }
        isPublished
        isAvailableForPurchase
        attributes {
          attribute {
            id
            name
            slug
          }
          values {
            id
            name
            slug
            inputType
          }
        }
        __typename
      }
      __typename
    }
    totalCount
    __typename
  }
}
`;


export const GET_CATEGORY_PRICING = gql`
  query getCategoryPricing($categoryId: ID!,$subcategoryId:ID! ,$metalType: String!, $carat: Int!, $stoneType: String!) {
    categoryPrice
    (
      categoryId: $categoryId
      subcategoryId:$subcategoryId
      metalType: $metalType
      carat: $carat
      stoneType: $stoneType
    ) {
      id
      metalType
      category {
        id
        name
        __typename
      }
      subcategory {
        id
        name
        __typename
      }
      carat
      stoneType
      hasDiamond
      hasOtherGemstone
      makingChargeMode
      makingCharge
      wastageChargeMode
      wastageCharge
      designBankMakingChargeMode
      designBankMakingCharge
      designBankWastageChargeMode
      designBankWastageCharge
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const GET_CATEGORY_PRICING_BY_CAT_SUBCAT = gql`
query getCategoryPricingByCatSubCat($categoryId: ID!, $subcategoryId: ID!) {
  categoryPricingByCategorySubcategory(
    categoryId: $categoryId
    subcategoryId: $subcategoryId
  ) {
    id
    metalType
    category {
      id
      name
      __typename
    }
    subcategory {
      id
      name
      __typename
    }
    carat
    stoneType
    hasDiamond
    hasOtherGemstone
    makingChargeMode
    makingCharge
    wastageChargeMode
    wastageCharge
    designBankMakingChargeMode
    designBankMakingCharge
    designBankWastageChargeMode
    designBankWastageCharge
    createdAt
    updatedAt
    __typename
  }
}
`

export const GET_USER_DETAILS = gql`
fragment ProductVariantType on ProductVariantType {
  id
  name
  sku
  quantityAvailable
  isAvailable
  images
  product {
    id
    name
    images {
      id
      url
    }
    thumbnail {
      url
      alt
      __typename
    }
    thumbnail2x: thumbnail(size: 510) {
      url
      __typename
    }
    makingDays
    productType {
      id
      isShippingRequired
      __typename
    }
    __typename
  }
  __typename
}
fragment Price on TaxedMoney {
  gross {
    amount
    currency
    __typename
  }
  net {
    amount
    currency
    __typename
  }
  __typename
}

fragment ProductVariant on ProductVariant {
  id
  name
  sku
  quantityAvailable
  isAvailable
  pricing {
    onSale
    priceUndiscounted {
      ...Price
      __typename
    }
    price {
      ...Price
      __typename
    }
    __typename
  }
  attributes {
    attribute {
      id
      name
      __typename
    }
    values {
      id
      name
      value: name
      __typename
    }
    __typename
  }
  product {
    id
    name
    images {
      id
      url
    }
    makingDays
    productType {
      id
      isShippingRequired
      __typename
    }
    __typename
  }
  __typename
}

fragment CheckoutLine on CheckoutLine {
  id
  quantity
  totalPrice {
    ...Price
    __typename
  }
  dbpVariantData {
    ...ProductVariantType
  }
  variant {
    ...ProductVariant
    __typename
  }
  __typename
}

fragment Address on Address {
  id
  firstName
  lastName
  companyName
  streetAddress1
  streetAddress2
  city
  postalCode
  country {
    code
    country
    __typename
  }
  countryArea
  phone
  isDefaultBillingAddress
  isDefaultShippingAddress
  __typename
}

fragment ShippingMethod on ShippingMethod {
  id
  name
  price {
    currency
    amount
    __typename
  }
  __typename
}

fragment PaymentGateway on PaymentGateway {
  id
  name
  config {
    field
    value
    __typename
  }
  currencies
  __typename
}

fragment Checkout on Checkout {
  token
  id
  totalPrice {
    ...Price
    __typename
  }
  subtotalPrice {
    ...Price
    __typename
  }
  billingAddress {
    ...Address
    __typename
  }
  shippingAddress {
    ...Address
    __typename
  }
  phoneNumber
  availableShippingMethods {
    ...ShippingMethod
    __typename
  }
  shippingMethod {
    ...ShippingMethod
    __typename
  }
  shippingPrice {
    ...Price
    __typename
  }
  lines {
    ...CheckoutLine
    __typename
  }
  isShippingRequired
  discount {
    currency
    amount
    __typename
  }
  discountName
  translatedDiscountName
  voucherCode
  availablePaymentGateways {
    ...PaymentGateway
    __typename
  }
  __typename
}
query me {
  me {
    id
    lastLogin
    phoneNumber
    email
    firstName
    lastName
    isActive
    dateJoined
    birthDate
    anniversaryDate
    gender
    defaultShippingAddress {
      id
      firstName
      lastName
      companyName
      streetAddress1
      streetAddress2
      city
      cityArea
      postalCode
      countryArea
      country {
        code
        country
      }
      phone
      isDefaultShippingAddress
      isDefaultBillingAddress
    }
    defaultBillingAddress {
      id
      firstName
      lastName
      companyName
      streetAddress1
      streetAddress2
      city
      cityArea
      postalCode
      countryArea
      country {
        code
        country
      }
      phone
      isDefaultShippingAddress
      isDefaultBillingAddress
    }
    addresses {
      id
      firstName
      lastName
      companyName
      streetAddress1
      streetAddress2
      city
      cityArea
      postalCode
      countryArea
      phone
      isDefaultShippingAddress
      isDefaultBillingAddress
    }
    checkout {
      ...Checkout
      metadata {
        key
        value
      }
    }
    orders(first: 100) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          created
          status
          languageCode
          trackingClientId
          billingAddress {
            id
            firstName
            lastName
            companyName
            streetAddress1
            streetAddress2
            city
            cityArea
            postalCode
            countryArea
            phone
            isDefaultShippingAddress
            isDefaultBillingAddress
          }
          shippingAddress {
            id
            firstName
            lastName
            companyName
            streetAddress1
            streetAddress2
            city
            cityArea
            postalCode
            countryArea
            phone
            isDefaultShippingAddress
            isDefaultBillingAddress
          }
          shippingMethod {
            id
            name
            price {
              currency
              amount
            }
            minimumOrderPrice {
              currency
              amount
            }
            maximumOrderPrice {
              currency
              amount
            }
            minimumOrderWeight {
              unit
              value
            }
            maximumOrderWeight {
              unit
              value
            }
            type
          }
          shippingMethodName
          shippingPrice {
            currency
            gross {
              amount
            }
            net {
              amount
            }
            tax {
              amount
            }
          }
          token
          voucher {
            id
            name
            type
            code
            usageLimit
            used
            startDate
            endDate
            applyOncePerOrder
            applyOncePerCustomer
            discountValueType
            discountValue
            minSpent {
              currency
              amount
            }
            minCheckoutItemsQuantity
            categories(first: 10) {
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              edges {
                node {
                  seoTitle
                  seoDescription
                  id
                  name
                  description
                  descriptionJson
                  slug
                  level
                  privateMetadata {
                    key
                    value
                  }
                  ancestors(first: 10) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                      startCursor
                      endCursor
                    }
                    edges {
                      node {
                        seoTitle
                        seoDescription
                        id
                        name
                        description
                        descriptionJson
                        slug
                        level
                      }
                      cursor
                    }
                  }
                  products(first: 50) {
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                      startCursor
                      endCursor
                    }
                    edges {
                      node {
                        id
                        seoTitle
                        seoDescription
                        name
                        description
                        descriptionJson
                        publicationDate
                        productType {
                          id
                          name
                          slug
                          hasVariants
                          isShippingRequired
                          isDigital
                          weight {
                            unit
                            value
                          }
                          taxRate
                          taxType {
                            description
                            taxCode
                          }
                          variantAttributes {
                            id
                            productTypes(first: 100) {
                              pageInfo {
                                hasNextPage
                                hasPreviousPage
                                startCursor
                                endCursor
                              }
                              edges {
                                node {
                                  id
                                  name
                                  slug
                                  hasVariants
                                  isShippingRequired
                                  isDigital
                                  weight {
                                    unit
                                    value
                                  }
                                  taxRate
                                }
                                cursor
                              }
                              totalCount
                            }
                            productVariantTypes(first: 20) {
                              pageInfo {
                                hasNextPage
                                hasPreviousPage
                                startCursor
                                endCursor
                              }
                              edges {
                                node {
                                  id
                                  name
                                  slug
                                  hasVariants
                                  isShippingRequired
                                  isDigital
                                }
                                cursor
                              }
                              totalCount
                            }
                            inputType
                            name
                            slug
                            values {
                              id
                              name
                              slug
                            }
                            valueRequired
                            visibleInStorefront
                            filterableInStorefront
                            filterableInDashboard
                            availableInGrid
                            storefrontSearchPosition
                          }
                          productAttributes {
                            id
                            inputType
                            name
                            slug
                            values {
                              id
                              name
                              slug
                            }
                            valueRequired
                            visibleInStorefront
                            filterableInStorefront
                            filterableInDashboard
                            availableInGrid
                            storefrontSearchPosition
                          }
                        }
                        slug
                        updatedAt
                        chargeTaxes
                        availableForPurchase
                        visibleInListings
                        isAvailable
                        isAvailableForPurchase
                        isPublished
                      }
                      cursor
                    }
                  }
                  backgroundImage {
                    url
                    alt
                  }
                }
                cursor
              }
            }
          }
          metadata {
            key
            value
          }
          discountName
          displayGrossPrices
          customerNote
          actions
          number
          isPaid
          paymentStatus
          paymentStatusDisplay
          statusDisplay
          canFinalize
          totalAuthorized {
            currency
            amount
          }
          totalCaptured {
            currency
            amount
          }
          events {
            id
            date
            type
            message
            phoneNumber
            phoneNumberType
            amount
            paymentId
            paymentGateway
          }
          totalBalance {
            currency
            amount
          }
          lines {
            id
            productName
            quantity
            isDbp
            dbpVariantData {
              ...ProductVariantType
            }
            variant {
              id
              product {
                id
                name
                images {
                  url
                }
              }
            }
          }
          phoneNumber
          isShippingRequired
        }
        cursor
      }
      totalCount
    }
  }
}
`;

export const GET_ALL_CATEGORY_PRICING = gql`
query allCategoryPricing {
  getAllCategoryPrices {
    id
    metalType
    category {
      id
      name
    }
    subcategory {
      id
      name
    }
    carat
    stoneType   
    makingCharge
    makingChargeMode
    wastageCharge
    wastageChargeMode
    designBankMakingCharge
    designBankMakingChargeMode
    designBankWastageCharge
    designBankWastageChargeMode
  }
}`

export const GET_THEME_COLORS = gql`
query themeColors {
  themeColors {
    colorName
    primaryColor
    secondaryColor
    ctaPrimary
    shade1
    shade2
    shade3
  }
}
`;