import React, { useState } from "react";
import OrderHeader from "./Header.order";
import OrderItemDetails from "./OrderItemDetails";
import { useGetBackgroundColor } from "../../../src/api/index";

const MyOrderMainContentMobile = ({orders, cancelledOrders}) => {
  const [activeTab, setActiveTab] = useState("myOrders");
  const [itemDetailsPage, setItemDetailsPage] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { data } = useGetBackgroundColor({ onLoad: true });

  // This function will be called by the child component
  const handleHeaderData = (childData) => {
    setItemDetailsPage(childData);
  }
  return (
    <>
    <OrderHeader name={activeTab === 'cancelledOrders' ? 'Cancelled orders' : 'My orders'} itemDetailsPage={itemDetailsPage} sendDataToParent={handleHeaderData}></OrderHeader>
    {!itemDetailsPage && (<><div className="flex justify-center	items-center">
    <div className="tabs mt-1">
    <button
      className={activeTab === "myOrders" ? "active" : ""}
      onClick={() => setActiveTab("myOrders")}
    >
      My Orders
    </button>
    <button
      className={activeTab === "cancelledOrders" ? "active" : ""}
      onClick={() => setActiveTab("cancelledOrders")}
    >
      Cancelled Orders
    </button>
    </div>
    </div>
      <div className="mx-4">      
          <div className="orders-content">
            {activeTab === "myOrders" && (
              <>             
              {orders && orders?.length===0 &&
              <div className="no-orders">
                <img
                  src="/assets/img/empty-orders.JPG" // Replace with actual image path
                  alt="No Orders"
                  className="no-orders-img w-24 mx-auto"
                />
                <h3>No Active Orders</h3>
                <button className="bg-pink-500 text-white py-2 px-4 rounded-md">Continue Shopping</button>
              </div>
              }
              {orders && 
                orders?.map((order)=>{
                  return (<div className="border border-gray-300 rounded-lg max-w-full bg-white mt-1">
                    {/* Order Header */}
                    <div className="flex flex-col bg-[#F9F9FA] p-4 rounded-t-lg">
                      <div className="flex flex-col items-start">
                        <p className="font-semibold">Order ID: {order?.plainOrderNumber}</p>
                        <p className="text-sm text-gray-500">Placed on {order?.created}</p>
                        <p className="font-semibold">Total Price : ₹{order?.price?.toLocaleString()}</p>
                      </div>
                      <div className="m-2">
                        <button className="text-white py-2 px-4 rounded-md w-full"  onClick={() => {setItemDetailsPage(true); setSelectedItem(order)}} style={{backgroundColor : data.color.ctaPrimary}}>
                          Item Details
                        </button>
                      </div>
                    </div>
                    {
                      order?.lines?.map((line, index)=>{
                        return (
                          <>
                           {/* Order Body */}
                            <div className="flex p-4 w-full">
                              {/* Product Image */}
                              <div className="flex">
                              <div className="w-36 h-32">
                              {!line.dbpVariantData?.images && !line?.variant?.product?.images &&
                                <img 
                                  src="https://via.placeholder.com/100" 
                                  alt="Product" 
                                  className="object-cover rounded-lg w-36 h-32"
                                />
                              }
                              { line?.dbpVariantData?.images[0] ? 
                                <img 
                                src={line?.dbpVariantData?.images[0]}
                                alt="Product" 
                                className="object-cover rounded-lg w-36 h-32"
                              />
                              :
                                <img 
                                  src={line?.variant?.product?.images[0]?.url}
                                  alt="Product" 
                                  className="object-cover rounded-lg w-36 h-32"
                                />
                              }
                              </div>
                              <div className="flex flex-col  text-start px-2 w-full break-all">
                              {/* Product Info */}
                              <div className="mb-3">
                                <p className="font-normal">{line?.variant?.product?.name ? line?.variant?.product?.name : line?.dbpVariantData?.product?.name}</p>
                                <p className="text-sm text-gray-500 mt-1">Quantity: {line?.quantity}</p>
                                {/* <p className="text-xs text-gray-400 mt-2">Return (money back) requested on 27th Aug 2024</p> */}
                              </div>
                              
                              <p className="font-semibold text-[20px]">₹{parseInt(JSON.parse(order?.metadata[index]?.replace(/'/g,'"'))['Total Price'])?.toLocaleString()}</p>
                              {/* Price and Return Status */}
                              <p className="font-semibold mt-1" style={{color : '#651fff'}}>{order?.statusDisplay ==="Fulfilled" ? "Delivered" : (order?.statusDisplay === "Unfulfilled" && order?.paymentStatus ==="Fully charged")? "Accpted":(order?.statusDisplay ==="Unfulfilled" ? "Under Process" : order?.statusDisplay)}</p>
                              </div>
                              </div>
                            </div>
                          </>
                        )
                      })
                    }
                   
                  </div>)
                })
              }
              
              </>
            )}

            {activeTab === "cancelledOrders" && (
              // <div className="no-orders">
              // <img
              //     src="/assets/img/empty-orders.JPG" // Replace with actual image path
              //     alt="No Orders"
              //     className="no-orders-img w-24 mx-auto"
              //   />
              //   <h3>No Cancelled Orders</h3>
              //   <button className="bg-pink-500 text-white py-2 px-4 rounded-md">Continue Shopping</button>
              // </div>
              <>
              {cancelledOrders && !cancelledOrders?.length &&
                <div className="no-orders">
                  <img
                    src="/assets/img/empty-orders.JPG" // Replace with actual image path
                    alt="No Orders"
                    className="no-orders-img w-24 mx-auto"
                  />
                  <h3>No Active Orders</h3>
                  <button className="bg-pink-500 text-white py-2 px-4 rounded-md">Continue Shopping</button>
                </div>
                }
                {cancelledOrders && 
                  cancelledOrders?.map((order)=>{
                    return (<div className="border border-gray-300 rounded-lg max-w-full bg-white mt-1">
                      {/* Order Header */}
                      <div className="flex flex-col bg-[#F9F9FA] p-4 rounded-t-lg">
                        <div className="flex flex-col items-start">
                          <p className="font-semibold">Order ID: {order?.plainOrderNumber}</p>
                          <p className="text-sm text-gray-500">Placed on {order?.created}</p>
                          <p className="font-semibold">Total Cost ₹{order?.price?.toLocaleString()}</p>
                        </div>
                        <div className="m-2">
                          <button className="text-white py-2 px-4 rounded-md w-full"  onClick={() => {setItemDetailsPage(true); setSelectedItem(order)}} style={{backgroundColor : data.color.ctaPrimary}}>
                            Item Details
                          </button>
                        </div>
                      </div>
                      {
                        order?.lines?.map((line, index)=>{
                          return (
                            <>
                             {/* Order Body */}
                              <div className="flex p-4 w-full">
                                {/* Product Image */}
                                <div className="flex">
                                <div className="w-36 h-32">
                                {!line?.variant?.product?.images &&
                                  <img 
                                    src="https://via.placeholder.com/100" 
                                    alt="Product" 
                                    className="object-cover rounded-lg w-36 h-32"
                                  />
                                }
                                {line?.variant?.product?.images &&
                                  <img 
                                    src={line?.variant?.product?.images[0]?.url}
                                    alt="Product" 
                                    className="object-cover rounded-lg w-36 h-32"
                                  />
                                }
                                </div>
                                <div className="flex flex-col text-start px-2 w-full break-all">
                                {/* Product Info */}
                                <div className="mb-3">
                                  <p className="font-normal">{line?.variant?.product?.name}</p>
                                  <p className="text-sm text-gray-500 mt-1">Quantity: {line?.quantity}</p>
                                  {/* <p className="text-xs text-gray-400 mt-2">Return (money back) requested on 27th Aug 2024</p> */}
                                </div>
                                
                                <p className="font-semibold text-[20px]">₹{parseInt(JSON.parse(order?.metadata[index]?.replace(/'/g,'"'))['Total Price'])?.toLocaleString()}</p>
                                {/* Price and Return Status */}
                                <p className="font-semibold mt-1" style={{color : '#651fff'}}>{order?.statusDisplay ==="Fulfilled" ? "Delivered" : (order?.statusDisplay === "Unfulfilled" && order?.paymentStatus ==="Fully charged")? "Accpted":(order?.statusDisplay ==="Unfulfilled" ? "Under Process" : order?.statusDisplay)}</p>                               
                                </div>
                                </div>
                              </div>
                            </>
                          )
                        })
                      }
                     
                    </div>)
                  })
                }
              </>
            )}
          </div>
      </div></>)}
    {itemDetailsPage && <OrderItemDetails orderDetails={selectedItem}></OrderItemDetails>}
    </>
  );
};

export default MyOrderMainContentMobile;
