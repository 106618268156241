export const mergeAndDeduplicate = (array1, array2) => {               
    const mergedArray = [...array1, ...array2];   
    const uniqueMap = new Map();

    for (const item of mergedArray) {
        if (!uniqueMap.has(item.name)) {                 
            uniqueMap.set(item.name, {
                name: item.name,
                img:item?.img,
                sub: [...item.sub]
            });
        } else {                 
            const existingItem = uniqueMap.get(item.name);
            
            const subUniqueMap = new Map();

            for (const subItem of existingItem.sub) {
                subUniqueMap.set(subItem.name, subItem);
            }
            for (const subItem of item.sub) {
                subUniqueMap.set(subItem.name, subItem);
            }

            existingItem.sub = Array.from(subUniqueMap.values());
        }
    }
    
    return Array.from(uniqueMap.values());
};

export const mergeAndDeduplicateById = (array1, array2) => {               
    // Handle null or undefined inputs
    const safeArray1 = array1 || [];
    const safeArray2 = array2 || [];
    
    const mergedArray = [...safeArray1, ...safeArray2];
    
    // If merged array is empty, return empty array
    if (mergedArray.length === 0) return [];
    
    const uniqueMap = new Map();

    for (const item of mergedArray) {
        // Skip null or undefined items
        if (!item) continue;
        
        if (!uniqueMap.has(item.id)) {                 
            uniqueMap.set(item.id, {
            id: item.id, 
            name: item.name,
            img: item?.img,
            sub: Array.isArray(item.sub) ? [...item.sub] : []
            });
        } else {                 
            const existingItem = uniqueMap.get(item.id);
            
            const subUniqueMap = new Map();

            // Ensure sub arrays exist
            const existingSub = Array.isArray(existingItem.sub) ? existingItem.sub : [];
            const itemSub = Array.isArray(item.sub) ? item.sub : [];

            for (const subItem of existingSub) {
                if (subItem && subItem.id) {
                    subUniqueMap.set(subItem.id, subItem);
                }
            }
            for (const subItem of itemSub) {
                if (subItem && subItem.id) {
                    subUniqueMap.set(subItem.id, subItem);
                }
            }

            existingItem.sub = Array.from(subUniqueMap.values());
        }
    }
    
    return Array.from(uniqueMap.values());
};

export const getCategoryProducts = (data) => {    
    // Handle null or undefined input
    if (!data || !Array.isArray(data)) return [];
    
    const results = [];
     
    for (const mainCategory of data) {
        // Skip null or invalid categories
        if (!mainCategory || !mainCategory.id) continue;
        
        const categoryResult = {
            name: mainCategory.name || 'Unknown Category',
            id: mainCategory.id,
            products: []
        };
        
        const productIds = new Set();
        
        // Ensure sub is an array
        const subCategories = Array.isArray(mainCategory.sub) ? mainCategory.sub : [];
        
        for (const subCategory of subCategories) {
            // Skip null subcategories
            if (!subCategory) continue;
            
            // Ensure products is an array
            const products = Array.isArray(subCategory.products) ? subCategory.products : [];
            
            for (const product of products) {
                // Skip null products or products without id
                if (!product || !product.id) continue;
                
                if (!productIds.has(product.id)) {
                    productIds.add(product.id);
                    categoryResult.products.push(product);
                }
            }
        }
        
        results.push(categoryResult);
    }
 
    return results;
}

export const sortByProductLength=(arr)=> {
    return arr.sort((a, b) => b.product.length - a.product.length);
}