import useDeviceDetect from 'hooks/useDeviceDetect';
import LoginFormDesktop from "./LoginForm";
import LoginFormMobile from "./LoginFormMobile";
import OTPFormDesktop from "../otp/DesktopOTP";
import { useSelector } from "react-redux";
import MobileOTP from "../otp/MobileOTP";

const LoginPage = () => {
  const { isMobile } = useDeviceDetect();

  const storeData = useSelector((state) => state.storeData);

  console.log("storeData",storeData)
  return (
    <>
    {isMobile && <LoginFormMobile/>}
    {!isMobile && 
        <div className="login-page--wrapper flex justify-center items-center py-20">
        <div className="login-page-content text-center w-[280px] font-['Inter']">
          <img
            src={storeData?.storeLogo}
            alt="Store Logo"
            className="w-[46px] h-[51px] object-contain m-auto mb-[20px]"
          />
          <p className="text-[20px] font-bold mb-[15px]">Login to {storeData?.name?.replace(","," ")}</p>
          <p className="text-[12px] font-medium text-[#6A6A6A] mb-[20px]">
          Create an account and unlock Best prices as well as
          be the first to know about our exclusive launches & offers
          </p>
          {!isMobile && <LoginFormDesktop/> }          
        </div>
      </div>
    }
     
      {/* <div className="login-page-component h-[100vh]">
        <div className="pagination font-['Inter'] flex justify-center items-center gap-[23px] py-[22px] bg-[#F3F3F3]">
          <span className="gap-[10px] flex justify-center items-center">
            <div className="text-[13px] font-semibold h-[25px] w-[25px] bg-[#232323] text-white rounded-full flex justify-center items-center">
              1
            </div>
            <p className="font-semibold text-[15px]">Log In</p>
          </span>
          <span className="gap-[10px] flex justify-center items-center">
            <div className="text-[13px] font-semibold h-[25px] w-[25px] text-black bg-[#C2C2C2] rounded-full flex justify-center items-center">
              2
            </div>
            <p className="font-semibold text-[#8E8E8E] text-[15px]">Shipping</p>
          </span>
          <span className="gap-[10px] flex justify-center items-center">
            <div className="text-[13px] font-semibold h-[25px] w-[25px] text-black bg-[#C2C2C2] rounded-full flex justify-center items-center">
              3
            </div>
            <p className="font-semibold text-[#8E8E8E] text-[15px]">Payments</p>
          </span>
        </div>
        <div className="login-page--wrapper flex justify-center items-center py-64">
          <div className="login-page-content text-center w-[280px] font-['Inter']">
            <img
              src={storeData.storeLogo}
              alt="Store Logo"
              className="w-[46px] h-[51px] object-contain m-auto mb-[20px]"
            />
            <p className="text-[20px] font-bold mb-[15px]">Login to GoldClub</p>
            <p className="text-[12px] font-medium text-[#6A6A6A] mb-[20px]">
              Login to unlock best prices and get exclusive launch offers. Complete
              your profile and get a ₹200 worth of discount on your next purchase.
            </p>
      {!isMobile && <LoginFormDesktop/> }
      {isMobile && <LoginFormMobile/>}
            <div className="my-64 py-64" >
              <OTPFormDesktop/>
              <MobileOTP/>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default LoginPage;