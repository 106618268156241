// features/login/loginSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { actionConstants } from 'constants/actionConstants';

const initialState = {
    pricing: null
};

const categoryPricingSlice = createSlice({
    name: 'categoryPricing',
    initialState,
    reducers: {
        setPricing(state, action) {
            state.pricing = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Handle other action types or async actions here if needed
    }
});

export const { setPricing } = categoryPricingSlice.actions;
// Export other actions as needed

export default categoryPricingSlice.reducer;
