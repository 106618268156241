import { useLazyQuery } from 'hooks';
import {
  DBP_PRODUCTS_WITH_FILTER,
  GET_ALL_CATEGORIES,
  GET_ALL_PRODUCTS_FILTERED,
  GET_DBP_INVENTORY,
} from 'graphql/query';
import { useEffect } from 'react';

export const useGetStoreProducts = ({ onLoad, searchParams }) => {
  // Determine if we should use cache based on the query parameters
  // For search queries, we'll use network-only to ensure fresh results
  const shouldUseCache = !searchParams?.get('search');

  const { data, loading, error, executeQuery, refetch } = useLazyQuery(
    GET_ALL_PRODUCTS_FILTERED,
    {
      variables: {
        filter: {
          search: searchParams?.get('search') || null, // mean 3~4< char. length
          categories: searchParams?.get('categories') || null, // Main + Local Store
          collections: searchParams?.get('collections') || null, // Local store
          productType: searchParams?.get('productType') || null,
          attributes: [
            ...(searchParams?.get('gender')
              ? [
                  {
                    slug: 'gender',
                    values: [searchParams?.get('gender')],
                  },
                ]
              : []),
            ...(searchParams?.get('metal')
              ? [
                  {
                    slug: 'metal-type',
                    values: [searchParams.get('metal')],
                  },
                ]
              : []),
            {
              slug: 'parent-product-id',
              values: ['0'],
            },
          ],
        },
        sort: { direction: 'DESC', field: 'PUBLICATION_DATE' },
      },
      // Use cache-first for non-search queries, network-only for search queries
      fetchPolicy: shouldUseCache ? 'cache-first' : 'network-only',
      // Cache results for 1 day for non-search queries
      ...(shouldUseCache && { cacheTime: 86400000 }),
      onLoad,
    }
  );

  useEffect(() => {
    if (!data && searchParams) {
      executeQuery();
    }
  }, [data, executeQuery, searchParams]);
  return {
    executeQuery,
    data,
    loading,
    error,
    refetch,
  };
};

export const useAllB2BProducts = ({ ids, searchParams }) => {
  // Only use network-only for search queries, as they need fresh results
  // All other filter types (categories, collections, etc.) can use cache
  const shouldUseCache = !searchParams?.get('search');

  // Create a stable filter object that won't cause unnecessary re-renders
  const filter = {
    ids: ids || null,
    search: searchParams?.get('search') || null,
    categories: searchParams?.get('categories') || null,
    collections: searchParams?.get('collections') || null,
    productType: searchParams?.get('productType') || null,
    attributes: [
      ...(searchParams?.get('gender')
        ? [
            {
              slug: 'gender',
              values: [searchParams.get('gender')],
            },
          ]
        : []),
      ...(searchParams?.get('metal')
        ? [
            {
              slug: 'metal-type',
              values: [searchParams.get('metal')],
            },
          ]
        : []),
      {
        slug: 'parent-product-id',
        values: ['0'], // Only parent products
      },
    ],
  };

  const { data, loading, error, executeQuery, refetch } = useLazyQuery(
    DBP_PRODUCTS_WITH_FILTER,
    {
      variables: {
        filter,
        sort: { direction: 'DESC', field: 'PUBLICATION_DATE' },
      },
      // Use cache for all queries except search
      fetchPolicy: shouldUseCache ? 'cache-first' : 'network-only',
      // Cache results for 1 day for cacheable queries
      ...(shouldUseCache && { cacheTime: 86400000 }),
    }
  );

  return {
    executeQueryB2B: executeQuery,
    dataB2B: data,
    loadingB2B: loading,
    errorB2B: error,
    refetchB2B: refetch,
  };
};

export const useGetDBPInventory = ({ onLoad, searchParams }) => {
  const { data, loading, error, executeQuery, refetch } = useLazyQuery(
    GET_DBP_INVENTORY,
    { onLoad }
  );
  const { executeQueryB2B, dataB2B } = useAllB2BProducts({
    ids: data?.getDesignBankProductsId,
    searchParams,
  });

  useEffect(() => {
    if (data?.getDesignBankProductsId?.length) {
      executeQueryB2B(); // Calling executeQueryB2B without arguments as it uses variables internally
    }
  }, [data?.getDesignBankProductsId, searchParams, executeQueryB2B]); // Including necessary dependencies

  return {
    executeQuery,
    data: dataB2B, // Returning B2B data
    loading,
    error,
    refetch,
  };
};

export const useGetAllCategories = ({ onLoad, productLimit }) => {
  const { data, loading, error, executeQuery, refetch } = useLazyQuery(
    GET_ALL_CATEGORIES,
    { variables: { productsFirst: productLimit }, onLoad }
  );

  return {
    executeQuery,
    data,
    loading,
    error,
    refetch,
  };
};
