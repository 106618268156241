// Environment variables with fallback values
export const config = {
  publicDomain:
    process.env.REACT_APP_PUBLIC_DOMAIN,
  tenantDomain:
    process.env.REACT_APP_TENANT_DOMAIN,
  isDevelopment:
    process.env.REACT_APP_SERVER_ENVIRONMENT === 'development',
  graphqlEndpoint: '/graphql/',
};

console.log(config);

// Helper to strip port from domain
const stripPort = domain => domain.split(':')[0];

export const getPublicGraphqlUrl = () => {
  if (config.isDevelopment) {
    return `http://${config.publicDomain}${config.graphqlEndpoint}`;
  }
  return 'https://goldclub.co/graphql/';
};

export const getTenantGraphqlUrl = () => {
  if (config.isDevelopment) {
    return `http://${config.tenantDomain}${config.graphqlEndpoint}`;
  }
  return window.location.origin + '/graphql/';
};

// For queries that need domain without port (like storeDetails)
export const getTenantDomain = () => {
  if (config.isDevelopment) {
    return stripPort(config.tenantDomain);
  }
  return window.location.hostname;
};

// For queries that need full domain with port
export const getTenantDomainWithPort = () => {
  if (config.isDevelopment) {
    return config.tenantDomain;
  }
  return window.location.hostname;
};
